import { Grid } from '@mui/material';
import InputNum from '../../../shared/forms/inputNum';

export default function ShearRail({ control, pagePermissions }) {
  return (
    <Grid container columnSpacing={{ xs: 2 }} rowSpacing={{ xs: 3, md: 4 }}>
      <Grid item xs={6}>
        <InputNum
          isDisabled={!pagePermissions.canEdit}
          control={control}
          label={`3/8" studs`}
          name="threeByEightStuds"
        />
      </Grid>
      <Grid item xs={6}>
        <InputNum isDisabled={!pagePermissions.canEdit} control={control} label={`1/2" studs`} name="oneByTwoStuds" />
      </Grid>
      <Grid item xs={6}>
        <InputNum
          isDisabled={!pagePermissions.canEdit}
          control={control}
          label={`5/8" studs`}
          name="fiveByEightStuds"
        />
      </Grid>
      <Grid item xs={6}>
        <InputNum
          isDisabled={!pagePermissions.canEdit}
          control={control}
          label={`3/4" studs`}
          name="threeByFourStuds"
        />
      </Grid>
      <Grid item xs={6}>
        <InputNum isDisabled={!pagePermissions.canEdit} control={control} label={`Rails`} name="rails" />
      </Grid>
    </Grid>
  );
}
