import { useCallback, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useSnackBar } from '../../context/snackBarContext';
import { Account } from '../../auth/userAccount';
import axios from 'axios';
import { JobNumberSearch } from '../shared/jobAutoComplete';
import { LocationDropDown2 } from '../shared/locationDropDown';
import ActionBar from '../shared/actionBar';
import { ActionSubmitButton } from '../shared/actionSubmitButton';
import { FormControlLabel, TextField, Grid, Checkbox, Autocomplete, FormControl } from '@mui/material';

const PTAndRebarJob = ({ pagePermissions }) => {
  const [locationId, setLocationID] = useState('');
  const [jobId, setJobId] = useState('');
  const [uid, setUid] = useState('');
  const [jobName, setJobName] = useState('');
  const [isAdditionalJobForPT, setAdditionalJobForPT] = useState(false);
  const [awaitingJobsOptions, setAwaitingJobsOptions] = useState([]);
  const { setSnackbar } = useSnackBar();
  const jobNameRef = useRef();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm();

  function onError(errors, e) {
    console.log(errors, e);
    console.log(locationId);
    console.log(jobId);
  }

  const handleAdditionalJob = async (event) => {
    if (event.target.checked === true) {
      setAdditionalJobForPT(true);
    } else {
      setAdditionalJobForPT(false);
      setUid('');
    }
  };

  const handleJobChange = async (event) => {
    setJobId(event?.projectId);
  };

  const getPendingJobsByLocation = useCallback(async (locationID) => {
    axios
      .get(`Locations/${locationID}/PendingJobs`)
      .then((response) => {
        setAwaitingJobsOptions(response.data ?? []);
      })
      .catch((error) => {
        console.log('Error occured while retrieving Pending Jobs By Location', error);
        console.log('Error Status:', error.status);
      });
  }, []);

  const handleLocationChange = useCallback(
    async (event) => {
      setLocationID(event?.locationID);
      setJobName('');
      // clear jobName field value NOTE: Need to cler txt-field also
      resetField('awaitingApprovalJobsName');

      if (event?.locationID) {
        await getPendingJobsByLocation(event.locationID);
      }
    },
    [resetField, getPendingJobsByLocation]
  );

  const handleCreatJob = async (formData) => {
    let job = {};
    job.ProjectId = isAdditionalJobForPT === false ? formData.jobNumber : '';
    job.RefProjectId = formData.jobNumber;
    job.LocationId = formData.location;
    job.IsAdditionalJobForPT = isAdditionalJobForPT;
    job.Name = formData?.awaitingApprovalJobsName;
    job.UID = uid;
    job.LastModifiedBy = Account.username;
    job.PTLocationId = formData.location;
    job.IsMiscJob = false;
    if (job.UID) {
      job.jobClassification = awaitingJobsOptions.find((job) => job?.uid === uid).jobClassification; //jobtype
      job.FirstDeliveryDate = awaitingJobsOptions.find((job) => job?.uid === uid).firstDeliveryDate;
      job.LastDeliveryDate = awaitingJobsOptions.find((job) => job?.uid === uid).lastDeliveryDate;
    }
    axios
      .post('createptjob', job, { loaderRequired: true })
      .then((response) => {
        let jobId = response.data?.value;

        if (!response.data.message) {
          setSnackbar({
            open: true,
            message: `Job# ${jobId} created successfully.`,
            severity: 'success',
          });
        }

        navigate(`/PT/Jobs/${jobId}/MainInfo`);
      })
      .catch((error) => {
        console.log('Error occured while creating job.');
        console.log(error);
      });
  };

  return (
    <Grid container direction="column" alignItems="center" sx={{ marginTop: '15px' }}>
      <form onSubmit={handleSubmit(handleCreatJob, onError)}>
        <Grid item sx={{ marginTop: '15px' }}>
          <JobNumberSearch
            control={control}
            errors={errors}
            jobChangeHandler={handleJobChange}
            name="jobNumber"
            disabled={!pagePermissions.canCreateJob}
            required="true"
            sx={{ minWidth: 400 }}
          />
        </Grid>
        <Grid item sx={{ marginTop: '15px' }}>
          <LocationDropDown2
            disabled={!pagePermissions.canCreateJob}
            changeHandler={handleLocationChange}
            control={control}
            errors={errors}
            name="location"
            required="true"
            sx={{ minWidth: 400 }}
          />
        </Grid>
        <Grid item sx={{ marginTop: '15px' }}>
          <FormControlLabel
            disabled={!pagePermissions.canCreateJob}
            control={<Checkbox id={'additionalJob'} />}
            label="Create Additional Job # For PT"
            onClick={(e) => (pagePermissions.canCreateJob ? handleAdditionalJob(e) : null)}
          />
        </Grid>
        <Grid item sx={{ marginTop: '15px' }}>
          <FormControl sx={{ minWidth: 400 }} size="medium">
            <Controller
              name="awaitingApprovalJobsName"
              rules={{ required: false }}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  ref={jobNameRef}
                  value={jobName}
                  disabled={!isAdditionalJobForPT || !pagePermissions.canCreateJob}
                  options={awaitingJobsOptions.map((jobs, i) => jobs?.projectName)}
                  onChange={(e, newValue) => {
                    onChange(newValue || null);
                    setJobName(newValue);
                    setUid(awaitingJobsOptions.find((job, i) => job?.projectName === newValue)?.uid);
                  }}
                  id="projectName"
                  renderInput={(params) => (
                    <TextField {...params} label="Select Job Awaiting Approval" placeholder="Job Name" />
                  )}
                />
              )}
            />
          </FormControl>
        </Grid>
        <ActionBar display={'flex'} justifyContent={'end'}>
          <ActionSubmitButton disabled={!pagePermissions.canCreateJob} buttonText={'Create'} />
        </ActionBar>
      </form>
    </Grid>
  );
};

export default PTAndRebarJob;
