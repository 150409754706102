import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { createContext } from "react";
import LoginRedirect from "./loginRedirect";
import { Account } from "./userAccount";
import { Outlet } from "react-router-dom";

export const UserContext = createContext({});

export default function Authenticate() {
  return (
    <>
      <AuthenticatedTemplate>
        <UserContext.Provider value={Account}>
          <Outlet />
        </UserContext.Provider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginRedirect />
      </UnauthenticatedTemplate>
    </>
  );
}
