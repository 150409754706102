import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MainInfoForm from '../../../components/PT/MainInfo/mainInfoForm';
import { useSnackBar } from '../../../context/snackBarContext';
import { Box, Grid } from '@mui/material';
import PageLoader from '../../../components/shared/pageLoader';
import { Helmet } from 'react-helmet-async';
import { useJob } from '../../../utils/masterData';
import { useUserContext } from '../../../context/userContext';

const MainInfo = () => {
  const [canExport, setCanExport] = useState(true);
  const { setSnackbar } = useSnackBar();
  const { jobId } = useParams();
  const { user } = useUserContext();

  const { data, isLoading, error } = useJob({ jobId });

  useEffect(() => {
    if (data?.value) {
      const { existingInSAP, awaitingExport } = data.value;

      //* if any one property true, disable export button
      if (existingInSAP || awaitingExport) setCanExport(false);
    }
  }, [data, setSnackbar]);

  if (isLoading) {
    return <PageLoader />;
  }

  const pagePermissions = {
    canEdit: (user.isCAM || user.isPTDetailer || user.isPTProjectManager) && !data?.value?.jobOnHold,
    canExport: user.isCAM && !data?.value?.jobOnHold,
    canUseHoldBtn: user.isCAM,
  };
  //* if error in getting job
  if (error) {
    return <Box>Error getting job info.</Box>;
  }

  // job not found.
  if (!data?.value?.isPTJob || !data?.value) {
    return <span>Job not found</span>;
  }

  return (
    <>
      <Helmet>
        <title>MainInfo</title>
      </Helmet>
      <Grid>
        <MainInfoForm
          pagePermissions={pagePermissions}
          canExport={canExport}
          setCanExport={setCanExport}
          jobInfo={data.value}
        />
      </Grid>
    </>
  );
};

export default MainInfo;
