import { Helmet } from 'react-helmet-async';
import Header from '../components/Layout/header';

export default function Unauthorized() {
  return (
    <>
      <Helmet>
        <title>Unauthorized</title>
      </Helmet>
      <Header nosearch />
      <main style={{ 
        alignItems: 'center', 
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 97px)',  
        justifyContent: 'center' }}>
          <img src="/unauthorized.svg" width={450} alt="401: Unauthorized" />
      </main>
    </>
  );
}
