import { Grid } from '@mui/material';
import InputNum from '../../../shared/forms/inputNum';
import { useWatch } from 'react-hook-form';
import { removeNumCommas } from '../../../../utils/numberFormatter';

export default function Weights({ control, setValue, pagePermissions }) {
  const estimatedLF = useWatch({ control, name: 'estimatedLF' });
  const changeOrderLF = useWatch({ control, name: 'changeOrderLF' });

  setValue('totalLF', parseInt(removeNumCommas(estimatedLF)) + parseInt(removeNumCommas(changeOrderLF)));

  return (
    <Grid container columnSpacing={{ xs: 2 }} rowSpacing={{ xs: 3, md: 4 }}>
      <Grid item xs={6}>
        <InputNum control={control} label="Estimated LF" name="estimatedLF" isDisabled={true} />
      </Grid>
      <Grid item xs={6}>
        <InputNum isDisabled={!pagePermissions.canEdit} control={control} label="CO LF" name="changeOrderLF" />
      </Grid>
      <Grid item xs={6}>
        <InputNum control={control} label="Total LF" name="totalLF" isDisabled={true} />
      </Grid>
      <Grid item xs={6}>
        <InputNum isDisabled={!pagePermissions.canEdit} control={control} label="Bare Weight" name="bareWeight" />
      </Grid>
      <Grid item xs={6}>
        <InputNum
          isDisabled={!pagePermissions.canEdit}
          control={control}
          label="Shipping Weight"
          name="shippingWeight"
        />
      </Grid>
      <Grid item xs={6}>
        <InputNum isDisabled={!pagePermissions.canEdit} control={control} label="Strand Cost/LF" name="strandCost" />
      </Grid>
      <Grid item xs={6}>
        <InputNum control={control} isDisabled={true} label="Sale $/LF" name="salesFLAmount" />
      </Grid>
      <Grid item xs={6}>
        <InputNum
          isDisabled={!pagePermissions.canEdit}
          control={control}
          label="Estimated # Of Loads"
          name="estLoads"
        />
      </Grid>
    </Grid>
  );
}
