import { Helmet } from 'react-helmet-async';
import { useParams, useSearchParams } from 'react-router-dom';
import BCAdditionalComponents from '../../../components/PT/PTQuantities/bcAdditionalComponents';
import JobHeader from '../../../components/shared/jobHeader';

export default function BCAdditionalItems() {
  const { loadDescription } = useParams();
  const [searchParams] = useSearchParams();

  const jobName = searchParams.get('jn');
  const cutNo = searchParams.get('cutNo');

  return (
    <>
      <Helmet>
        <title>Barrier Cable Additional Components</title>
      </Helmet>
      <JobHeader jobName={jobName} loadDescription={loadDescription} cutNo={cutNo} />
      <BCAdditionalComponents />
    </>
  );
}
