import React from 'react';
import axios from 'axios';
import { env } from './env';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './auth/config/authConfig';
import { PublicClientApplication } from '@azure/msal-browser';
import CssBaseline from '@mui/material/CssBaseline';
import { Worker } from '@react-pdf-viewer/core';
import App from './App';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './styles/styles.scss';

const container = document.getElementById('root');
const root = createRoot(container);
const msalInstance = new PublicClientApplication(msalConfig);
axios.defaults.baseURL = env.REACT_APP_API_URL;

root.render(
  // <React.StrictMode>
  <MsalProvider instance={msalInstance}>
    <CssBaseline />
    <HelmetProvider>
      <Worker workerUrl={'/pdf.worker.min.js'}>
        <App />
      </Worker>
    </HelmetProvider>
  </MsalProvider>
  // </React.StrictMode>
);
