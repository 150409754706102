export const ROUTE_EMPTY = '';
export const ROUTE_DEFAULT = '/';
export const ROUTE_HOME = '/home';
export const ROUTE_PT = '/PT';

//* ----- Admin paths.

export const ROUTE_PT_ADMIN = '/PT/admin';
export const ROUTE_PT_ADMIN_PARTCLASS = 'part class';
export const ROUTE_PT_ADMIN_PART = 'parts';
export const ROUTE_PT_ADMIN_FABCONFIG = 'fabconfig';
export const ROUTE_PT_ADMIN_KITCONFIG = 'kitconfig';

//* ----------------------

//* ----- jobs paths
export const ROUTE_NEW_PT_JOB = '/PT/newjob';

export const ROUTE_LOGIN = '/login';
export const ROUTE_JOBS = '/PT/jobs';

export const ROUTE_JOBINFO = '/PT/jobs/:jobId/MainInfo';
export const ROUTE_CIS_DOCUMENTS_UPLOAD = 'CIS Documents';

export const ROUTE_JOBINF_REDIRECT = ':jobId';
export const ROUTE_JOBS_ESTIMATES = ':jobId/estimates';
export const ROUTE_JOBS_CONTACTS = ':jobId/contacts';

//  --- fab routest
export const ROUTE_FAB_JOBS = '/FAB';
export const ROUTE_NEW_FAB_JOB = '/FAB/newjob';
export const ROUTE_FAB_JOBINFO = '/FAB/jobs/:jobId';
export const ROUTE_FAB_JOBS_RDIRECT = '/FAB/jobs';

//* pt-quantities
export const ROUTE_JOBS_PTQUANTITIES = ':jobId/pt-quantities'; //* index

// redirect to quantites page, remove when load page added.
export const ROUTE_LOAD_QUANTITES_REDIRECT = ':loadDescription';
export const ROUTE_LOADS_CUT_IMPORT = ':loadDescription/cut-import';
// redirect to pt-quantites page, remove when cuts page added.
export const ROUTE_CUTS_QUANTITES_REDIRECT = ':loadDescription/cuts';
export const ROUTE_LOAD_HARDWARES = ':loadDescription/cuts/Hardwares';
export const ROUTE_LOAD_IMPORT_BARRIER_CABLE_STEEL_POST = ':loadDescription/Barrier Cable Steel Post & ACC';
export const ROUTE_LOAD_IMPORT_ACCESSORIES = ':loadDescription/Accessories';
export const ROUTE_LOAD_Link_Drawings = ':loadDescription/Link Drawings';
export const ROUTE_LOAD_PTI_Filing = ':loadDescription/PTI Filing';
export const ROUTE_LOAD_BC_Additional_Components = ':loadDescription/cuts/Barrier Cable Additional Components';

//* studrails-quantities
export const ROUTE_JOBS_STUD_RAIL_QUANTITIES = ':jobId/studrails_quantities'; //* index
export const ROUTE_STUD_RAIL_CUT_IMPORT = ':loadDescription/cut-import';
export const ROUTE_LOAD_IMPORT_STUDRAIL_CHAIRS = ':loadDescription/Chairs';

//* ----- schedules
export const ROUTE_PT_SCHEDULES = 'pt/schedules'; //* index
export const ROUTE_PT_SHOP_PAPER_WORK = ':jobId/:loadDescription/shop paperwork';
export const ROUTE_PT_SCHEDULES_REDIRECT_LOAD = ':jobId/:loadDescription';
export const ROUTE_PT_SCHEDULES_REDIRECT_JOB = ':jobId/';

//* --------------------

//* ------
export const ROUTE_UNAUTHORIZED_USER = '/unauthorized';
