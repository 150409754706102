import { useState, React, Fragment, useEffect } from 'react';
import { Autocomplete, FormControl, TextField, CircularProgress, debounce } from '@mui/material';
import { Controller } from 'react-hook-form';
import * as ErrorMessages from './errorMessages';
import axios from 'axios';

export function JobNumberSearch(props) {
    const [open, setOpen] = useState(false);
    const [jobNumberList, setJobNumberList] = useState([]);
    const { control, errors } = props;
    const [loading, setLoading] = useState(open && jobNumberList.length);

    useEffect(() => {
        if (!open) {
            setJobNumberList([]);
        }
    }, [open]);

    const loadData = async (inputValue, setOptions) => {
        if (inputValue === undefined) {
            console.log('no options');
            setJobNumberList([]);
            return;
        }
        setLoading(true);
        axios
            .get('Jobs/SearchJob?JobId=' + inputValue, { loaderRequired: false })
            .then((response) => {
                if (response.data) {
                    const filteredOptions = response.data.filter((f) => f.projectId.includes(inputValue));
                    setJobNumberList(filteredOptions);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log('Error occured while retrieving Job#.', error);
                console.log('catchError', error?.status);
                setLoading(false);
            });
    };

    const debouncedLoadData = debounce(loadData, 1000);

    const handleInputChange = async (value) => {
        debouncedLoadData(value, setJobNumberList);
    };

    return (
        <FormControl>
            <Controller
                name="jobNumber"
                rules={{ required: ErrorMessages.Error_RequiredField }}
                control={control}
                render={({ field }) => {
                    const { onChange } = field;
                    return (
                        <Autocomplete
                            id="jobNumber"
                            name="jobNumber"
                            open={open}
                            onOpen={() => {
                                setOpen(true);
                            }}
                            onClose={() => {
                                setOpen(false);
                            }}
                            onChange={(event, data) => {
                                onChange(data?.projectId);
                            }}
                            style={{ width: 400 }}
                            options={!jobNumberList ? [{ label: 'Loading...', projectId: '0' }] : jobNumberList}
                            loading={loading}
                            isOptionEqualToValue={(option, value) => option?.projectId === value?.projectId}
                            getOptionLabel={(jobNumberList) => jobNumberList?.projectId}
                            renderOption={(props, jobNumberList) => (
                                <box {...props} key={jobNumberList?.projectId}>
                                    {jobNumberList?.projectId}
                                </box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    id="jobNumber"
                                    {...params}
                                    label="Search a Rebar Job #"
                                    variant="outlined"
                                    onChange={(ev) => {
                                        handleInputChange(ev.target.value);
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <Fragment>
                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </Fragment>
                                        ),
                                    }}
                                    error={Boolean(errors?.jobNumber)}
                                    helperText={errors?.jobNumber?.message}
                                />
                            )}
                        />
                    );
                }}
            />
        </FormControl>
    );
}
