import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { IoIosAdd } from 'react-icons/io';
import { useJob } from '../../../utils/masterData';
import {  useParams } from 'react-router-dom';
import DataGrid, { Column, Editing, EmailRule, PatternRule, RequiredRule, Scrolling } from 'devextreme-react/data-grid';

export default function AdditionalContactGrid({
  pagePermissions,
  dataGridRef,
  setHasEditData,
  onSaving,
  additionalContactsData,
}) {
  const { jobId } = useParams();
  const { data } = useJob({ jobId });

  return (
    <Grid item xs={12} mt={4}>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'baseline'}>
        <Typography variant="h2">Additional contacts</Typography>
        <Button
          sx={{ px: 20 }}
          onClick={() => dataGridRef.current.instance.addRow()}
          className="btn btn-blue"
          startIcon={<IoIosAdd />}
          disabled={!pagePermissions.canEdit || data?.value?.jobOnHold }
        >
          Add
        </Button>
      </Box>

      <DataGrid
        ref={dataGridRef}
        keyExpr={'contactId'}
        className="datagrid-container"
        onContentReady={(event) => setHasEditData(event.component.hasEditData())}
        onToolbarPreparing={(event) => event.toolbarOptions.items.splice(0)}
        dataSource={additionalContactsData}
        onSaving={onSaving}
      >
        <Scrolling mode="standard" />
        <Editing
          allowAdding={pagePermissions.canEdit  || !data?.value?.jobOnHold}
          allowDeleting={pagePermissions.canEdit || !data?.value?.jobOnHold}
          allowUpdating={pagePermissions.canEdit || !data?.value?.jobOnHold}
          
          mode="batch"
        />
        <Column dataField="name" caption="Name" allowEditing={pagePermissions.canEdit }>
          <RequiredRule />
        </Column>
        <Column dataField="company" caption="Company" allowEditing={pagePermissions.canEdit } />
        <Column dataField="email" caption="Email" allowEditing={pagePermissions.canEdit }>
          <EmailRule />
        </Column>
        <Column dataField="phone" caption="Phone" dataType="string" allowEditing={pagePermissions.canEdit }>
          <PatternRule message={'Invalid Phone'} pattern={/^(\(\d{3}\)|\d{3})\s?\d{3}[-\s]?\d{4}\b/} />
        </Column>
      </DataGrid>
    </Grid>
  );
}
