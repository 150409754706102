import { useEffect } from "react";
import axios from "axios";
import { useAppState } from "./context/appContext";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginRequest } from "./auth/config/authConfig";
import { env } from "./env";
import { msalInstance } from "./auth/userAccount";
import { useErrorContext } from "./context/errorContext";

const AxiosInterceptor = () => {
  const { setLoading } = useAppState();
  const { setError } = useErrorContext();

  useEffect(() => {
    axios.defaults.baseURL = env.REACT_APP_API_URL;

    axios.interceptors.request.use(
      async (config) => {
        if (
          config.loaderRequired === undefined ||
          config.loaderRequired === "undefined"
        ) {
          setLoading(true);
        } else {
          setLoading(false);
        }

        setError(null);

        // May need to re-add
        //setError(null);

        const account = msalInstance.getAllAccounts()[0];
        const msalResponse = await msalInstance
          .acquireTokenSilent({
            ...loginRequest,
            account: account,
          })
          .catch(async (error) => {
            setLoading(false);

            if (error instanceof InteractionRequiredAuthError) {
              // fallback to interaction when silent call fails
              return msalInstance.acquireTokenPopup(loginRequest);
            }
            // handle other errors
          });

        const token = msalResponse.accessToken;

        if (token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }

        return config;
      },
      (error) => Promise.reject(error)
    );

    axios.interceptors.response.use(
      (response) => {
        if (response.data.errors?.length > 0) {
          console.log(response.data.errors)
          setError({response})
        }
        setLoading(false);
        return response;
      },
      (error) => {
        console.log(error);
        setLoading(false);
        setError(error);
        return Promise.reject(error);
      }
    );
  });
};

export { AxiosInterceptor };
