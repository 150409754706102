import { Outlet, useLocation, useParams } from 'react-router-dom';
import Header from '../../../components/Layout/header';
import ProjectsNav from '../../../components/Nav/projectsNav';
import BreadCrumbsCustom from '../../../components/shared/breadCrumb';
import { DisplayError } from '../../../components/PT/displayError';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

export default function JobsLayout() {
  const { pathname } = useLocation();
  const {jobId} = useParams();

  const isPTJobsRoute = pathname.toLowerCase() === '/pt/jobs';

  useEffect(() => {
    TagManager.dataLayer({dataLayer:{projectId: jobId}});
    return () => TagManager.dataLayer({dataLayer:{projectId: undefined}});
  }, []);

  return (
    <>
      <Header />
      {isPTJobsRoute ? null : <ProjectsNav />}
      <main className={!isPTJobsRoute ? 'pt-admin-users' : ''}>
        <DisplayError />
        <BreadCrumbsCustom />
        <Outlet />
      </main>
    </>
  );
}
