import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useSnackBar } from '../../context/snackBarContext';
import axios from 'axios';
import { Autocomplete, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material';
import { LocationDropDown2 } from '../shared/locationDropDown';
import ActionBar from '../shared/actionBar';
import { ActionSubmitButton } from '../shared/actionSubmitButton';
import * as ErrorMessages from '../shared/errorMessages';

const PTOnlyJob = ({ pagePermissions }) => {
  const { setSnackbar } = useSnackBar();
  const navigate = useNavigate();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [uid, setUid] = useState('');
  const [isBlankJob, setBlankJob] = useState(false);
  const [isImportFromSalesForce, setImportFromSalesForce] = useState(true);
  const [pendingJobs, setPendingJobs] = useState([]);
  const [projectName, setProjectName] = useState('');

  const handleImportFromSalesForce = (event) => {
    setImportFromSalesForce(true);
    setBlankJob(false);
  };

  const handleBlankJob = () => {
    setBlankJob(true);
    setImportFromSalesForce(false);
    setProjectName('');
  };

  const handleLocationChange = (event) => {
    setUid('');
    if (event) getPendingJobsByLocation(event.locationID);
  };

  const getPendingJobsByLocation = async (locationID) => {
    axios
      .get(`Locations/${locationID}/PendingJobs`)
      .then((response) => {
        setPendingJobs(response?.data ?? []);
        setProjectName('');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  async function handleCreatJob(formData) {
    let job = {};
    job.LocationId = formData.location;
    job.Name = formData.jobName ?? formData.projectName;
    job.IsAdditionalJobForPT = true;
    job.UID = uid;
    job.PTLocationId = formData.location;
    job.IsMiscJob = false;
    if (job.UID) {
      job.jobClassification = pendingJobs.find((job) => job?.uid === uid).jobClassification; //jobtype
      job.FirstDeliveryDate = pendingJobs.find((job) => job?.uid === uid).firstDeliveryDate;
      job.LastDeliveryDate = pendingJobs.find((job) => job?.uid === uid).lastDeliveryDate;
    }
    axios
      .post('createptjob', job)
      .then((response) => {
        console.log(response);
        setPendingJobs(response?.data ?? []);
        setProjectName('');

        let jobId = response.data?.value;
        if (!response.data.message) {
          setSnackbar({
            open: true,
            message: `Job# ${jobId} created successfully.`,
            severity: 'success',
          });
        }

        navigate(`/PT/Jobs/${jobId}/MainInfo`);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <Grid container direction="column" alignItems="center" sx={{ marginTop: '10px' }}>
      <Grid item sx={{ marginTop: '20px' }}>
        <form onSubmit={handleSubmit(handleCreatJob)}>
          <LocationDropDown2
            changeHandler={handleLocationChange}
            control={control}
            disabled={!pagePermissions.canCreateJob}
            errors={errors}
            name="location"
            required={true}
            sx={{ minWidth: 400 }}
          />
          <Grid item sx={{ marginTop: '20px' }}>
            <FormControl component="fieldset" sx={{ minWidth: 300 }}>
              <RadioGroup aria-label="options" defaultValue="1" name="options" row>
                <FormControlLabel
                  control={<Radio disabled={!pagePermissions.canCreateJob} />}
                  label={'Import from SalesForce'}
                  onClick={(e) => (pagePermissions.canCreateJob ? handleImportFromSalesForce(e) : null)}
                  value="1"
                />
                <FormControlLabel
                  control={<Radio disabled={!pagePermissions.canCreateJob} />}
                  label={'Blank Job'}
                  onClick={() => (pagePermissions.canCreateJob ? handleBlankJob() : null)}
                  value="2"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item sx={{ marginTop: '20px' }}>
            {isImportFromSalesForce && (
              <FormControl sx={{ minWidth: 400 }} size="medium">
                <Controller
                  name="projectName"
                  rules={{
                    required: ErrorMessages.Error_RequiredField,
                  }}
                  control={control}
                  render={({ field }) => {
                    const { onChange } = field;
                    return (
                      <Autocomplete
                        value={projectName}
                        options={pendingJobs?.map((option) => option.projectName)}
                        onChange={(e, newValue) => {
                          onChange(newValue || null);
                          setProjectName(newValue);
                          setUid(pendingJobs.find((job, i) => job?.projectName === newValue)?.uid);
                        }}
                        disabled={!pagePermissions.canCreateJob}
                        id="control-projectName"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Job Awaiting Approval"
                            error={Boolean(errors.projectName)}
                            helperText={errors.projectName?.message}
                          />
                        )}
                      />
                    );
                  }}
                />
              </FormControl>
            )}
            {isBlankJob && (
              <FormControl sx={{ minWidth: 400 }} size="medium">
                <TextField
                  id="JobName"
                  variant="outlined"
                  placeholder="Job Name"
                  name="jobName"
                  disabled={!pagePermissions.canCreateJob}
                  fullWidth
                  {...register('jobName', {
                    required: ErrorMessages.Error_RequiredField,
                  })}
                  error={Boolean(errors.jobName)}
                  helperText={errors.jobName?.message}
                />
              </FormControl>
            )}
          </Grid>
          <ActionBar display={'flex'} justifyContent={'end'}>
            <ActionSubmitButton disabled={!pagePermissions.canCreateJob} buttonText={'Create'} />
          </ActionBar>
        </form>
      </Grid>
    </Grid>
  );
};

export default PTOnlyJob;
