import { DropDownOptions, Lookup } from 'devextreme-react/lookup';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { forwardRef, useCallback, useState } from 'react';

export const PartNumberLookupDropdown = forwardRef(({ ...props }, ref) => {
  const [selectedValue, setSelectedValue] = useState(props.data.value ?? '');
  const [isDropDownOpened, setIsDropDownOpened] = useState(false);

  const boxOptionChanged = useCallback((e) => {
    if (e.name === 'opened') {
      setIsDropDownOpened(e.value);
    }
  }, []);

  const onSelectionChanged = useCallback(
    (value) => {
      setSelectedValue(value);
      props.data.setValue(value);
      setIsDropDownOpened(false);
    },
    [setSelectedValue, setIsDropDownOpened, props.data]
  );

  const contentRender = useCallback((data) => {
    return (
      <ListItem disablePadding style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
        <ListItemText
          secondaryTypographyProps={{ style: { fontWeight: 800 } }}
          secondary={`${data.partNumber} - ${data.materialId}`}
        />
        <ListItemText secondary={`${data.matDescription}`} />
      </ListItem>
    );
  }, []);

  return (
    <Lookup
      ref={ref}
      onOptionChanged={boxOptionChanged}
      opened={isDropDownOpened}
      dataSource={props.data.column.lookup.dataSource}
      value={selectedValue}
      onValueChanged={(e) => onSelectionChanged(e.value)}
      itemRender={contentRender}
      {...props}
    >
      <DropDownOptions height={'auto'} minHeight={300} minWidth={300} />
    </Lookup>
  );
});
