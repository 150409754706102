import React, { createContext, useContext, useMemo, useState } from 'react';

export const ErrorContext = createContext(null);

export const useErrorContext = () => {
    return useContext(ErrorContext);
};

export const ErrorContextProvider = ({ children }) => {
    const [error, setError] = useState({});

    const value = useMemo(() => ({ error, setError }), [error, setError]);

    return <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>;
};
