import Router from './router';
import Spinner from './components/shared/spinner';
import { AppStateProvider } from './context/appContext';
import { ErrorContextProvider } from './context/errorContext';
import { AxiosInterceptor } from './axiosInterceptor';
import { SnackBarProvider } from './context/snackBarContext';
import CustomSnackBar from './components/shared/snackBarCustom';
import { UserContextProvider } from './context/userContext';
import { QueryClient } from '@tanstack/query-core';
import { QueryClientProvider } from '@tanstack/react-query';
import TagManager from 'react-gtm-module';
import { env } from './env';

//* config query client
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      refetchIntervalInBackground: true,
      refetchInterval: 1000 * 60 * 10, // 10 mins.
      retry: false, //on error refetch.
    },
  },
});

export default function App() {
  
  // Initializing Google Tag manager
  const tagManagerArgs = {
    gtmId: env.REACT_APP_GTM_ID
  };
  
  if(env.REACT_APP_GTM_ID != '') TagManager.initialize(tagManagerArgs);

  return (
    <QueryClientProvider client={queryClient}>
      <AppStateProvider>
        <SnackBarProvider>
          <ErrorContextProvider>
            <UserContextProvider>
              <AxiosInterceptor />
              <Router queryClient={queryClient} />
              <Spinner />
            </UserContextProvider>
          </ErrorContextProvider>
          <CustomSnackBar />
        </SnackBarProvider>
      </AppStateProvider>
    </QueryClientProvider>
  );
}
