import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import ActionBar from '../../../components/shared/actionBar';
import { ActionSubmitButton2 } from '../../../components/shared/actionSubmitButton';
import ActionCancelButton from '../../../components/shared/actionCancelButton';
import { Helmet } from 'react-helmet-async';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useJob } from '../../../utils/masterData';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import GridSkeleton from '../../../components/shared/gridSkeleton';
import { useSnackBar } from '../../../context/snackBarContext';
import { queryClient } from '../../../App';
import AdditionalContactGrid from '../../../components/PT/Contacts/additionalContactGrid';
import JobContactForm from '../../../components/PT/Contacts/jobContactForm';
import { useUserContext } from '../../../context/userContext';

export default function Contacts() {
  const { jobId } = useParams();
  const { user } = useUserContext();
  const { setSnackbar } = useSnackBar();
  const { data: jobData, isLoading: jobLoading, error: jobError } = useJob({ jobId });
  const {
    data: contactData, //service response object.
    isLoading: contactLoading,
    error: contactError,
  } = useQuery({
    queryKey: ['contacts', { jobId }],
    queryFn: async () => (await axios.get(`jobs/${jobId}/contacts`, { loaderRequired: false })).data,
    retry: false,
    refetchOnMount: 'always',
    refetchOnWindowFocus: 'always',
  });
  const { data: locations, isLoading: locationLoading } = useQuery({
    queryKey: ['locations'],
    queryFn: async () => {
      const res = await axios.get('Locations', { loaderRequired: false });
      return res.data;
    },
    retry: false,
  });

  const updateContact = useMutation({
    mutationFn: (contact) => axios.put(`jobs/${jobId}/contacts`, contact),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ['contacts', { jobId }] });
    },
  });

  const dataGridRef = useRef();
  const form = useForm({
    defaultValues: {
      street: '',
      contact: '',
      city: '',
      email: '',
      state: '',
      zip: '',
      phone: '',
      stoStreet: '',
      stoContact: '',
      stoCity: '',
      stoEmail: '',
      stoState: '',
      stoZip: '',
      stoPhone: '',
      shipToCustomerID: '',
      soldToCustomerID: '',
      stO_PlantId: null,
    },
  });
  const [hasEditData, setHasEditData] = useState(false);

  const pagePermissions = {
    canEdit: user.isPTProjectManager || user.isPTDetailer,
  };

  useEffect(() => {
    if (jobError) setSnackbar({ open: true, message: 'Error getting job name.', severity: 'warning' });
  }, [jobError, setSnackbar]);

  // reset form data when contact data changes, API call.
  useEffect(() => {
    if (contactData?.errors?.length) {
      setSnackbar({
        open: true,
        message: `${contactData.errors.join(',')}`,
        severity: 'warning',
      });
    }
    if (contactData?.value) {
      form.reset({
        ...contactData?.value?.shipToContactDetails, //sales force fields.
        stoStreet: contactData.value?.stoStreet ?? '',
        stoContact: contactData.value?.stoContact ?? '',
        stoCity: contactData.value?.stoCity ?? '',
        stoEmail: contactData.value?.stoEmail ?? '',
        stoState: contactData.value?.stoState ?? '',
        stoZip: contactData.value?.stoZip ?? '',
        stoPhone: contactData.value?.stoPhone ?? '',
        shipToCustomerID: contactData.value?.shipToCustomerID ?? '',
        soldToCustomerID: contactData.value?.soldToCustomerID ?? '',
        stO_PlantId: contactData.value?.stO_PlantId ?? null,
      });
    }
  }, [contactData, setSnackbar, form]);

  // save handler for both grid and form.
  // api call for update contacts.s
  async function updateContacts(component, payload) {
    try {
      await updateContact.mutateAsync(payload);
      setSnackbar({ open: true, message: 'Saved contact successfully.', severity: 'success' });
      component.cancelEditData();
    } catch (error) {
      setSnackbar({ open: true, message: 'Error saving contact.', severity: 'error' });
    }
  }
  const onSubmit = () => {
    dataGridRef.current.instance.saveEditData();
  };

  function onSaving(e) {
    e.cancel = true;
    let allRecords = dataGridRef.current.instance.getVisibleRows();
    const formData = form.getValues();
    let payload = {
      ...formData,
      AdditionalContact: [],
      AdditionalContactToRemove: [],
    };
    e.changes.forEach((change) => {
      if (change.type === 'remove') payload.AdditionalContactToRemove.push({ contactId: change.key });
      else if (change.type === 'insert') {
        payload.AdditionalContact.push(change.data);
      } else {
        // If this is update, get other fields as well
        let row = allRecords.find((r) => r.data?.contactId === change.key);
        if (row?.data) {
          payload.AdditionalContact.push({
            ...row.data,
          });
        }
      }
    });
    e.promise = updateContacts(e.component, payload);
  }

  // project not found.
  // if (!contactLoading && !contactData.value?.projectId) {
  //   return <div>PT Project not found.</div>;
  // }

  return (
    <>
      <Helmet>
        <title>Contacts</title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={12} mb={8}>
          {jobLoading ? <Skeleton width={'40%'} /> : <Typography variant="h1">{jobData?.value?.name}</Typography>}
          {!contactError ? (
            <form onSubmit={form.handleSubmit(onSubmit)}>
              {contactLoading || locationLoading ? (
                <Box display={'flex'} gap={2}>
                  <Skeleton width={'100%'} height={300} />
                  <Skeleton width={'100%'} height={300} />
                </Box>
              ) : (
                <JobContactForm
                  pagePermissions={pagePermissions}
                  locations={locations}
                  form={form}
                  data={contactData}
                />
              )}
              {contactLoading ? (
                <GridSkeleton />
              ) : (
                <AdditionalContactGrid
                  pagePermissions={pagePermissions}
                  dataGridRef={dataGridRef}
                  additionalContactsData={contactData?.value?.additionalContact}
                  onSaving={onSaving}
                  setHasEditData={setHasEditData}
                />
              )}
              <ActionBar
                sx={{
                  justifyContent: 'end',
                  display: 'flex',
                  gap: '10px',
                }}
              >
                <ActionSubmitButton2
                  buttonText={'Save'}
                  type="submit"
                  disabled={
                    !pagePermissions.canEdit || (!Object.keys(form.formState.dirtyFields).length && !hasEditData)
                  }
                />
                <ActionCancelButton
                  buttonText={'cancel'}
                  disabled={!Object.keys(form.formState.dirtyFields).length && !hasEditData}
                  clickHandler={() => {
                    form.reset();
                    dataGridRef.current.instance.cancelEditData();
                  }}
                />
              </ActionBar>
            </form>
          ) : (
            <Grid item> Error occurred while fetching data request.</Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}
