import Box from "@mui/material/Box";
import React from "react";

const ActionBar = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <Box ref={ref} className="action-bar" {...props}>
      {children}
    </Box>
  );
});

export default ActionBar;
