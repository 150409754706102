import { useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import PTOnlyJob from './ptOnlyJob';
import PTAndRebarJob from './ptAndRebarJob';
import MiscJob from './miscJob';

const JobCreation = ({ pagePermissions }) => {
  const [isPTOnly, setPTOnly] = useState(true);
  const [isPtRebar, setIsPtRebar] = useState(false);
  const [isMiscJob, setIsMiscJob] = useState(false);

  function handlePTOnly() {
    setPTOnly(true);
    setIsPtRebar(false);
    setIsMiscJob(false);
  }
  function handlePTRebar() {
    setPTOnly(false);
    setIsPtRebar(true);
    setIsMiscJob(false);
  }
  function handleMiscJob() {
    setPTOnly(false);
    setIsPtRebar(false);
    setIsMiscJob(true);
  }

  return (
    <Grid container spacing={0}>
      <Grid
        sx={{ width: '50%', alignItems: 'center', alignContent: 'center', mb: 8 }}
        xs={12}
        container
        spacing={2}
        direction="column"
        alignItems="center"
        // sx={{
        //   alignItems: 'center',
        //   display: 'flex',
        //   flexWrap: 'wrap',
        //   justifyContent: 'space-between',
        //   marginBottom: '1.5rem',
        // }}
      >
        <Typography variant="h1">Create New PT Job</Typography>
      </Grid>
      <Grid
        //sx={{ width: '50%', alignItems: 'center', alignContent: 'center', mb: 8 }}
        container
        spacing={2}
        
        direction="row"
        justifyContent="center"
        alignItems="center"
      
      >
        <Button
          disabled={!pagePermissions.canCreateJob}
          className={`btn ${isPTOnly && 'btn-active'}`}
          sx={{ marginRight: '6px' ,width:'150px'}}
          onClick={handlePTOnly}
          disableRipple="true"
        >
          PT Only
        </Button>
        <Button
          disabled={!pagePermissions.canCreateJob}
          className={`btn ${isPtRebar && 'btn-active'}`}
          sx={{ marginRight: '6px',width:'150px' }}
          onClick={handlePTRebar}
          disableRipple="true"
        >
          Rebar and PT
        </Button>
        <Button
          disabled={!pagePermissions.canCreateJob}
          className={`btn ${isMiscJob && ' btn-active'}`}
          onClick={handleMiscJob}
          sx={{width: '150px'}}
          disableRipple="true"
        >
          Misc Job(R/99)
        </Button>
      </Grid>

      {isPTOnly && <PTOnlyJob pagePermissions={pagePermissions} />}
      {isPtRebar && <PTAndRebarJob pagePermissions={pagePermissions} />}
      {isMiscJob && <MiscJob pagePermissions={pagePermissions} />}
    </Grid>
  );
};

export default JobCreation;
