import { Grid } from '@mui/material';
import Date from '../../../shared/forms/date';

export default function Dates({ control, pagePermissions }) {
  return (
    <Grid container columnSpacing={{ xs: 2, md: 4 }} rowSpacing={{ xs: 3, md: 4 }}>
      <Grid item xs={12} sm={6}>
        <Date control={control} isDisabled={true} label="Bid Date" name="bidDate" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Date control={control} isDisabled={true} label="Award Date" name="awardDate" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Date isDisabled={!pagePermissions.canEdit} control={control} label="Contract Date" name="contractDate" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Date control={control} isDisabled={true} label="Contract Completion" name="contractCompletion" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Date control={control} isDisabled={true} label="Drawing Date" name="drawingDate" />
      </Grid>
    </Grid>
  );
}
