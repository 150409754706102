import { Helmet } from 'react-helmet-async';
import Header from '../components/Layout/header';
import { Box } from '@mui/material';

export default function NotFound() {
    return (
        <>
            <Helmet>
                <title>Not Found</title>
            </Helmet>
            <Header nosearch />
            <main style={{ 
                alignItems: 'center', 
                display: 'flex',
                flexDirection: 'column',
                height: 'calc(100vh - 87px)',  
                justifyContent: 'center' }}>
                <img src="/404.svg" width={450} height={505} alt="404 Not Found" />
                <Box sx={{
                    backgroundColor: '#3862ae',
                    borderRadius: '6px',
                    color: '#fff',
                    marginTop: '1.5rem',
                    maxWidth: '950px',
                    padding: '1.5rem',
                }}>
                    <strong>Note:</strong> If you reached here through a bookmarked link, please note that there has been a major change in
                    MyProjects since last release. Please remove the bookmark, navigate to the page from home screen and bookmark again for later use.
                </Box>  
                <Box sx={{
                    maxWidth: '950px',
                    padding: '1.5rem 0 0',
                }}>
                    Refresh the page or go back and try again. If you still believe this page is displayed on error, 
                    please reach out to <a href='mailto:helpdesk@cmc.com' style={{ color: '#53b7e8' }}>CMC Helpdesk</a>.
                </Box>
            </main>
        </>
    );
}
