import SaveIcon from '@mui/icons-material/Save';
import { Button, CircularProgress } from '@mui/material';
import React from 'react';

const ActionSubmitButton = ({ buttonText, disabled }) => {
  return (
    <Button className="btn btn-icon" startIcon={<SaveIcon />} type="submit" disabled={disabled}>
      {buttonText}
    </Button>
  );
};

const ActionSubmitButton2 = React.forwardRef(({ buttonText, isDisabled, loading, ...props }, ref) => {
  return (
    <Button
      sx={{ width: '100px' }}
      disabled={isDisabled}
      className="btn btn-icon btn-blue"
      ref={ref}
      startIcon={!loading ? <SaveIcon /> : <CircularProgress size={20} sx={{ color: '#3862ae' }} />}
      type="submit"
      {...props}
    >
      {buttonText}
    </Button>
  );
});

export { ActionSubmitButton, ActionSubmitButton2 };
