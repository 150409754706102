import ModuleList from '../../components/Home/moduleList';
import MyJobs from '../../components/shared/myJobs';
import RecentJobs from '../../components/Home/recentJobs';
import { Card, CardHeader, Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';

export default function Home() {
  return (
    <div>
      <Helmet>
        <title>myProjects Dashboard</title>
      </Helmet>
      <Grid
        container
        spacing={4}
        className="cards"
        sx={{
          color: 'text.primary',
        }}
      >
        <Grid item xs={12} md={4} sx={{ display: 'flex' }}>
          <MyJobs />
        </Grid>
        <Grid item xs={12} md={4} sx={{ display: 'flex' }}>
          <RecentJobs />
        </Grid>
        <Grid item xs={12} md={4} sx={{ display: 'flex' }}>
          <Card className="card">
            <CardHeader className="card-hdr" title="Modules" />
            <ModuleList />
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
