import { useMsal } from '@azure/msal-react';
import { loginRequest } from './config/authConfig';
import PageLoader from '../components/shared/pageLoader';

export default function LoginRedirect() {
  const { instance } = useMsal();

  instance.loginRedirect(loginRequest).catch((e) => {
    console.log(e);
  });

  return <PageLoader />;
}
