import React from 'react';
import { Grid, Typography } from '@mui/material';

const JobHeader = ({ jobName, loadDescription, cutNo }) => {
  return (
    <Grid container spacing={2}  sx={{ marginTop: '1.5rem' }}>
      <Grid item xs={12} sm={4}>
        {jobName ? <Typography variant="h1">{decodeURIComponent(jobName)}</Typography> : null}
      </Grid>
      <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography variant="h1">{loadDescription}</Typography>
      </Grid>
      <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        {cutNo ? <Typography variant="h1">{cutNo}</Typography> : null}
      </Grid>
    </Grid>
  );
};

export default JobHeader;
