import { useEffect, useState } from 'react';
import { Grid, Box, TextField } from '@mui/material';
import ActionBar from '../../shared/actionBar';
import ActionCancelButton from '../../shared/actionCancelButton';
import { ActionSubmitButton2 } from '../../shared/actionSubmitButton';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { removeNumCommas } from '../../../utils/numberFormatter';
import { useSnackBar } from '../../../context/snackBarContext';
import { NumericFormat } from 'react-number-format';
import Tooltip from '@mui/material/Tooltip';

const FormLabels = [
  '1/2" USS Flat Washer',
  '3/4" USS Flat Washer',
  '3/4" Hex Nuts',
  '1/2" x 3" Large Diameter Tapcons',
  '1/2" SDS Drill Bit',
  '5/8" SDS Drill Bit',
  '7/8" SDS Drill Bit',
  'HOG RINGS (BLACK)',
  'TENSION BARS',
  '5/16" Eyebolt',
  '5/16" Machine Lead Anchor',
  '5/16" Fender Washer',
  'Cold Galv Compound',
  '4 1/2" x .045 Metabo Cut Off Wheel Typ 27',
  'Hilti Epoxy',
  'Fast Tack 87 Adhesive 13oz',
  'Vinyl Caps',
  '3/4" B7 Threaded Rod',
  'Length',
  '3/4" B7 Threaded Rod',
  'Length',
  '3/4" B7 Threaded Rod',
  'Length',
];

// default values
const bcMiscPartsForm = [
  { id: 1, value: '' },
  { id: 2, value: '' },
  { id: 3, value: '' },
  { id: 4, value: '' },
  { id: 5, value: '' },
  { id: 6, value: '' },
  { id: 7, value: '' },
  { id: 8, value: '' },
  { id: 9, value: '' },
  { id: 10, value: '' },
  { id: 11, value: '' },
  { id: 12, value: '' },
  { id: 13, value: '' },
  { id: 14, value: '' },
  { id: 15, value: '' },
  { id: 16, value: '' },
  { id: 17, value: '' },
  { id: 18, value: '' },
  { id: 19, value: '' },
  { id: 20, value: '' },
  { id: 21, value: '' },
  { id: 22, value: '' },
  { id: 23, value: '' },
];

const BCAdditionalComponents = () => {
  const [bcMiscParts, setBCMiscParts] = useState(bcMiscPartsForm);
  const [lastSavedValues, setLastSavedValues] = useState(bcMiscPartsForm); 

  const [searchParams] = useSearchParams();
  const { setSnackbar } = useSnackBar();

  const loadId = searchParams.get('loadId');
  const cutId = searchParams.get('cutId');

  const form = useForm({ defaultValues: { bcMiscParts, comments: '' } });
  const formFields = useFieldArray({ control: form.control, name: 'bcMiscParts', keyName: 'key' });

  const watchedValues = form.watch('bcMiscParts');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`PT/Load/${loadId}/Cuts/${cutId}/BCMiscParts`);
        if (response.data.value) {
          // update the default form values.
          const updatedBcMisParts = bcMiscParts.map((item) => {
            const found = response.data.value.find((obj) => obj.id === item.id);
            // gets the value.
            return found ? { ...item, value: found.value } : item;
          });

          setBCMiscParts(updatedBcMisParts);
          setLastSavedValues(updatedBcMisParts)
          form.reset({ bcMiscParts: updatedBcMisParts, comments: response.data.value[0].bcComments ?? '' });
        }
      } catch (error) {}
    };

    fetchData();
  }, [loadId, cutId]);

  async function handleSubmit(formData) {
    const { bcMiscParts, comments } = formData;

    const payload = {
      cutBCMiscParts: bcMiscParts.map((part) => ({
        id: part.id,
        value: parseInt(removeNumCommas(part.value) ?? 0),
      })),
    };

    try {
      await axios.put(`PT/Load/${loadId}/Cuts/${cutId}/BCMiscParts?BCComments=${comments}`, payload);
   
    
      form.reset(formData);
      setBCMiscParts(formData);
      setLastSavedValues(formData.bcMiscParts)
      setSnackbar({
        open: true,
        message: 'Saved successfully',
        severity: 'success',
      });
    } catch (error) {}
  }

  const resetForm = () => {
    form.reset({ bcMiscParts: lastSavedValues }); 
  };

  return (
    <Grid container direction="column" sx={{ mt: 2, mb: 8 }}>
      <form onSubmit={form.handleSubmit((data) => handleSubmit(data))}>
        <Box sx={{ display: 'flex', justifyItems: 'center', flexWrap: 'wrap', gap: 2, alignItems: 'center' }}>
          {formFields.fields.map((item, index) => {
            // Dynamically determine if fields required.
            const isField17 = index === 17;
            const isField18 = index === 18;
            const isField19 = index === 19;
            const isField20 = index === 20;
            const isField21 = index === 21;
            const isField22 = index === 22;

            return (
              <>
                <Controller
                  rules={{
                    required:
                      (isField17 && watchedValues[18]?.value) ||
                      (isField18 && watchedValues[17]?.value) ||
                      (isField19 && watchedValues[20]?.value) ||
                      (isField20 && watchedValues[19]?.value) ||
                      (isField21 && watchedValues[22]?.value) ||
                      (isField22 && watchedValues[21]?.value)
                        ? 'This field is required '
                        : false,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <Tooltip title={FormLabels[index]} arrow>
                      <NumericFormat
                        label={`${FormLabels[index]}`}
                        {...field}
                        thousandSeparator
                        customInput={TextField}
                        helperText={error?.message}
                        error={!!error}
                        isAllowed={({ floatValue }) => {
                          return (
                            floatValue === undefined ||
                            floatValue == '' ||
                            (floatValue >= 0 && floatValue === Math.floor(floatValue))
                          );
                        }}
                        onValueChange={({ floatValue }) => {
                          // Update the form state with the new value
                          form.setValue(`bcMiscParts.${index}.value`, floatValue || '');
                        }}
                       
                      />
                    </Tooltip>
                  )}
                  name={`bcMiscParts.${index}.value`}
                  control={form.control}
                />
                {[3, 6, 10, 13, 16, 22].includes(index) && <span style={{ width: '100%' }}></span>}
              </>
            );
          })}
          <Controller
            control={form.control}
            name={'comments'}
            render={({ field }) => (
              <TextField
                {...field}
                sx={{ minWidth: 900 }}
                label="Comments"
                multiline
                rows={3}
                variant="outlined"
                inputProps={{ maxLength: 500 }}
              />
            )}
          />
        </Box>
        <ActionBar
          sx={{
            justifyContent: 'end',
            display: 'flex',
            gap: '10px',
          }}
        >
          <ActionSubmitButton2
            buttonText={'Save'}
            disabled={!(Object.keys(form.formState.dirtyFields).length ?? false)}
          />
          <ActionCancelButton
            buttonText={'Cancel'}
            disabled={!(Object.keys(form.formState.dirtyFields).length ?? false)}
            clickHandler={resetForm}
          />
        </ActionBar>
      </form>
    </Grid>
  );
};
export default BCAdditionalComponents;
