import { Chip, FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { forwardRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';

export const MultiSelect = forwardRef(
  ({ selected, onChange, setSelected, onDelete, options, name, value, placeholder, ...props }, ref) => {
    return (
      <FormControl ref={ref} {...props}>
        <InputLabel size="small">{placeholder}</InputLabel>
        <Select
          size="small"
          multiple
          value={selected}
          label={placeholder}
          onChange={onChange}
          sx={{ height: 45 }}
          renderValue={(selected) => (
            <Stack
              gap={0.5}
              direction="column"
              flexWrap="wrap"
              pt={1}
              maxHeight={40}
              alignItems="center"
              overflow="scroll"
            >
              {selected.map((item) => (
                <Chip
                  sx={{ background: '#3862ae', color: 'white' }}
                  key={item[value]}
                  size="small"
                  label={item[name]}
                  onDelete={() => onDelete(item)}
                  deleteIcon={<CloseIcon onMouseDown={(event) => event.stopPropagation()} />}
                />
              ))}
            </Stack>
          )}
        >
          {options.map((option) => (
            <MenuItem key={option[value]} value={option}>
              {option[name]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
);
