import { Controller } from 'react-hook-form';
import { Autocomplete, FormControl, TextField } from '@mui/material';

export default function AutoComplete({ control, data, isRequired, label, name, isDisabled }) {
  return (
    <FormControl size="medium" fullWidth>
      {data && (
        <Controller
          control={control}
          name={name}
          render={({ field }) => {
            return (
              <Autocomplete
                disabled={isDisabled}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(_, newValue) => field.onChange(newValue ? newValue?.id : null)}
                options={data}
                renderInput={(params) => <TextField {...params} label={label} />}
                required={isRequired}
                value={field.value ? data.find((x) => field.value === x.id) : null}
              />
            );
          }}
        />
      )}
    </FormControl>
  );
}
