import { useContext, useEffect, useRef, useState } from 'react';
import UserInfo from './userInfo';
import { UserContext } from '../../auth/authentication';
import { Box, Button, Stack } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';

export default function ProfileNav() {
    const accountInfo = useContext(UserContext);
    const firstName = accountInfo?.name?.split(',')[1];
    const lastName = accountInfo?.name?.split(',')[0];
    const fullName = `${firstName} ${lastName}`;
    const triggerRef = useRef();
    const navRef = useRef();
    const [navOpen, setNavOpen] = useState(false);

    useEffect(() => {
        const hasClickedOutside = e => {
          if (navOpen && navRef.current !== e.target) {
            setTimeout(() => { setNavOpen(false) }, 150);
          }
        } 

        document.addEventListener('mousedown', hasClickedOutside);
    
        return () => {
          document.removeEventListener('mousedown', hasClickedOutside);
        }
    }, [navOpen]);

    function clickHandler(e) {
        setNavOpen((prevState) => !prevState);    
    }

    return (
        <Box className={`profile-nav ${navOpen && 'nav-open'}`}>
            {firstName && (<UserInfo user={firstName} />)}
            <Stack className="profile-nav-trigger">
                <Button 
                    disableRipple
                    className="profile-nav-btn"
                    ref={triggerRef}
                    title={`${fullName ? fullName : ''}`}
                    onClick={clickHandler}>
                    <AccountCircleIcon className="profile-nav-icon" />
                </Button>
            </Stack>
            {navOpen && (
                <Stack className="profile-nav-menu" direction="row" ref={navRef}>
                    <a href="https://myprojects.cmc.com/Home/Help" className="profile-nav-link" target="_blank" rel="noreferrer">
                        <Button>
                            <HelpOutlineIcon />
                            Help
                        </Button>
                    </a>
                    <a href="https://myprojects.cmc.com/Content/Documents/myProjectsFAQ.pdf" className="profile-nav-link" target="_blank" rel="noreferrer">
                        <Button>
                            <ForumOutlinedIcon />
                            FAQs
                        </Button>
                    </a>                    
                </Stack>
            )}
        </Box>
    )
}