import { Outlet, useLocation } from 'react-router-dom';
import Header from '../../components/Layout/header';
import BreadCrumbsCustom from '../../components/shared/breadCrumb';
import { DisplayError } from '../../components/PT/displayError'; 

export default function Layout() {
    const location = useLocation();
    return (
        <>
            <Header />
            <main>
                <DisplayError />
                {location.pathname !== '/' && <BreadCrumbsCustom />}
                <Outlet />
            </main>
        </>
    );
}
