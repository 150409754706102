import EstimatesForm from '../../../components/PT/MainInfo/estimatesForm';
import { Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useUserContext } from '../../../context/userContext';
import { useJob } from '../../../utils/masterData';
import PageLoader from '../../../components/shared/pageLoader';
import { useParams } from 'react-router-dom';

export default function Estimates() {
  const { user } = useUserContext();
  const { jobId } = useParams();
  const { data: job, isLoading: jobLoading } = useJob({ jobId });

  if (jobLoading) {
    return <PageLoader />;
  }

  //* cam user
  //* job not on hold
  const pagePermissions = {
    canEdit: user.isCAM && !job?.value?.jobOnHold,
  };

  return (
    <>
      <Helmet>
        <title>Estimate</title>
      </Helmet>
      <Grid container spacing={2}>
        <EstimatesForm pagePermissions={pagePermissions} />
      </Grid>
    </>
  );
}
