import { Link } from "react-router-dom";

export default function Logo() {
  return (
    <div className="logo">
      <Link to="/">
        <img src="/logos/logo.svg" alt="myProjects" width="168" height="50" />
      </Link>
    </div>
  );
}
