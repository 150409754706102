import { Helmet } from 'react-helmet-async';
import { useParams, useSearchParams } from 'react-router-dom';
import { StudRailChairsForm } from '../../../components/PT/StudRail/studRailChairsForm';
import { Box, Typography } from '@mui/material';

export default function StudRailQuantitiesChairs() {
  const [searchParams] = useSearchParams();
  const { loadDescription } = useParams();
  const jobName = searchParams.get('jn'); //job name

  return (
    <>
      <Helmet>
        <title>Stud Rail Accessories</title>
      </Helmet>
      <Typography variant="h1">
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={2}>
          <Typography variant="h1">{decodeURIComponent(jobName)}</Typography>
          <Typography variant="h1">{loadDescription}</Typography>
        </Box>
      </Typography>
      <StudRailChairsForm />
    </>
  );
}
