import { useParams, useSearchParams } from 'react-router-dom';
import { Grid, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import DataGrid, { Column, Scrolling, Selection, HeaderFilter } from 'devextreme-react/data-grid';
import { useRef, useState, useEffect } from 'react';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { RiBookletLine } from 'react-icons/ri';
import HandymanIcon from '@mui/icons-material/Handyman';
import ListIcon from '@mui/icons-material/List';
import AssessmentIcon from '@mui/icons-material/Assessment';
import GridSkeleton from '../../shared/gridSkeleton';
import { usePDFViewerModal } from '../../Modal/pdfViewerModal';
import { CUT_STAUTS, LOAD_TYPE, REPORT_NAMES } from '../../../common/constants';
import { useSnackBar } from '../../../context/snackBarContext';

export default function ShopPaperWorkForm({ pagePermissions }) {
  const { jobId, loadDescription } = useParams();
  const [searchParams] = useSearchParams();
  const loadId = searchParams.get('loadId');
  const jobName = searchParams.get('jobName');
  const cutsDataGridRef = useRef();
  const [isStudRailLoad, setIsStudRailLoad] = useState(null);

  const cuts = useQuery({
    queryKey: ['cuts', { loadId }],
    queryFn: async () => (await axios.get(`jobs/${jobId}/loads/${loadId}/cuts`, { loaderRequired: false })).data,
    refetchOnMount: 'always',
  });
  const { setSnackbar } = useSnackBar();
  const [fileUrl, setFileUrl] = useState('');
  const [fileName, setFileName] = useState('');
  const [selectedCutsId, setSelectedCutsId] = useState([]);

  const { PDFViewerModal, setPdfViewerOpen, pdfViewerOpen } = usePDFViewerModal({ fileName, fileUrl });

  // check load type
  useEffect(() => {
    if (cuts?.data?.value) {
      const cutsData = cuts.data.value;
      setIsStudRailLoad(cutsData.some((cut) => cut.type === LOAD_TYPE.studRails));
    }
  }, [cuts]);

  const printReportUrls = {
    [REPORT_NAMES.loadSummary]: `/PT/schedules/loadsummarysheet?loadId=${loadId}`,
    [REPORT_NAMES.looseComponents]: `/PT/schedules/looseComponentsReport?loadId=${loadId}`,
    [REPORT_NAMES.fabOrder]: `/PT/schedules/cutlist?loadId=${loadId}&projectID=${jobId}`,
    [REPORT_NAMES.tag]: `/PT/schedules/printtag`,
    [REPORT_NAMES.studrailPaperwork]: `/PT/schedules/studrailPaperwork`,
    [REPORT_NAMES.looseComponentsDetailed]: `/PT/schedules/looseComponentsDetailedReport?loadId=${loadId}`,
    [REPORT_NAMES.ptiPaperwork]: `/PT/schedules/PTIFilingReport?loadId=${loadId}`,
    [REPORT_NAMES.barrierCableFabOrder]: `/PT/schedules/BCFabOrderReport?loadId=${loadId}&jobId=${jobId}`,
  };

  async function printReport(reportName, fileName, params = null) {
    try {
      const url = printReportUrls[reportName];
      const response = await axios.get(url, {
        params: params,
        paramsSerializer: { indexes: null },
        responseType: 'blob',
        headers: {
          Accept: 'application/pdf',
        },
      });

      const fileUrl = URL.createObjectURL(new File([response.data], fileName, { type: 'application/pdf' }));
      setFileName(fileName);
      setFileUrl(fileUrl);
      setPdfViewerOpen(true);
    } catch (error) {}
  }

  async function validatePTIShopPaperworkReport() {
    try {
      const response = await axios.get(`pt/Schedules/validatePTIFilingReport?loadId=${loadId}`);
      if (response.data?.errors) {
        setSnackbar({
          message: (
            <Typography>
              Could not locate the MTR files for the following heat numbers: {response.data.errors.join(', ')}
            </Typography>
          ),
          open: true,
          severity: 'info',
          isAction: true,
        });
      }
    } catch (error) {}
  }

  const paperWorks = [
    {
      id: 1,
      name: 'Print Tags',
      icon: <LocalOfferIcon sx={{ color: '#3862ae' }} />,
      // disabled if load is stud rail type and if can't print tags.
      disabled: selectedCutsId.length === 0 || !pagePermissions.canPrintTag || isStudRailLoad,
      hidden: !pagePermissions.canPrintTag,
      print: async () => {
        await printReport(REPORT_NAMES.tag, `${selectedCutsId.join('-')}-print_tags.pdf`, {
          cutIds: selectedCutsId,
          loadId,
        });
        // refetch cuts for status update changes.
        await cuts.refetch();
      },
    },
    {
      id: 2,
      name: 'PT FAB Order Report',
      icon: <ListIcon sx={{ color: '#3862ae' }} />,
      print: () => printReport(REPORT_NAMES.fabOrder, `${jobId}-${loadDescription}-fabrication_order.pdf`),
      disabled: false,
      hidden: false,
    },
    {
      id: 3,
      name: REPORT_NAMES.barrierCableFabOrder,
      icon: <ListIcon sx={{ color: '#3862ae' }} />,
      print: () =>
        printReport(REPORT_NAMES.barrierCableFabOrder, `${loadId}-${loadDescription}-barrierCableFabOrderReport.pdf`),
      disabled: false,
      hidden: false,
    },
    {
      id: 4,
      name: 'Load Summary Report',
      icon: <AssessmentIcon sx={{ color: '#3862ae' }} />,
      print: () => printReport(REPORT_NAMES.loadSummary, `${loadId}-${loadDescription}-LoadSummarySheet.pdf`),
      disabled: false,
      hidden: false,
    },
    {
      id: 5,
      name: 'Loose Components Report',
      icon: <HandymanIcon sx={{ color: '#3862ae' }} />,
      print: () =>
        printReport(REPORT_NAMES.looseComponents, `${loadId}-${loadDescription}-PT_LooseComponentsReport.pdf`),
      disabled: false,
      hidden: false,
    },
    {
      id: 6,
      name: 'Loose Components Detailed Report',
      icon: <HandymanIcon sx={{ color: '#3862ae' }} />,
      print: () =>
        printReport(
          REPORT_NAMES.looseComponentsDetailed,
          `${loadId}-${loadDescription}-PT_LooseComponentsDetailedReport.pdf`
        ),
      disabled: false,
      hidden: false,
    },
    {
      id: 7,
      name: 'Studrail Paperwork',
      icon: <RiBookletLine size={20} color="#3862ae" />,
      print: () =>
        printReport(REPORT_NAMES.studrailPaperwork, `${loadId}-${loadDescription}-studrailPaperworkReport.pdf`, {
          cutIds: selectedCutsId, // selected cuts or all the cuts if null.
          loadId,
          jobId,
        }),
      disabled: false,
      hidden: !isStudRailLoad,
    },
    {
      id: 8,
      name: REPORT_NAMES.ptiPaperwork,
      icon: <RiBookletLine size={20} color="#3862ae" />,
      print: () => printReport(REPORT_NAMES.ptiPaperwork, `${loadId}-${loadDescription}-PTIPaperwork.pdf`),
      disabled: false,
      hidden: isStudRailLoad,
      validate: () => validatePTIShopPaperworkReport(),
    },
  ];

  function onSelectionChanged(e) {
    const deselectRowKeys = [];
    const selectedCutIds = []; // for button state

    e.selectedRowKeys.forEach((item) => {
      // check for status>=date-approved && type is NOT Stud Rail.
      // if status <= release to shop. add to deselect.
      if (item.statusId <= CUT_STAUTS.releaseToShop && item.type !== LOAD_TYPE.studRails) {
        deselectRowKeys.push(e.component.keyOf(item));
      } else selectedCutIds.push(item.cutId);
    });
    if (deselectRowKeys.length) e.component.deselectRows(deselectRowKeys);

    setSelectedCutsId(selectedCutIds);
  }

  if (cuts.error) return <div>Error getting cuts data</div>;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} display={'flex'} alignItems={'baseline'} justifyContent={'space-between'} mt={1}>
        <Typography variant="h1">{`${jobName ?? ''} (${jobId})`}</Typography>
        {loadDescription ? (
          <Typography variant="h1">
            Load #: <span>{loadDescription}</span>
          </Typography>
        ) : null}
      </Grid>

      <Grid item xs={12} sm={6}>
        {paperWorks.map((items, index) =>
          !items.hidden ? (
            <ListItemButton
              key={index.id}
              sx={{
                width: '100%',
                border: '1px solid #F0F0F0',
                boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                ':hover': { color: '#3862ae', textUnderlineOffset: 2, textDecoration: 'underline' },
              }}
              component="button"
              onClick={async () => {
                if (items?.validate) {
                  await items?.validate();
                }
                await items.print();
              }}
              disabled={items.disabled}
              hidden={items.hidden}
            >
              <ListItemIcon>{items.icon}</ListItemIcon>
              <ListItemText primary={`${items.name}`} />
            </ListItemButton>
          ) : null
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        {cuts.isLoading ? (
          <GridSkeleton />
        ) : (
          <DataGrid
            dataSource={cuts.data?.value}
            ref={cutsDataGridRef}
            showBorders={true}
            columnAutoWidth={true}
            rowAlternationEnabled="true"
            className="grid-cuts"
            height={440}
            onSelectionChanged={onSelectionChanged}
          >
            <Scrolling mode="infinite" />
            <HeaderFilter visible={true} />
            {pagePermissions.canPrintTag ? <Selection mode="multiple" showCheckBoxesMode="none" /> : null}
            <Column dataField={'description'} caption={'Description'} />
            <Column dataField="cutStatus" caption={'Cut status'} dataType="string" />
          </DataGrid>
        )}
      </Grid>
      {pdfViewerOpen ? <PDFViewerModal /> : null}
    </Grid>
  );
}
