import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSnackBar } from '../../../context/snackBarContext';
import axios from 'axios';
import { NumericFormat } from 'react-number-format';
import HandymanIcon from '@mui/icons-material/Handyman';
import CutsGrid from './cutsGrid';
import AnchorIcon from '@mui/icons-material/Anchor';
import { FaGripLines } from 'react-icons/fa';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import BuildIcon from '@mui/icons-material/Build';
import {
  Column,
  DataGrid,
  Editing,
  Format,
  HeaderFilter,
  Lookup,
  MasterDetail,
  RequiredRule,
  Scrolling,
  SearchPanel,
  Selection,
  StateStoring,
  Button as DevextremeButton,
} from 'devextreme-react/data-grid';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import { FaFileImport } from 'react-icons/fa6';
import { RiBookletLine } from 'react-icons/ri';
import ActionBar from '../../shared/actionBar';
import { ActionSubmitButton2 } from '../../shared/actionSubmitButton';
import ActionCancelButton from '../../shared/actionCancelButton';
import { useJob } from '../../../utils/masterData';
import GridSkeleton from '../../shared/gridSkeleton';
import { convertToGMT } from '../../../utils/dateUtils';
import PageLoader from '../../shared/pageLoader';
import { BID_ITEMS } from '../../../common/constants';

export default function LoadsForm({ pagePermissions }) {
  const { jobId } = useParams();
  const { setSnackbar } = useSnackBar();
  const loadsDataGridRef = useRef(null);
  const cutsGridRef = useRef(null);
  const [loadData, setLoadData] = useState();
  const [loading, setLoading] = useState(true);
  const [loadStatusLookUp, setLoadStatusLookUp] = useState();
  const [additionalItemsLookUp, setAdditionalItemsLookUp] = useState();
  const [hasEditData, setHasEditData] = useState(false);
  const [selectedLoad, setSelectedLoad] = useState();
  const [selectedRowKey, setSelectedRowKey] = useState({ selectedRowKeys: [] });
  const [accPickBtnState, setAccPickBtnState] = useState({
    isDisabled: true,
    cutId: null,
    loadId: null,
    cutNo: null,
    cutBidItemId: null,
  });

  //Get the job details
  const { data: jobDetail, isLoading: jobDetailLoading } = useJob({ jobId: jobId });

  const navigate = useNavigate();
  const isBCSeries = /^BC\d+$/.test(accPickBtnState.cutNo);

  //* get all data api calls
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        // Make calls to API concurrently
        const promises = await Promise.all([
          axios.get(`jobs/${jobId}/loads?type=1`, { loaderRequired: false }),
          axios.get(`pt/loadstatus`, { loaderRequired: false }),
          axios.get(`pt/additionalItems`, { loaderRequired: false }),
        ]);

        setLoadData(promises[0]?.data);
        setLoadStatusLookUp(promises[1]?.data);
        setAdditionalItemsLookUp(promises[2]?.data);
      } catch (error) {
        setSnackbar({
          open: true,
          message: 'Error getting PT-Quantities data',
          severity: 'error',
        });
      } finally {
        setLoading(false);
      }
    })();
  }, [jobId, setSnackbar]);

  //* row focus handler
  function handleRowFocus(e) {
    if (e.row.key) {
      setSelectedRowKey([e.row.key]);
      setSelectedLoad(e.row.data);
    }
  }

  //* add row button handler
  function handleAddRowButton() {
    loadsDataGridRef.current.instance.addRow();
  }

  //* removes the data grid toolbar header buttons
  function handleOnToolbarPreparing(e) {
    //* remove add save and reset
    e.toolbarOptions.items.splice(0, 3);
  }

  //* handle edit btn state
  function handleOnContentReady(e) {
    setHasEditData(e.component.hasEditData());
  }

  //* cancel/reset button handler
  function handleCancelButton() {
    loadsDataGridRef.current?.instance.cancelEditData();
    cutsGridRef.current?.instance.cancelEditData();
  }

  //* save button handler
  function handleSaveButton() {
    loadsDataGridRef.current?.instance.saveEditData();
    cutsGridRef.current?.instance.saveEditData();
    setHasEditData(true);
  }

  function onImportCutsClick() {
    if (!selectedLoad || selectedLoad.loadId === undefined || selectedLoad.loadId <= 0) {
      setSnackbar({
        open: true,
        message: `Please select a load`,
        severity: 'error',
      });
      return;
    }

    navigate(`${selectedLoad.description}/cut-import?loadId=${selectedLoad.loadId}&mode=import`, {
      state: { loadId: selectedLoad.loadId, jobName: jobDetail?.value?.name },
    });
  }

  //* refresh datagrid data on insert new row or delete of row
  async function refresh() {
    try {
      const loadResponse = await axios.get(`jobs/${jobId}/loads?type=1`, { loaderRequired: false });
      setLoadData(loadResponse?.data);
    } catch (error) {
      console.log(error);
    }
  }

  //* onSaving datagrid handler
  async function handleSaving(e) {
    let changes = e.changes;

    let payload = { loads: [], loadsToRemove: [] };
    let haveInsert = false;
    let allRecords = loadsDataGridRef.current.instance.getVisibleRows();

    // if no changes no api call.
    if (!changes?.length) {
      e.cancel = true;
      e.component?.cancelEditData();
      return;
    }

    changes.map((change) => {
      //* logic for remove
      if (change.type === 'remove') {
        payload.loadsToRemove.push({ loadId: change.key });
      } else if (change.type === 'insert') {
        payload.loads.push({
          ...change.data,
          deliveryDate: convertToGMT(new Date(change.data.deliveryDate)),
          submittalDate: convertToGMT(new Date(change.data.submittalDate)),
        });

        haveInsert = true;
      } else {
        //* If this is update, get other fields as well
        let row = allRecords.find((r) => r.data?.loadId === change.key);
        if (row?.data) {
          payload.loads.push({
            ...row.data,
            deliveryDate: convertToGMT(new Date(row.data.deliveryDate)),
            submittalDate: convertToGMT(new Date(row.data.submittalDate)),
          });
        }
      }
      payload.loads.forEach((item) => {
        item.type = 1;
      });
      return payload;
    });

    axios
      .put(`jobs/${jobId}/loads?type=1`, payload, { loaderRequired: false })
      .then(async () => {
        setSnackbar({
          open: true,
          message: `Saved successfully`,
          severity: 'success',
        });
        if (haveInsert) await refresh();
      })
      .catch(async (error) => {
        console.log(error);
        setSnackbar({
          open: true,
          message: `Error occured while saving loads.`,
          severity: 'error',
        });
      });
  }

  //* render percent completion column values.s
  function percentCompletionRender({ data: rowProps }) {
    let value = 0;
    if (rowProps.data.actualLF) value = (rowProps.data.actualLF * 100.0) / rowProps.data.estLF;
    return (
      <NumericFormat
        value={value}
        decimalScale={0}
        style={{ background: 'inherit', border: 'none', outline: 'none' }}
        readOnly
      />
    );
  }

  //* adds default values for new row insert in loads grid
  function onInitNewRow(e) {
    e.data.drawingStatusId = 1; // Design
    e.data.additionalItemsId = 1; //None
  }

  //* render the cut child grid.
  const renderCutsForm = useCallback(
    (props) => {
      return (
        <CutsGrid
          {...props}
          jobName={jobDetail?.value?.name}
          pagePermissions={pagePermissions}
          setAccPickBtnState={setAccPickBtnState}
          refresh={refresh}
          cutsGridRef={cutsGridRef}
          setHasEditData={setHasEditData}
        />
      );
    },
    [jobDetail?.value?.name, pagePermissions, setAccPickBtnState, setHasEditData, cutsGridRef]
  );

  //* Delete Icon visible render method - Based on cuts.
  const deleteButtonVisible = useCallback(
    (rowProps) => {
      return !rowProps.row.data?.hasCuts && pagePermissions.canEditLoad;
    },
    [pagePermissions]
  );
  const handleBCAddOnsClick = useCallback(() => {
    if (selectedLoad?.description && selectedLoad?.loadId) {
      navigate(
        `${selectedLoad.description}/Barrier Cable Steel Post & ACC?loadId=${selectedLoad.loadId}&jn=${encodeURIComponent(
          jobDetail?.value?.name
        )}`
      );
    } else {
      setSnackbar({
        open: true,
        message: `Please select a load`,
        severity: 'error',
      });
    }
  }, [jobDetail, navigate, selectedLoad, setSnackbar]);
  const handleAccessoriesBtnClick = useCallback(() => {
    if (selectedLoad?.description && selectedLoad?.loadId) {
      navigate(
        `${selectedLoad.description}/Accessories?loadId=${selectedLoad.loadId}&jn=${encodeURIComponent(
          jobDetail?.value?.name
        )}`
      );
    } else {
      setSnackbar({
        open: true,
        message: `Please select a load`,
        severity: 'error',
      });
    }
  }, [jobDetail, navigate, selectedLoad, setSnackbar]);

  const handleDrawings = useCallback(() => {
    if (selectedLoad?.description && selectedLoad?.loadId) {
      navigate(
        `${selectedLoad.description}/Link Drawings?loadId=${selectedLoad.loadId}&jn=${encodeURIComponent(
          jobDetail?.value?.name
        )}`
      );
    } else {
      setSnackbar({
        open: true,
        message: `Please select a load`,
        severity: 'error',
      });
    }
  }, [jobDetail, navigate, selectedLoad, setSnackbar]);

  const handleBCAdditionalComponents = useCallback(() => {
    if (selectedLoad?.description && selectedLoad?.loadId) {
      navigate(
        `${selectedLoad.description}/cuts/Barrier Cable Additional Components?loadId=${selectedLoad.loadId}&cutId=${
          accPickBtnState.cutId
        }&jn=${encodeURIComponent(jobDetail?.value?.name)}&cutNo=${accPickBtnState.cutNo}`
      );
    } else {
      setSnackbar({
        open: true,
        message: `Please select a load`,
        severity: 'error',
      });
    }
  }, [jobDetail, navigate, selectedLoad, setSnackbar, accPickBtnState]);

  const handleHardwareBtnClick = useCallback(() => {
    if (selectedLoad?.description && selectedLoad?.loadId) {
      navigate(
        `${selectedLoad.description}/cuts/Hardwares?loadId=${selectedLoad.loadId}&cutId=${
          accPickBtnState.cutId
        }&jn=${encodeURIComponent(jobDetail?.value?.name)}&cutNo=${accPickBtnState.cutNo}`
      );
    } else {
      setSnackbar({
        open: true,
        message: `Please select a load`,
        severity: 'error',
      });
    }
  }, [jobDetail, selectedLoad, accPickBtnState, navigate, setSnackbar]);

  const handlePTIFilling = useCallback(() => {
    if (selectedLoad?.description && selectedLoad?.loadId) {
      navigate(
        `${selectedLoad.description}/PTI Filing?loadId=${selectedLoad.loadId}&jn=${encodeURIComponent(
          jobDetail?.value?.name
        )}`
      );
    } else {
      setSnackbar({
        open: true,
        message: `Please select a load`,
        severity: 'error',
      });
    }
  }, [jobDetail, navigate, selectedLoad, setSnackbar]);

  if (jobDetailLoading) {
    return <PageLoader />;
  }
  // job not found.
  if (!jobDetailLoading && (!jobDetail?.value?.isPTJob || !jobDetail?.value)) {
    return <span>Job not found</span>;
  }

  return (
    <Box marginBottom={12} width={'100%'} display={'flex'} flexDirection={'column'}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          paddingBottom: 2,
        }}
      >
        <Typography variant="h1">
          {jobDetailLoading ? (
            <Skeleton sx={{ width: '400px', height: '100%' }} />
          ) : (
            `${jobDetail?.value?.name} (${jobId})`
          )}
        </Typography>
        {pagePermissions.canEdit ? (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Button className="btn" onClick={handleAddRowButton} disabled={!pagePermissions.canEditLoad}>
              Create Load
            </Button>
            <Button
              className="btn"
              disabled={!jobDetail?.value?.existingInSAP}
              startIcon={<FaFileImport size={20} />}
              onClick={onImportCutsClick}
            >
              Import Cuts
            </Button>
          </Box>
        ) : null}
      </Box>
      <Box height={'100%'} display={'flex'}>
        {loading ? (
          <GridSkeleton />
        ) : (
          <DataGrid
            ref={loadsDataGridRef}
            id="gridContainer"
            className="grid-container"
            dataSource={loadData}
            rowAlternationEnabled={true}
            focusedRowEnabled={true}
            keyExpr={'loadId'}
            columnResizingMode="nextColumn"
            showBorders
            wordWrapEnabled
            allowColumnReordering
            allowColumnResizing
            //allowEditing={pagePermissions.canEditLoad}
            onSaving={handleSaving}
            onContentReady={handleOnContentReady}
            onInitNewRow={onInitNewRow}
            onToolbarPreparing={handleOnToolbarPreparing}
            onFocusedRowChanged={handleRowFocus}
            selectedRowKeys={Array.from(selectedRowKey)}
            //* expand only one row at a time
            onRowExpanding={(e) => e.component.collapseAll(-1)}
            onRowCollapsed={(e) =>
              setAccPickBtnState({ cutId: null, isDisabled: true, loadId: null, cutBidItemId: null, cutNo: null })
            }
            onEditorPreparing={(e) => {
              if (e.row.isNewRow && e.dataField === 'deliveryDate') {
                e.editorOptions.readOnly = false;
              } else if (e.dataField === 'deliveryDate') {
                if (e.row.data?.productionStatusId !== 1) e.editorOptions.readOnly = true;
              }
            }}
          >
            <MasterDetail enabled component={renderCutsForm} />
            <SearchPanel visible={false} placeholder="Search..." />
            <HeaderFilter visible />
            <StateStoring enabled type="localStorage" storageKey="load-data-grid" />
            <Selection mode="single" />
            <Scrolling mode="standard" />
            <Editing
              mode="batch"
              selectTextOnEditStart={true}
              allowEditing={pagePermissions.canEditLoad}
              allowUpdating={pagePermissions.canEditLoad}
              allowDeleting={pagePermissions.canEditLoad}
            />
            <Column
              dataField={'description'}
              width={170}
              allowFiltering={false}
              caption={'Load Description'}
              allowSearch
              editorOptions={{ maxLength: 15 }}
            >
              <RequiredRule />
            </Column>
            <Column
              dataField={'submittalDate'}
              allowFiltering={false}
              caption={'Submittal Date'}
              dataType="date"
              format={'MM/dd/yyyy'}
              width={115}
            >
              <RequiredRule />
            </Column>
            <Column
              dataField={'deliveryDate'}
              allowFiltering={false}
              caption={'Delivery Date'}
              dataType="date"
              format={'MM/dd/yyyy'}
              width={115}
            >
              <RequiredRule />
            </Column>
            <Column dataField={'estLF'} dataType='number' width={100} allowFiltering={false} caption={'Est LF'}>
              <RequiredRule />
              <Format type="fixedPoint" />
            </Column>
            <Column dataField={'actualLF'} allowFiltering={false} caption={'Actual LF '} allowEditing={false}>
              <Format type="fixedPoint" />
            </Column>
            <Column dataField={'estDeadEnds'} dataType='number' width={90} allowFiltering={false} caption={'Est Dead'}>
              <RequiredRule />
              <Format type="fixedPoint" />
            </Column>
            <Column dataField={'estLiveEnds'} dataType='number' width={90} allowFiltering={false} caption={'Est Live End'}>
              <RequiredRule />
              <Format type="fixedPoint" />
            </Column>
            <Column dataField={'estContInter'} dataType='number' width={90} allowFiltering={false} caption={'Est Cont Inter'}>
              <RequiredRule />
              <Format type="fixedPoint" />
            </Column>
            <Column dataField={'estCouplers'} dataType='number' width={100} allowFiltering={false} caption={'Est Coupler'}>
              <RequiredRule />
              <Format type="fixedPoint" />
            </Column>
            <Column caption={'% Complete'} width={95} allowFiltering={false} cellComponent={percentCompletionRender}>
              <Format type="fixedPoint" />
            </Column>
            <Column dataField={'additionalItemsId'} allowFiltering={false} width={120} caption={'Additional Items'}>
              <RequiredRule />
              <Lookup dataSource={additionalItemsLookUp} valueExpr="id" displayExpr="description" />
            </Column>
            <Column dataField={'drawingStatusId'} width={180} allowFiltering caption={'Drawing Status'}>
              <RequiredRule />
              <Lookup dataSource={loadStatusLookUp} valueExpr="id" displayExpr="description" />
            </Column>
            <Column type="buttons">
              <DevextremeButton name="delete" visible={deleteButtonVisible} />
            </Column>
          </DataGrid>
        )}
      </Box>
      <ActionBar
        sx={{
          justifyContent: 'end',
          display: 'flex',
          gap: '10px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'start',
            gap: 2,
          }}
        >
          <Button
            className="btn"
            onClick={handleHardwareBtnClick}
            disabled={accPickBtnState?.isDisabled || !jobDetail?.value?.existingInSAP}
            startIcon={<AnchorIcon />}
          >
            Hardware
          </Button>
          <Button className="btn" startIcon={<RiBookletLine size={20} />} onClick={handlePTIFilling}>
            PTI Filing
          </Button>
          <Button
            className="btn"
            startIcon={<HandymanIcon size={20} />}
            disabled={!selectedLoad?.loadId || !jobDetail?.value?.existingInSAP}
            onClick={handleAccessoriesBtnClick}
          >
            Accessories
          </Button>
          <Button
            className="btn"
            startIcon={<FaGripLines size={20} />}
            disabled={
              accPickBtnState.cutBidItemId !== BID_ITEMS.barrierCable || isBCSeries || !jobDetail?.value?.existingInSAP
            }
            onClick={handleBCAddOnsClick}
          >
            BC Steel Post & Acc
          </Button>
          <Button
            className="btn"
            startIcon={<CloudUploadIcon />}
            disabled={!selectedLoad?.loadId || !jobDetail?.value?.existingInSAP}
            onClick={handleDrawings}
          >
            Link Drawings
          </Button>
          <Button
            className="btn"
            startIcon={<BuildIcon />}
            disabled={
              accPickBtnState.cutBidItemId !== BID_ITEMS.barrierCable || isBCSeries || !jobDetail?.value?.existingInSAP
            }
            onClick={handleBCAdditionalComponents}
          >
            BC Additional Components
          </Button>
        </Box>
        <ActionSubmitButton2
          buttonText={'Save'}
          disabled={!hasEditData || !pagePermissions.canEdit}
          onClick={handleSaveButton}
        />
        <ActionCancelButton
          buttonText={'cancel'}
          disabled={!hasEditData}
          clickHandler={handleCancelButton}
          width="3%"
        />
      </ActionBar>
    </Box>
  );
}
