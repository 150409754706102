import axios from 'axios';

export const getMarkets = async () => {
    try {
        const response = await axios.get(`Jobs/Market`);
        return response.data;
    }
    catch (error) {
        console.error("Error fetching markets:", error);
    }
};

export const getSalesPersons = async () => {
    try {
        const response = await axios.get(`common/salespersons`);
        return response.data;
    }
    catch (error) {
        console.error("Error fetching sales persons:", error);
    }
};
