import { env } from '../../env';
import { useNavigate, useLocation, matchPath } from 'react-router-dom';
import { Box, List, ListItem, ListItemText, ListItemButton } from '@mui/material';
import { useRecentJobs } from '../../helper/recentJobsHelper';
import { AREA_NAMES } from '../../common/constants';
 
export default function MyJobsList({ items }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  let isPTPage = !matchPath(``, decodeURIComponent(pathname));
 
  const { saveRecentJobs } = useRecentJobs(isPTPage ? AREA_NAMES.pt : AREA_NAMES.rebar);
 
  function onRecentItemClick(item) {
    saveRecentJobs({...item, name: item.jobName});
 
    if (isPTPage) {
      navigate(`/PT/Jobs/${item.projectId}/MainInfo`);
    } else window.location.href = env.REACT_APP_CLASSIC_HOME + `project/${item.projectId}/jobinfo`;
  }
 
  if (items.length === 0) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          fontSize: '1rem',
          height: 'calc(100% - 90px)',
          justifyContent: 'center',
        }}
      >
        No jobs found.
      </Box>
    );
  }
 
  return (
    <List disablePadding sx={{ height: 'auto', maxHeight: '506px', overflowY: 'auto', paddingBottom: '42px' }}>
      {items.map((item, i) => (
        <ListItem disableGutters disablePadding divider key={item.projectId}>
          <ListItemButton
            onClick={() => onRecentItemClick(item)}
            sx={{
              backgroundColor: i % 2 === 0 && '#f3f3f3',
              padding: '.3rem 1.5rem',
              '&:hover': {
                backgroundColor: '#dbe7fd',
              },
              '&& .MuiTouchRipple-child': {
                backgroundColor: '#3862ae',
              },
            }}
          >
            <ListItemText
              primary={`${item.projectId} - ${item.jobName}`}
              secondary={`${item.customerName && '(' + item.customerName + ')'}`}
              sx={{ color: `${item.isPastDue && 'red'}` }}
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}