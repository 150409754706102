import { env } from '../../env';

// Msal Configurations
export const msalConfig = {
  auth: {
    clientId: env.REACT_APP_AZURE_CLIENT_ID,
    authority: env.REACT_APP_AZURE_AUTHORITY + env.REACT_APP_AZURE_TENANT_ID,
    redirectUri: env.REACT_APP_REDIRECT_URL
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true
  },
  system: {	
        loggerOptions: {	
            loggerCallback: (level, message, containsPii) => {	
                if (containsPii) {		
                    return;		
                }		
            }	
        }	
    }
};
 
// Authentication Parameters
export const loginRequest = {
  scopes: [env.REACT_APP_API_SCOPE]
}
