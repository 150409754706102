import { Box, Skeleton } from '@mui/material';

export default function UserFormSkeleton() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                maxHeight: '500px',
                gap: '20px',
            }}
        >
            <Skeleton animation="wave" variant="rectangular" width={'100%'} height={200} />
            <Skeleton animation="wave" variant="rectangular" width={'100%'} height={50} />
        </Box>
    );
}
