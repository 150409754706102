import { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import saveAs from 'file-saver';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useParams } from 'react-router-dom';
import { useJob } from '../../../utils/masterData';
import { Column, DataGrid, Editing, Scrolling, Button as ActionButtons } from 'devextreme-react/data-grid';
import { Grid, Button} from '@mui/material';
import ConfirmDialog from '../../../components/shared/confirmDialog';

const CISUploadDocuments = () => {
  const hiddenFileInput = useRef(null);
  const cisDocumentsGrid = useRef();
  const [openPopup, setOpenPopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [documentsData, setDocumentsData] = useState(null);
  const { jobId } = useParams();
  const { data: documentsJobData } = useJob({ jobId: jobId });

  const [selectedDocument, setSelectedDocument] = useState();
  const [uploadedDocument, setUploadedDocument] = useState();

  const fetchCisDocumentsGridData = useCallback(async () => {
    const promise = await axios.get(`jobs/${jobId}/cis-documents`);
    setDocumentsData(promise?.data.value);
  }, [jobId]);

  useEffect(() => {
    (async () => {
      try {
        // Make calls to API concurrently
        await fetchCisDocumentsGridData();
      } catch (error) {
        console.error(error);
      }
    })();
  }, [jobId]);

  const handleDownload = async (e) => {
    const { documentLinkId, fileName } = e.row.data;
    try {
      const response = await axios.get(`jobs/${jobId}/cis-documents/${documentLinkId}`, {
        responseType: 'blob',
      });
      saveAs(response.data, fileName);
    } catch (error) {
      console.error(error);
    }
  };

  async function handleFileOverwrite() {
    let file = uploadedDocument;
    const formData = new FormData();
    formData.append('file', file);
    try {
      await axios.put(`Jobs/${jobId}/cis-documents`, formData, {
        headers: { 'Content-Type': 'mulpart/form-data' },
      });
      fetchCisDocumentsGridData();
    } catch (error) {
      console.error(error);
    } finally {
      setOpenPopup(false);
    }
  }
  const handleDelete = async (event) => {
    try {
      let data = event.row.data;
      setSelectedDocument(data);
      setOpenDeletePopup(true);
    } catch (error) {
      console.error(error);
    }
  };
  const handleFileUpload = async (event) => {
    const selectedFile = event.target.files[0];
    setUploadedDocument(selectedFile);
    if (selectedFile) {
      let filename = selectedFile.name;
      let id = documentsData.find((x) => x.fileName === filename)?.documentLinkId;
      if (id === undefined || id <= 0) {
        const formData = new FormData();
        formData.append('file', selectedFile);
        try {
          await axios.put(`Jobs/${jobId}/cis-documents`, formData, {
            headers: { 'Content-Type': 'mulpart/form-data' },
          });
          fetchCisDocumentsGridData();
        } catch (error) {
          console.error(error);
        }
      } else {
        setOpenPopup(true);
      }

      hiddenFileInput.current.value = '';
    }
  };

  const handleDocumentsUploadClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleDeleteConfirm = async (event) => {
    try {
      let data = selectedDocument;
      await axios.delete(`Jobs/${jobId}/cis-documents/${data.documentLinkId}`);
      fetchCisDocumentsGridData();
      setOpenDeletePopup(false);
    } catch (error) {}
  };
  return (
    <>
      <Helmet>
        <title>CISUploadDocuments</title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            className="btn btn-icon"
            onClick={handleDocumentsUploadClick}
            style={{ float: 'right' }}
            startIcon={<CloudUploadIcon />}
            disabled={documentsJobData?.value?.jobOnHold}
          >
            Upload
          </Button>
          <input type="file" onChange={handleFileUpload} ref={hiddenFileInput} style={{ display: 'none' }} />
        </Grid>
        <Grid item xs={12} mb={8}>
          {documentsData && (
            <DataGrid ref={cisDocumentsGrid} dataSource={documentsData} id="id" height={440}>
              <Editing mode="row" useIcons={true} allowEditing={true} allowUpdating={true} allowDeleting={true} />
              <Scrolling mode="infinite" />
              <Column dataField="fileName" caption="File Name" allowFiltering={false}></Column>
              <Column dataField="fileType" caption="File Type" allowFiltering={false}></Column>
              <Column
                dataField="modifiedDate"
                dataType="date"
                format={'MM/dd/yyyy'}
                caption="Date Uploaded"
                allowFiltering={false}
              ></Column>
              <Column type="buttons">
                <ActionButtons
                  name="delete"
                  hint="Delete"
                  visible={true}
                  onClick={handleDelete}
                  disabled={documentsJobData?.value?.jobOnHold}
                />
                <ActionButtons
                  name="Download"
                  hint="Download"
                  icon="download"
                  visible={true}
                  disabled={documentsJobData?.value?.jobOnHold}
                  onClick={async (e) => {
                    await handleDownload(e);
                  }}
                />
              </Column>
            </DataGrid>
          )}
        </Grid>
        <ConfirmDialog
          open={openPopup}
          onClose={() => setOpenPopup(false)}
          onConfirm={handleFileOverwrite}
          title="Are you sure you want to overwrite the existing file?"
        />
        <ConfirmDialog
          open={openDeletePopup}
          onClose={() => setOpenDeletePopup(false)}
          onConfirm={handleDeleteConfirm}
          title="Are you sure you want to delete the file?"
        />
      </Grid>
    </>
  );
};
export default CISUploadDocuments;
