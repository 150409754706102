import { Helmet } from 'react-helmet-async';
import PartClassForm from '../../../components/PT/Admin/partClassForm';

function PartClass() {
    return (
        <>
            <Helmet>
                <title>Part Class</title>
            </Helmet>
            <PartClassForm />
        </>
    );
}

export default PartClass;