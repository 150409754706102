import { useEffect, useState } from 'react';
import MyJobsList from './myJobsList';
import { Box, Card, CardActions, CircularProgress, FormControlLabel, Switch } from '@mui/material';
import { useMyJobs } from '../../utils/masterData';
import { useLocation } from 'react-router-dom';
import ExportCSV from '../../utils/exportCSV';

export default function MyJobs() {
  const location = useLocation();
  const { data: jobs, isLoading } = useMyJobs(location.pathname === '/' ? { hasRebar: true } : { hasPT: true });
  const [filtered, setFiltered] = useState([]);
  const [state, setState] = useState({
    excludeMiscJobs: true,
    excludeShipComplete: true,
  });
  const [csvList, setCsvList] = useState([]);

  useEffect(() => {
    const arr = [['Project Id', 'Job Name', 'Customer Name']];

    if (jobs?.length) {
      const filteredJobs = jobs.filter((job) => {
        // Exclude job if excludeMiscJobs is true or exclude shippingCompleted jobs if excludeCompletedJobs is true
        return !((state.excludeMiscJobs && job.isMiscJob) || (state.excludeShipComplete && job.shippingCompleted));
      });
      setFiltered(filteredJobs);

      filteredJobs.forEach((job) => {
        arr.push([job.projectId, job.jobName, job.customerName]);

      });
      setCsvList(arr);

    }
  }, [jobs, state]);

  const handleSwitchChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Card className="card">
      <Box className="card-hdr hdr-sm">
        <span>My Jobs</span>
        {csvList.length ? <ExportCSV data={csvList} />:''}
      </Box>
      {isLoading ? (
        <Box
          sx={{
            height: 'calc(100% - 90px)',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <MyJobsList items={filtered} />
      )}
      <CardActions
        sx={{
          backgroundColor: '#fff',
          borderTop: '1px solid #f0f0f0',
          bottom: '0',
          left: '0',
          display: 'flex',
          position: 'absolute',
          width: '100%',
          zIndex: '5',
        }}
      >
        <Box sx={{ padding: '0 1rem' }}>
          <FormControlLabel
            className="switch-label"
            disabled={isLoading}
            control={<Switch size="small" name="excludeMiscJobs" checked={state.excludeMiscJobs} />}
            label="Exclude Miscellaneous Jobs"
            onChange={handleSwitchChange}
          />
          <FormControlLabel
            className="switch-label"
            disabled={isLoading}
            control={<Switch size="small" name="excludeShipComplete" checked={state.excludeShipComplete} />}
            label="Exclude Shipping Complete Jobs"
            onChange={handleSwitchChange}
          />
        </Box>
      </CardActions>
    </Card>
  );
}
