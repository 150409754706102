import { Helmet } from 'react-helmet-async';
import KitConfigForm from '../../../components/PT/Admin/kitConfigForm';

export default function KitConfig() {
    return (
        <>
            <Helmet>
                <title>Kit Config</title>
            </Helmet>
            <KitConfigForm />
        </>
    );
}
