import { Grid, MenuItem } from '@mui/material';
import Select from '../../../shared/forms/select';
import { useCableDiameter, useCableType } from '../../../../utils/masterData';
import Input from '../../../shared/forms/input';
import Checkbox from '../../../shared/forms/checkbox';

export default function BarrierCable({ control, pagePermissions }) {
  const { data: cableDiameter } = useCableDiameter();
  const { data: cableType } = useCableType();

  return (
    <Grid container columnSpacing={{ xs: 2 }} rowSpacing={{ xs: 3, md: 4 }}>
      <Grid item xs={6}>
        <Select 
          disabled={!pagePermissions.canEdit} 
          control={control} 
          label="Diameter" 
          name="barrierCableDiameter">
            <MenuItem value={0} sx={{ height: '36px' }}>
              <em></em>
            </MenuItem>
          {cableDiameter?.map((cd) => {
            return (
              <MenuItem key={cd.id} value={cd.id}>
                {cd.description}
              </MenuItem>
            );
          })}
        </Select>
      </Grid>
      <Grid item xs={6}>
        <Select disabled={!pagePermissions.canEdit} control={control} label="Cable Type" name="cableType">
            <MenuItem value={0} sx={{ height: '36px' }}>
              <em></em>
            </MenuItem>
          {cableType?.map((ct) => {
            return (
              <MenuItem key={ct.id} value={ct.id}>
                {ct.description}
              </MenuItem>
            );
          })}
        </Select>
      </Grid>
      <Grid item xs={12}>
        <Input
          isDisabled={!pagePermissions.canEdit}
          control={control}
          maxLength={20}
          label="Sheathing Color/Thickness"
          name="sheathingThickness"
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          isDisabled={!pagePermissions.canEdit}
          control={control}
          maxLength={20}
          label="Anchor Types"
          name="anchorTypes"
        />
      </Grid>
      <Grid item xs={12} display={'flex'} flexWrap={'wrap'} justifyContent={'space-between'}>
        <Checkbox isDisabled={!pagePermissions.canEdit} control={control} label="Steel Posts" name="steelPosts" />
        <Checkbox isDisabled={!pagePermissions.canEdit} control={control} label="Welding" name="welding" />
        <Checkbox isDisabled={!pagePermissions.canEdit} control={control} label="Cable Labor" name="cableLabor" />
      </Grid>
    </Grid>
  );
}
