import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useSnackBar } from '../../context/snackBarContext';
import axios from 'axios';
import { FormControl, Grid, TextField, debounce, createFilterOptions } from '@mui/material';
import { LocationDropDown2 } from '../shared/locationDropDown';
import ActionBar from '../shared/actionBar';
import { ActionSubmitButton } from '../shared/actionSubmitButton';
import * as ErrorMessages from '../shared/errorMessages';

const MiscJob = ({ pagePermissions }) => {
  const { setSnackbar } = useSnackBar();
  const navigate = useNavigate();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [uid, setUid] = useState('');
  const [pendingJobs, setPendingJobs] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [open] = useState(false);
  const [setLoading] = useState(open && dataList.length);
  const { pathname } = useLocation();

  let isPTPage = false;
  if (pathname.toUpperCase().indexOf('/PT') >= 0) isPTPage = true;
  const filterOptions = createFilterOptions({
    stringify: ({ projectId, name }) => `${projectId} ${name}`,
  });

  // check if required.
  const handleLocationChange = (event) => {
    setUid('');
  };

  useEffect(() => {
    if (!open) {
      setDataList([]);
    }
  }, [open]);

  async function handleCreatJob(formData) {
    let job = {};
    job.LocationId = formData.location;
    job.Name = formData.jobName;
    job.IsAdditionalJobForPT = true;
    job.UID = uid;
    job.PTLocationId = formData.location;
    job.IsMiscJob = true;
    job.customerId = formData.customerId;

    if (job.UID) {
      job.FirstDeliveryDate = pendingJobs.find((job) => job?.uid === uid).firstDeliveryDate;
      job.LastDeliveryDate = pendingJobs.find((job) => job?.uid === uid).lastDeliveryDate;
    }

    try {
      const response = await axios.post('createptjob', job);
      setPendingJobs(response?.data ?? []);

      let jobId = response.data?.value;
      if (!response.data.message) {
        setSnackbar({
          open: true,
          message: `Job# ${jobId} created successfully.`,
          severity: 'success',
        });
      }
      navigate(`/PT/Jobs/${jobId}/MainInfo`);
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Error creating job`,
        severity: 'error',
      });
    }
  }

  const loadData = async (inputValue, setOptions) => {
    if (inputValue === undefined) {
      setDataList([]);
      return;
    }
    setLoading(true);
    let domain = isPTPage ? 'PT' : 'Rebar';
    if (inputValue) {
      axios
        .get(`Common/searchjobs?inputSearch=${inputValue}&domain=${domain}`, { loaderRequired: false })
        .then((response) => {
          if (response.data) {
            console.log(response.data);
            const filteredOptions = response.data.filter(
              (f) => f.projectId.includes(inputValue) || f.name.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
            );
            setDataList(filteredOptions);
            console.log(filterOptions);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log('Error occured while retrieving Job#.', error);
          setLoading(false);
        });
    }
  };

  debounce(loadData, 1000);

  return (
    <Grid container direction="column" alignItems="center" sx={{ marginTop: '15px' }}>
      <form onSubmit={handleSubmit(handleCreatJob)}>
        <Grid item sx={{ marginTop: '20px' }}>
          <LocationDropDown2
            changeHandler={handleLocationChange}
            control={control}
            disabled={!pagePermissions.canCreateJob}
            errors={errors}
            name="location"
            required={true}
            sx={{ minWidth: 400 }}
          />
        </Grid>
        <Grid item sx={{ marginTop: '20px' }}>
          <Controller
            name="customerId"
            control={control}
            render={({ field, error }) => (
              <TextField {...field} label="Customer Id" variant="outlined" sx={{ minWidth: 400 }} />
            )}
          />
        </Grid>
        <Grid item sx={{ marginTop: '20px' }}>
          <FormControl sx={{ minWidth: 400 }} size="medium">
            <TextField
              id="JobName"
              variant="outlined"
              placeholder="Job Name"
              name="jobName"
              disabled={!pagePermissions.canCreateJob}
              fullWidth
              {...register('jobName', {
                required: ErrorMessages.Error_RequiredField,
              })}
              error={Boolean(errors.jobName)}
              helperText={errors.jobName?.message}
            />
          </FormControl>
        </Grid>
        <ActionBar display={'flex'} justifyContent={'end'}>
          <ActionSubmitButton disabled={!pagePermissions.canCreateJob} buttonText={'Create'} />
        </ActionBar>
      </form>
    </Grid>
  );
};

export default MiscJob;
