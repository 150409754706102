import { useMsal } from '@azure/msal-react';
import Button from '@mui/material/Button';
import moment from 'moment/moment';

export default function UserInfo({ user }) {
  const { instance } = useMsal();

  function signOff() {
    instance.logoutRedirect({
      postLogoutRedirectUri: '/',
    });
  }

  return (
    <>
      <div className="user-account">
        <div className="user-account-intro">
          <div className="user-account-name">
            <span>Welcome back, {user}</span><span className="spacer"></span>
            <Button variant="text" disableRipple onClick={signOff}>
              Logout
            </Button>
          </div>
          <div className="user-account-date">
            {moment().format("dddd, MMM DD, YYYY")}
          </div>
        </div>
      </div>
    </>
  );
}
