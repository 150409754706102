import { Box, CircularProgress } from '@mui/material';

export default function PageLoader() {
  return (
    <Box
      sx={{
        alignItems: 'center',
        bottom: '0',
        display: 'flex',
        height: 'calc(100% - 134px)',
        left: '50%',
        position: 'absolute',
        transform: 'translateX(-50%)',
      }}
    >
      <CircularProgress />
    </Box>
  );
}
