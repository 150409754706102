import { useEffect, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { useSnackBar } from '../../../context/snackBarContext';
import { Box } from '@mui/material';
import { matchPath, useLocation, useParams } from 'react-router-dom';
import { ROUTE_JOBS_STUD_RAIL_QUANTITIES, ROUTE_STUD_RAIL_CUT_IMPORT } from '../../../routes';

const baseStyle = {
  alignItems: 'center',
  backgroundColor: '#f5f5f5',
  borderColor: '#d3d3d3',
  borderRadius: 2,
  borderStyle: 'dashed',
  borderWidth: 2,
  color: '#8f8f8f',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'center',
  margin: '.75rem 0 0',
  outline: 'none',
  padding: '20px',
  transition: 'border .24s ease-in-out',
  height: '300px',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

export default function CutDropZone({ loadId, setCutsData, setIsFormDirty }) {
  const { setSnackbar } = useSnackBar();
  const { jobId } = useParams();
  const { pathname } = useLocation();

  const { acceptedFiles, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: { 'text/csv': ['.csv'] },
  });

  //* handle file upload
  useEffect(() => {
    async function importCut(type) {
      const formData = new FormData();
      acceptedFiles.forEach((file) => formData.append('file', file, file.name));
      try {
        const res = await axios.post(`jobs/${jobId}/loads/${loadId}/cuts/importcuts?type=${type}`, formData);
        //* check if errors
        if (res.data?.errors?.length) {
          setSnackbar({
            open: true,
            message: `Error in uploading the file.`,
            severity: 'error',
          });
        }
        //* set cuts data and default state to handle the reset form
        if (res.data?.value?.length) {
          setCutsData([...res.data.value]);
          setIsFormDirty(true);
        }
      } catch (error) {
        setSnackbar({
          open: true,
          message: `Error in uploading the file`,
          severity: 'error',
        });
      }
    }
    (async () => {
      if (acceptedFiles.length) {
        const isStudRailImport = !!matchPath(
          `/pt/jobs/${ROUTE_JOBS_STUD_RAIL_QUANTITIES}/${ROUTE_STUD_RAIL_CUT_IMPORT}`,
          decodeURIComponent(pathname)
        );
        const type = isStudRailImport ? 2 : 1; // query param for import api.
        await importCut(type);
      }
    })();
  }, [acceptedFiles, jobId, loadId, setCutsData, setIsFormDirty, setSnackbar]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <Box>
      <Box {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop file here, or click to select file</p>
      </Box>
    </Box>
  );
}
