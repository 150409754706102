import { Controller } from 'react-hook-form';
import { FormControl, InputLabel, Select as MuiSelect } from '@mui/material';

export default function Select({ children, control, isRequired, label, name, disabled }) {
  return (
    <FormControl disabled={disabled} size="medium" fullWidth>
      <InputLabel id={name}>{label}</InputLabel>
      <Controller
        control={control}
        defaultValue=""
        name={name}
        render={({ field }) => (
          <MuiSelect {...field} label={label} labelId={name} required={isRequired}>
            {children}
          </MuiSelect>
        )}
      />
    </FormControl>
  );
}
