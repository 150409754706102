import React from 'react';
import { Grid } from '@mui/material';
import ImportRebarJob from '../../../components/Home/importRebarJob';
import { Helmet } from 'react-helmet-async';

export default function ImportFabJob() {
  return (
    <>
      <Helmet>
        <title>Fab - New Job</title>
      </Helmet>
      <Grid container spacing={0} direction="column" justifyContent={'space-between'} alignItems="center">
        <ImportRebarJob />
      </Grid>
    </>
  );
}
