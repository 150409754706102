import { Helmet } from 'react-helmet-async';
import FabConfigForm from '../../../components/PT/Admin/fabConfigForm';

function FabConfig() {
    return (
        <>
            <Helmet>
                <title>Fab Config</title>
            </Helmet>
            <FabConfigForm />
        </>
    );
}

export default FabConfig;
