import React from 'react';

const TabPanel = React.forwardRef(({ children, value, index, ...props }, ref) => {
    return (
        <div
            ref={ref}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...props}
        >
            {value === index ? <div> {children} </div> : null}
        </div>
    );
});

export default TabPanel;
