import { Helmet } from 'react-helmet-async';
import ShopPaperWorkForm from '../../../components/PT/Schedules/shopPaperWorkForm';
import { useUserContext } from '../../../context/userContext';
import { useParams } from 'react-router-dom';
import PageLoader from '../../../components/shared/pageLoader';
import { useJob } from '../../../utils/masterData';

export default function ShopPaperWork() {
  const { user } = useUserContext();
  const { jobId } = useParams();

  const { data: jobData, isLoading, error } = useJob({ jobId });

  if (isLoading) return <PageLoader />;

  if (error) return <div>Error loading project info</div>;

  const pagePermissions = {
    canPrintTag: user.isPTScheduler && !jobData.value.jobOnHold,
  };

  return (
    <>
      <Helmet>
        <title>Shop Paperwork</title>
      </Helmet>
      <ShopPaperWorkForm pagePermissions={pagePermissions} />
    </>
  );
}
