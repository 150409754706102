import { Grid } from '@mui/material';
import AutoComplete from '../../../shared/forms/autoComplete';
import Input from '../../../shared/forms/input';

export default function Team({ control, salesPersons, estimatorPersons, pagePermissions }) {
  return (
    <Grid container columnSpacing={{ xs: 2, md: 4 }} rowSpacing={{ xs: 3, md: 4 }}>
      <Grid item xs={12} sm={6}>
        <AutoComplete
          isDisabled={!pagePermissions.canEdit}
          control={control}
          data={estimatorPersons}
          label="PT Estimator"
          name="estimator"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AutoComplete
          isDisabled={!pagePermissions.canEdit}
          control={control}
          data={salesPersons}
          label="PT Sales Person"
          name="salesPerson"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          isDisabled={true}
          control={control}
          label="PT Project Manager"
          name="projectManager"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          isDisabled={true}
          control={control}
          label="PT Project Engineer"
          name="projectEngineer" 
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input control={control} label="Detailer" name="detailer" isDisabled={true} />
      </Grid>
    </Grid>
  );
}
