import { Grid } from '@mui/material';
import Checkbox from '../../../shared/forms/checkbox';

export default function MoreOptions({ control, pagePermissions, jobInfo }) {
  return (
    <Grid container columnSpacing={{ lg: 3, xl: 1 }} rowSpacing={{ xs: 1, lg: 2 }}>
      <Grid item xs={12} sm={4} lg={6}>
      <Checkbox control={control} isDisabled={!pagePermissions.canEdit || (jobInfo?.regionID!=='WST')} label="Tax Included" name="taxIncluded" />
      </Grid>
      <Grid item xs={12} sm={4} lg={6}>
        <Checkbox
          isDisabled={!pagePermissions.canEdit}
          control={control}
          label="Loss Calc Req"
          name="lossCalcRequired"
        />
      </Grid>
      <Grid item xs={12} sm={4} lg={6}>
        <Checkbox label="BUY AMERICA" name="buyAmerica" isDisabled={!pagePermissions.canEdit} control={control} />
      </Grid>
      <Grid item xs={12} sm={4} lg={6}>
        <Checkbox isDisabled={!pagePermissions.canEdit} control={control} label="Encapsulated" name="encapsulated" />
      </Grid>
    </Grid>
  );
}
