import CancelIcon from '@mui/icons-material/Cancel';
import { Button } from '@mui/material';

const ActionCancelButton = ({ buttonText, clickHandler, disabled }) => {
    return (
        <Button
            className="btn btn-icon btn-cancel"
            startIcon={<CancelIcon />}
            disabled={disabled}
            onClick={clickHandler}
        >
            {buttonText}
        </Button>
    );
};

export default ActionCancelButton;
