import { Grid, MenuItem, TextField } from '@mui/material';
import Input from '../../../shared/forms/input';
import InputNum from '../../../shared/forms/inputNum';
import Select from '../../../shared/forms/select';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useJobTypes, usePTAreaCodes } from '../../../../utils/masterData';

export default function GeneralInfo({ control, markets, getValues, pagePermissions }) {
  const { data: accessories } = useQuery({
    queryKey: 'common/accessories',
    queryFn: async () => (await axios.get('common/accessories')).data,
    refetchIntervalInBackground: false,
  });

  const { data: engStampRequirement } = useQuery({
    queryKey: 'common/engstamprequirements',
    queryFn: async () => (await axios.get('common/engstamprequirements')).data,
    refetchIntervalInBackground: false,
  });

  const { data: millThickness } = useQuery({
    queryKey: ['pt/millThickness'],
    queryFn: async () => (await axios.get('pt/millthickness')).data,
    refetchIntervalInBackground: false,
  });

  const { data: cableDiameter } = useQuery({
    queryKey: ['pt/cableDiameter'],
    queryFn: async () => (await axios.get('pt/cableDiameter')).data,
    refetchIntervalInBackground: false,
  });

  const { data: areaCodes } = usePTAreaCodes();

  const { data: jobTypes } = useJobTypes();

  return (
    <Grid container columnSpacing={{ xs: 2, xl: 5 }} rowSpacing={{ xs: 3, md: 4 }}>
      <Grid item xs={8} sm={6} md={3}>
        <Select
          disabled={!pagePermissions.canEdit}
          control={control}
          id={'job-type'}
          label="Job Type"
          name="jobType.id"
        >
          {jobTypes?.map((jt) => {
            return (
              <MenuItem key={jt.id} value={jt.id}>
                {jt.name}
              </MenuItem>
            );
          })}
        </Select>
      </Grid>
      <Grid item xs={8} sm={6} md={3}>
        <TextField
          id="SAP Status"
          label="SAP Status"
          variant="outlined"
          name="SAPStatus"
          fullWidth
          defaultValue={getValues('sapStatus')}
          disabled
        />
      </Grid>
      <Grid item xs={8} sm={6} md={3}>
        <Select disabled={!pagePermissions.canEdit} control={control} label="Mill Thickness" name="millThickness">
          <MenuItem value={0} sx={{ height: '36px' }}>
            <em></em>
          </MenuItem>
          {millThickness?.map((cd) => {
            return (
              <MenuItem key={cd.id} value={cd.id}>
                {cd.description}
              </MenuItem>
            );
          })}
        </Select>
      </Grid>
      <Grid item xs={8} sm={6} md={3}>
        <Select disabled={!pagePermissions.canEdit} control={control} label="Cable Diameter" name="cableDiameter">
          <MenuItem value={0} sx={{ height: '36px' }}>
            <em></em>
          </MenuItem>
          {cableDiameter?.map((cd) => {
            return (
              <MenuItem key={cd.id} value={cd.id}>
                {cd.description}
              </MenuItem>
            );
          })}
        </Select>
      </Grid>
      <Grid item xs={8} sm={6} md={3}>
        <InputNum
          isDisabled={!pagePermissions.canEdit}
          control={control}
          label="Equip Calibration Exp Day"
          name="equipmentCalibration"
        />
      </Grid>
      <Grid item xs={8} sm={6} md={3}>
        <Select disabled={!pagePermissions.canEdit} control={control} label="Market" name="marketId">
          <MenuItem value={0} sx={{ height: '36px' }}>
            <em></em>
          </MenuItem>
          {markets?.map((mar) => {
            return (
              <MenuItem key={mar.id} value={mar.id}>
                {mar.description}
              </MenuItem>
            );
          })}
        </Select>
      </Grid>
      <Grid item xs={8} sm={6} md={3}>
        <Select disabled={!pagePermissions.canEdit} control={control} label="Eng Stamp Req" name="engStamp">
          {engStampRequirement?.map((esr) => {
            return (
              <MenuItem key={esr.id} value={esr.id}>
                {esr.description}
              </MenuItem>
            );
          })}
        </Select>
      </Grid>
      <Grid item xs={8} sm={6} md={3}>
        <Select
          disabled={!pagePermissions.canEdit}
          labelId="accessorieskit"
          label="Accessories Kit"
          name="accessoryKit"
          control={control}
          defaultValue={getValues('accessoryKit')}
        >
          <MenuItem value={0} sx={{ height: '36px' }}>
            <em></em>
          </MenuItem>
          {accessories?.map((ace) => {
            return (
              <MenuItem key={ace.id} value={ace.id}>
                {ace.description}
              </MenuItem>
            );
          })}
        </Select>
      </Grid>
      <Grid item xs={8} sm={6} md={3}>
        <Select disabled={!pagePermissions.canEdit} control={control} label="PT Area Code" name="areaCode">
          <MenuItem value={null} sx={{ height: '36px' }}>
            <em></em>
          </MenuItem>
          {areaCodes?.map((a) => {
            return (
              <MenuItem key={a.id} value={a.id}>
                {a.code}
              </MenuItem>
            );
          })}
        </Select>
      </Grid>
      <Grid item xs={8} sm={6} md={3}>
        <Input control={control} isDisabled={true} label="Rebar Job #" name="refProjectId" />
      </Grid>
    </Grid>
  );
}
