import { Outlet } from 'react-router-dom';
import SchedulesProvider from '../../../context/schedulesContext';

export default function ScheduleLayout() {
  return (
    <SchedulesProvider>
      <Outlet />
    </SchedulesProvider>
  );
}
