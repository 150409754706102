import { createContext, useContext, useMemo, useState } from 'react';

const AppContext = createContext();

export const useAppState = () => {
    return useContext(AppContext);
};

export const AppStateProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);

    const value = useMemo(() => ({ loading, setLoading }), [loading, setLoading]);

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
