import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Container, Menu, MenuItem } from '@mui/material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { ROUTE_PT_ADMIN } from '../../routes';

export default function AdminUsersNav() {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    return (
        <Container maxWidth="false" sx={{ padding: '6px 8px' }} disableGutters className="admin-user">
            <Box>
                <Button
                    variant="outlined"
                    sx={{
                        color: 'rgba(0, 0, 0, 0.87)',
                        border: 'none',
                        '&:hover': {
                            backgroundColor: 'transparent',
                            border: 'none',
                        },
                    }}
                    disableRipple
                    onClick={handleClick}
                    startIcon={<AdminPanelSettingsIcon />}
                >
                    Admin
                </Button>
                <Menu
                    className="sub-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        sx: {
                            backgroundColor: '#f1f2f3',
                            display: 'flex',
                            minHeight: '42px',
                            padding: '0',
                        },
                    }}
                >
                    <MenuItem>
                        <Link to={ROUTE_PT_ADMIN}>Users</Link>
                    </MenuItem>
                    <MenuItem>
                        <Link to="/PT/Admin/Part Class">Part Class</Link>
                    </MenuItem>
                    <MenuItem>
                        <Link to="/PT/Admin/parts">Parts</Link>
                    </MenuItem>
                    <MenuItem>
                        <Link to="/PT/Admin/FabConfig">FAB Config</Link>
                    </MenuItem>
                    <MenuItem>
                        <Link to="/PT/Admin/KitConfig">Kit Config</Link>
                    </MenuItem>
                    <MenuItem>
                        <Link to="/PT/Admin/Pricing">Pricing</Link>
                    </MenuItem>
                </Menu>
            </Box>
        </Container>
    );
}
