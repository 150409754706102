import { useNavigate, Outlet } from 'react-router-dom';
import Header from '../../components/Layout/header';
import AdminUsersNav from '../../components/Nav/adminUsersNav';
import BreadCrumbsCustom from '../../components/shared/breadCrumb';
import { useUserContext } from '../../context/userContext';
import PageLoader from '../../components/shared/pageLoader';
import { DisplayError } from '../../components/PT/displayError';

export default function PTLayout() {
    const { user, loading: userLoading } = useUserContext();

    const navigate = useNavigate();

    if (userLoading) return <PageLoader />;

    if (!user.isAdmin) navigate(`/unauthorized`);

    return (
        <>
            <Header />
            <AdminUsersNav />
            <main className="pt-admin-users">
                <DisplayError />
                <BreadCrumbsCustom />
                <Outlet />
            </main>

        </>
    );
}
