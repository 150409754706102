import { AxiosError } from 'axios';
import { useRouteError } from 'react-router-dom';
import GenericErrorPage from '../../pages/genericErrorPage';
import Unauthorized from '../../pages/unauthorized';

export const axiosErrorCodes = Object.freeze({
  ERR_NETWORK: 'ERR_NETWORK',
  ERR_BAD_REQUEST: 'ERR_BAD_REQUEST',
  ERR_BAD_RESPONSE: 'ERR_BAD_RESPONSE',
  ERR_ABORTED: 'ERR_ABORTED',
  ECONNABORTED: 'ECONNABORTED',
  ERR_FR_TOO_MANY_REDIRECTS: 'ERR_FR_TOO_MANY_REDIRECTS',
  ENETUNREACH: 'ENETUNREACH',
  EADDRINUSE: 'EADDRINUSE',
  ERR_CONNECTION_REFUSED: 'ERR_CONNECTION_REFUSED',
  ERR_NETWORK_MESSAGE: 'Network Error',
});

/// any error on any page. bubble's up to this component.
export default function ErrorBoundary() {
  let error = useRouteError();

  if (error instanceof AxiosError) {
    // Unauthorized user.
    if (error.code === axiosErrorCodes.ERR_BAD_REQUEST && error?.response?.status === 401) {
      return <Unauthorized />;
    }
    // Unauthorized user. | Forbidan
    if (error.code === axiosErrorCodes.ERR_BAD_REQUEST && error?.response?.status === 403) {
      return <Unauthorized />;
    }
    // network error - service down.
    // if(error.code === axiosErrorCodes.ERR_NETWORK)
      return <GenericErrorPage  message={axiosErrorCodes.ERR_NETWORK_MESSAGE}/>;
    
  }
}
