import { useRef, useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useSnackBar } from '../../../context/snackBarContext';
import {
  Column,
  DataGrid,
  Editing,
  HeaderFilter,
  Lookup,
  RequiredRule,
  Scrolling,
  Selection
} from 'devextreme-react/data-grid';
import { Skeleton, Grid, Button } from '@mui/material';
import ActionBar from '../../shared/actionBar';
import { ActionSubmitButton2 } from '../../shared/actionSubmitButton';
import ActionCancelButton from '../../shared/actionCancelButton';
import AddIcon from '@mui/icons-material/Add';

function PartClassForm() {
  const partClassDataGrid = useRef(null);
  const { setSnackbar } = useSnackBar();
  const [hasEditData, setHasEditData] = useState(false);
  const [partclassData, setPartclassData] = useState([]);
  const [loader, setLoader] = useState(true);
  const { data: categoriesLookup } = useQuery({
    queryKey: ['pt/categories'],
    queryFn: async () => {
      const response = await axios.get('pt/categories');
      return response.data;
    },
  });
  const { data: allParts } = useQuery({
    queryKey: 'pt/parts',
    queryFn: async () => (await axios.get('pt/parts')).data,
  });
  const { data: uomList } = useQuery({
    queryKey: ['pt/uom'],
    queryFn: async () => (await axios.get('pt/UOM')).data,
    staleTime: Infinity,
  });

  //* get all data api call
  useEffect(() => {
    (async () => {
      Promise.all([axios.get(`PT/PartClass`, { loaderRequired: false })])
        .then((results) => {
          setPartclassData(results[0].data);
          setLoader(false);
        })
        .catch((error) => {
          setSnackbar({
            open: true,
            message: `Error occured while getting partclass data.`,
            severity: 'error',
          });
          console.log(error);
          setLoader(false);
        });
    })();
  }, [setSnackbar]);

  const getFilteredParts = (options) => ({
    store: allParts,
    filter: options.data ? [['partClassId', '=', options.data.partClassId], 'and', ['isActive', '=', true]] : null,
  });

  //* removes the data grid toolbar header buttons (add, save and reset)
  function handleOnToolbarPreparing(e) {
    e.toolbarOptions.items.splice(0);
  }

  //* handle edit btn state
  function handleOnContentReady(e) {
    setHasEditData(e.component.hasEditData());
  }

  //* add row button handler
  function handleAddRowButton() {
    partClassDataGrid.current.instance.addRow();
  }

  //* save button handler
  function handleSaveButton() {
    partClassDataGrid.current.instance.saveEditData();
    setHasEditData(true);
  }

  //* cancel/reset button handler
  function handleCancelButton() {
    partClassDataGrid.current.instance.cancelEditData();
  }

  //* refresh datagrid data on insert new row
  async function refresh() {
    try {
      const partClassResponse = await axios.get(`PT/PartClass`, { loaderRequired: false });
      setPartclassData(partClassResponse.data);
    } 
    catch (error) {
      console.log(error);
    }
  }

  //* on save data grid handler
  async function handleSaving(e) {
    // default save behavior canceled
    e.cancel = true;

    let payload = {
      partclass: [],
    };

    let haveInsert = false;
    let allRecords = partClassDataGrid.current.instance.getVisibleRows();

    e.changes.forEach((change) => {
      if (change.type === 'insert') {
        payload.partclass.push(change.data);
        haveInsert = true;
      } 
      else {
        // If this is update, get other fields as well
        let row = allRecords.find((r) => r.data?.partClassId === change.key);
        if (row?.data) {
          payload.partclass.push({...row.data});
        }
      }
    });

    //grid default save behavior enabled back
    e.cancel = false;

    axios
      .put(`pt/partclass`, payload, { loaderRequired: false })
      .then(async () => {
        setSnackbar({
          open: true,
          message: `Saved successfully`,
          severity: 'success',
        });
        if (haveInsert) await refresh();
      })
      .catch((error) => {
        console.log(error);
        setSnackbar({
          open: true,
          message: `Error occured while updating partclass.`,
          severity: 'error',
        });
      });
  }

  const handleInitNewRow = (e) => {
    // Set the default values for the new row
    e.data.isActive = true;
  };

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'start',
        }}>
        <Button onClick={handleAddRowButton} className="btn btn-blue btn-icon" startIcon={<AddIcon />}>
          Add Row
        </Button>
      </Grid>
      <Grid item xs={12} marginBottom={9}>
        {loader ? (
          <Skeleton sx={{ width: '100%', height: 300 }} />
        ) : (
          <DataGrid
            ref={partClassDataGrid}
            id="gridContainer"
            dataSource={partclassData}
            height={440}
            keyExpr={'partClassId'}
            showBorders={true}
            rowAlternationEnabled={true}
            onSaving={handleSaving}
            onToolbarPreparing={handleOnToolbarPreparing}
            onContentReady={handleOnContentReady}
            onInitNewRow={handleInitNewRow}>
              <HeaderFilter visible />
              <Selection mode="single" />
              <Editing allowAdding allowUpdating mode="batch" />
              <Scrolling mode="infinite" />
              <Column
                dataField="name"
                caption="Part Class"
                allowSearch
                allowFiltering={false}
                allowEditing={true}>
                  <RequiredRule /> 
              </Column>
              <Column dataField="uom" caption={'UOM'} allowSearch allowFiltering={false} allowEditing={true}>
                <Lookup dataSource={uomList} valueExpr="code" displayExpr="code" />
                <RequiredRule />
              </Column>
              <Column
                dataField="categoryId"
                caption="PT Category"
                allowSearch
                allowFiltering={false}
                allowEditing={true}>
                  <Lookup dataSource={categoriesLookup} valueExpr="id" displayExpr="name" />
                  <RequiredRule />
              </Column>
              <Column
                alignment="left"
                dataField="perUnit"
                allowSearch
                allowFiltering={false}
                caption="Per Unit"
                allowEditing={true}>
                  <RequiredRule />
              </Column>
              <Column
                dataField="defaultPart"
                allowSearch
                allowFiltering={false}
                caption="Default Part"
                allowEditing={true}>
                  <Lookup
                    dataSource={getFilteredParts}
                    valueExpr="partId"
                    displayExpr="partNumber"
                    allowClearing="true"
                  />
              </Column>
              <Column
                caption={'Active'}
                dataType="boolean"
                dataField="isActive"
                allowFiltering
                allowSearch
                trueText="Active"
                falseText="Inactive"
              />
          </DataGrid>
        )}
      </Grid>
      <ActionBar
        sx={{
          justifyContent: 'end',
          display: 'flex',
          gap: '10px',
        }}>
          <ActionSubmitButton2 buttonText={'Save'} onClick={handleSaveButton} disabled={!hasEditData} />
          <ActionCancelButton buttonText={'cancel'} disabled={!hasEditData} clickHandler={handleCancelButton} />
      </ActionBar>
    </Grid>
  );
}

export default PartClassForm;
