import { Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export default function Date({ control, isDisabled, isRequired, label, name, value }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        control={control}
        rules={{ ...(isRequired ? { required: isRequired } : {}) }}
        defaultValue={dayjs()}
        name={name}
        render={({ field, fieldState: { error } }) => {
          return (
            <DatePicker
              disabled={isDisabled}
              inputRef={field.ref}
              label={label}
              onChange={(date) => {
                field.onChange(date);
              }}
              required={isRequired}
              slotProps={{
                textField: { error: !!error, helperText: error?.message, required: isRequired },
              }}
              sx={{ width: '100%' }}
              value={dayjs(value).year() < 2 ? dayjs(null) : dayjs(field.value)}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
}
