import { createContext, useState, useContext, useMemo } from 'react';

const SnackBarContext = createContext({
  snackbar: { open: false, message: '', severity: '', isAction: false },
  setSnackbar: ({ open, message, severity, isAction = false }) => null,
  closeSnackBar: () => null,
});

export const SnackBarProvider = ({ children }) => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: '',
    isAction: false,
  });

  function closeSnackBar(e, reason) {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar({ open: false, message: '', severity: '', isAction: false });
  }

  const value = useMemo(() => ({ snackbar, setSnackbar, closeSnackBar }), [snackbar, setSnackbar, closeSnackBar]);

  return <SnackBarContext.Provider value={value}>{children}</SnackBarContext.Provider>;
};

export const useSnackBar = () => {
  const context = useContext(SnackBarContext);

  if (context === undefined) throw new Error('useSnackBar must be used in SnackBarContext');

  return context;
};
