import { useState, useCallback, useEffect } from 'react';
import moment from 'moment';

const KEY = 'recentJobs';
function getRecentJobs(area) {
  // Retrieve recent jobs from local storage
  const localStorageJobs = localStorage.getItem(KEY);
  const recentJobs = JSON.parse(localStorageJobs) || [];

  // Filter jobs based on the area
  if (area)
    return recentJobs
      .filter((job) => job.area === area)
      .sort((a, b) => moment(b.accessedDate) - moment(a.accessedDate));

  return recentJobs;
}

export function useRecentJobs(area) {
  const [recentJobs, setRecentJobs] = useState([]);

  useEffect(() => {
    setRecentJobs(() => getRecentJobs(area));
  }, [area]);

  const saveRecentJobs = useCallback(
    (job) => {
      job = {
        projectId: job.projectId,
        accessedDate: moment(),
        area,
        name: job.name,
        customerName: job.customerName,
      };

      // Retrieve the existing recent jobs -- all jobs areas.
      const existingJobs = getRecentJobs();
      let jobsByArea = existingJobs.filter((j) => j.area === area && j.projectId !== job.projectId) || [];
      const restOfJobs = existingJobs.filter((job) => job.area !== area);

      jobsByArea = [...jobsByArea, job];

      jobsByArea = jobsByArea.sort((a, b) => moment(b.accessedDate) - moment(a.accessedDate)).slice(0, 20);

      const updatedJobs = [...jobsByArea, ...restOfJobs];
      localStorage.setItem(KEY, JSON.stringify(updatedJobs));
    },
    [localStorage, recentJobs, getRecentJobs]
  );

  return { recentJobs, saveRecentJobs };
}
