import { useCallback, useEffect, useRef, useState } from 'react';
import { useSnackBar } from '../../../context/snackBarContext';
import ActionBar from '../../shared/actionBar';
import { ActionSubmitButton2 } from '../../shared/actionSubmitButton';
import ActionCancelButton from '../../shared/actionCancelButton';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import {
  Column,
  CustomRule,
  Editing,
  DataGrid,
  Scrolling,
  Lookup,
  Selection,
  SearchPanel,
  Format,
  HeaderFilter,
  Sorting,
  StateStoring,
} from 'devextreme-react/data-grid';
import {
  FormControl,
  Grid,
  Typography,
  Box,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  InputAdornment,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import axios from 'axios';
import { useAdditionalItems, useCutStatus } from '../../../utils/masterData';
import { useMutation, useQuery } from '@tanstack/react-query';
import GridSkeleton from '../../shared/gridSkeleton';
import { queryClient } from '../../../App';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import { useNavigate } from 'react-router-dom';
import { convertToGMT } from '../../../utils/dateUtils';
import moment from 'moment/moment';
import { useSchedulesContext } from '../../../context/schedulesContext';
import { Workbook } from 'exceljs';
import { MultiSelect } from '../../shared/multiSelect';
import { PRODUCTION_STATUS } from '../../../common/constants';

export default function ShopScheduleForm({ pagePermissions }) {
  const scheduleDataGridRef = useRef(null);
  const navigate = useNavigate();
  const { setSnackbar } = useSnackBar();
  const [hasEditData, setHasEditData] = useState(false);
  const { shopGridRadioValue, setShopGridRadioValue, selectedLocations, PTLocations, setSelectedLocations } =
    useSchedulesContext();
  // set status params value based on grid radio button.
  const [status, setStatus] = useState(() => (shopGridRadioValue === '2' ? [2] : [3, 4, 5, 6]));
  const [selectedLoad, setSelectedLoad] = useState([]);
  // set productionStatus base on grid radio button
  const [productionStatus, setProductionStatus] = useState(() =>
    shopGridRadioValue === '2' ? [2, 3] : [2, 3, 4, 5, 6]
  );
  const [alertDialogContent, setAlertDialogContent] = useState({ contnet: '' });
  const [dialogOpen, setDialogOpen] = useState(false);
  //Get cut status
  const { data: cutStatusLookup } = useCutStatus();
  // Get Additional Items
  const { data: additionalItemsLookup } = useAdditionalItems();
  // * get all the shop schedule data
  const {
    data: ShopScheduleData,
    isLoading,
    error,
  } = useQuery({
    queryKey: [`pt/Schedules`, { status, locations: selectedLocations.map((l) => l.locationID) }],
    queryFn: async () =>
      (
        await axios.get('pt/Schedules', {
          loaderRequired: false,
          params: { status, locations: selectedLocations.map((l) => l.locationID) },
          paramsSerializer: { indexes: null },
        })
      ).data.loads,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    staleTime: 0,
  });

  useEffect(() => {
    localStorage.setItem('pt-location-filter', JSON.stringify(selectedLocations));
  }, [selectedLocations]);

  const updateShopSchedule = useMutation({
    mutationFn: (loads) => axios.put('pt/schedules/shopschedules', loads),
    onSuccess: () => {
      setSnackbar({ open: true, message: 'Saved shop schedules successfully.', severity: 'success' });
      return queryClient.invalidateQueries({ queryKey: [`pt/Schedules`] });
    },
  });

  //* handle edit btn state
  function handleOnContentReady(e) {
    setHasEditData(e?.component.hasEditData());
  }

  //* save button handler
  function handleSaveButton() {
    scheduleDataGridRef.current.instance.saveEditData();
    setHasEditData(true);
  }

  //* cancel/reset button handler
  function handleCancelButton() {
    scheduleDataGridRef.current.instance.cancelEditData();
  }
  //* Handle shop edit process request
  async function processBatchRequest(loads, component) {
    try {
      updateShopSchedule.mutate(loads);
      component?.cancelEditData();
    } catch (error) {
      setSnackbar({ open: true, message: 'Error saving shop schedules.', severity: 'error' });
    }
  }

  //* on save data grid handler
  async function handleSaving(e) {
    e.cancel = true;
    let payload = { loads: [] };
    if (!e.changes.length) return;

    let allRecords = scheduleDataGridRef.current.instance.getVisibleRows();

    e.changes.forEach((change) => {
      //* If this is update, get other fields as well
      let row = allRecords.find((r) => r.data?.loadId === change.key);
      if (!row.data) return;
      let item = {
        ...row.data,
        deliveryDate: row.data.deliveryDate ? convertToGMT(new Date(row.data.deliveryDate)) : null,
        productionDate: row.data.productionDate ? convertToGMT(new Date(row.data.productionDate)) : null,
        custReqDate: row.data.custReqDate ? convertToGMT(new Date(row.data.custReqDate)) : null,
      };

      // Converting delivery date to formatted text value of delivery time.
      // Note: it is displayDeliveryTime the dataSource, changes avaliable only on that.
      item.deliveryTime = moment(item.displayDeliveryTime).format('hh:mm A');

      payload.loads.push(item);
    });

    e.promise = processBatchRequest(payload, e.component);
  }

  const handleChange = (event) => {
    //Maintain the radio button status
    setShopGridRadioValue(event.target.value); // 3 & 2
    //get the data as per Status of Radio button checked

    // set status for production lookup
    if (event.target.value === '2') {
      //Waiting for Approval
      setProductionStatus([2, 3]);
      setStatus([2]);
      setSelectedLoad({}); //clear selected load and row kyes.
      // setSelectedRowKeys([]);
    } else {
      //Approved by Shop
      setProductionStatus([2, 3, 4, 5, 6]);
      setStatus([3, 4, 5, 6]);
    }
  };

  function handleShopPaperwork() {
    if (!selectedLoad[0].loadId) {
      setSnackbar({
        open: true,
        message: `Please select a load`,
        severity: 'error',
      });
      return;
    }

    navigate(
      `${selectedLoad[0].projectId}/${selectedLoad[0].description}/shop paperwork?loadId=${selectedLoad[0].loadId}&jobName=${selectedLoad[0].jobName}`
    );
  }

  function editorOnValueChanged(args, event) {
    if (event.dataField === 'productionStatusId') {
      if (args.previousValue >= 5 && args.value < args.previousValue) {
        // case when: send to TMW.
        setAlertDialogContent(
          'Make sure to reverse the PGI and delete the delivery in SAP. Tags also need to be reprinted.'
        );
        setDialogOpen(true);
      }
      if (args.previousValue >= 4 && args.previousValue < 5 && args.value < args.previousValue) {
        // case when: send to Tagged.
        setAlertDialogContent('Tags also need to be reprinted.');
        setDialogOpen(true);
      }
    }
    event.setValue(args.value);
  }

  function onEditorPreparing(event) {
    // check if job on hold
    if (event.row.data.jobOnHold) {
      event.editorOptions.readOnly = true;
      return;
    }

    //
    if (event.row.data.productionStatusId >= PRODUCTION_STATUS.sentToTMW) {
      event.editorOptions.readOnly = true;
      return;
    }

    // make the data-source fields disable.
    if (event.dataField === 'productionStatusId') {
      event.editorOptions.dataSource = cutStatusLookup
        ?.filter((r) => productionStatus.includes(r.id))
        .map((status) => {
          let isDisable = event.value + 1 < status.id;

          if (status.id === PRODUCTION_STATUS.taggedInProcess || status.id === PRODUCTION_STATUS.sentToTMW)
            isDisable = true;
          return { ...status, disabled: isDisable };
        });

      event.editorOptions.onValueChanged = (args) => editorOnValueChanged(args, event);
    }
  }

  const deliveryDateValidate = (options) => {
    //* get all data to validate
    return moment(options.data.productionDate).format('MM/DD/YYYY') <= moment(options.value).format('MM/DD/YYYY');
  };

  const productionDateValidate = (options) => {
    return moment(options.data.deliveryDate).format('MM/DD/YYYY') >= moment(options.value).format('MM/DD/YYYY');
  };

  function onRowPrepared(event) {
    if (event.rowType === 'data' && event.data.hasMultiStatusCuts) {
      event.rowElement.style.borderLeft = 'solid 5px red';
    }
  }

  // export to xl
  const handleExportDataGrid = useCallback(() => {
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Shop Schedules');
    exportDataGrid({
      component: scheduleDataGridRef.current.instance,
      worksheet: worksheet,
    })
      .then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'shop-schedules.xlsx');
        });
      })
      .catch(() => {
        setSnackbar({ message: 'Error in saving the xl file.', open: true, severity: 'error' });
      });
  }, [scheduleDataGridRef, exportDataGrid, saveAs]);

  // handles the grid state save - localstorage
  const saveState = useCallback((state) => {
    const updateColumns = state.columns.map((column) => {
      if (column.dataField === 'displayDeliveryTime' || column.dataField === 'deliveryDate') {
        column.sortOrder = 'desc';
        column.sortIndex = 0;
      } else {
        delete column.sortIndex;
        delete column.sortOrder;
      }
      // removes columns filters values.
      if (column?.filterValues?.length) {
        delete column.filterValues;
      }
      return column;
    });
    if (state?.searchText) {
      delete state.searchText;
    }
    if (state?.selectedRowKeys?.length) {
      delete state.selectedRowKeys;
    }

    let updateState = { ...state, columns: updateColumns };
    localStorage.setItem('shop-schedules-grid', JSON.stringify(updateState));
  }, []);

  const loadState = useCallback(() => {
    const state = localStorage.getItem('shop-schedules-grid');
    if (state) return JSON.parse(state);

    return null;
  }, []);

  // clears the default toolbar of Grid.
  const hideDefaultToolBar = (e) => e.toolbarOptions.items.splice(0);

  // shop schedules/dispatch/shipped, total LF
  const renderLFCell = (rowData) => {
    const LF = rowData.type === 2 ? rowData.totalLinealFootage : rowData.actualLF;
    return LF;
  };

  // shop schedules/dispatch/shipped, # of Cuts/Rails
  const renderNumberOfCutsRailsCell = (rowData) => {
    const cell = rowData.type === 2 ? rowData.totalRails : rowData.cutCount;
    return cell;
  };

  if (isLoading) return <GridSkeleton />;

  if (error) return <div> Error getting data </div>;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            margin: '16px 0 3px',
          }}
        >
          <FormControl component="fieldset">
            <RadioGroup
              name="ShopSchedule"
              value={shopGridRadioValue}
              onChange={handleChange}
              sx={{ flexDirection: 'row' }}
            >
              <FormControlLabel value={'2'} control={<Radio />} label="Waiting for Approval" />
              <FormControlLabel value={'3'} control={<Radio />} label="Approved by Shop" />
            </RadioGroup>
          </FormControl>
          <Typography variant="h1">Production Schedule</Typography>
          <Box display="flex" alignItems="center" gap={1} id="search">
            {shopGridRadioValue === '3' ? (
              <Button className="btn btn-blue" disabled={selectedLoad.length !== 1} onClick={handleShopPaperwork}>
                Shop Paperwork
              </Button>
            ) : null}
            <Button className="btn btn-blue" onClick={handleExportDataGrid}>
              Export to Excel
            </Button>
            <MultiSelect
              className="pt-location-filter"
              size="small"
              placeholder={'Filter by locations'}
              options={PTLocations}
              selected={selectedLocations}
              setSelected={setSelectedLocations}
              onChange={(e) => setSelectedLocations(e.target.value)}
              onDelete={(value) => {
                const updateSelected = selectedLocations.filter(
                  (location) => location['locationID'] !== value['locationID']
                );
                setSelectedLocations(updateSelected);
              }}
              value={'locationID'}
              name={'locationName'}
              sx={{ maxWidth: 300, minWidth: 200 }}
            />
            <TextField
              id="search-grid"
              size="small"
              variant="filled"
              placeholder="Search..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className="search-icon">
                    <SearchSharpIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                scheduleDataGridRef.current.instance.searchByText(e.target.value);
              }}
            />
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          paddingTop: '0px !important',
        }}
      >
        <Grid item xs={12}>
          <DataGrid
            ref={scheduleDataGridRef}
            id="gridContainer"
            dataSource={ShopScheduleData}
            height={440}
            keyExpr={'loadId'}
            showBorders={true}
            rowAlternationEnabled={true}
            onSaving={handleSaving}
            onToolbarPreparing={hideDefaultToolBar}
            onContentReady={handleOnContentReady}
            allowColumnReordering={true}
            allowColumnResizing={true}
            onEditorPreparing={onEditorPreparing}
            onRowPrepared={onRowPrepared}
            onSelectionChanged={(e) => {
              setSelectedLoad(e.selectedRowsData);
            }}
          >
            <Editing
              allowEditing={pagePermissions.canEditShop}
              allowUpdating={pagePermissions.canEditShop}
              mode="batch"
            />
            <Scrolling mode="infinite" />
            <SearchPanel visible={false} placeholder="search ..." />
            <Selection
              mode={shopGridRadioValue === '2' ? 'single' : 'multiple'}
              selectByClick
              showCheckBoxesMode="none"
            />
            <StateStoring type="custom" enabled={true} customSave={saveState} customLoad={loadState} />
            <Sorting mode="multiple" />
            <HeaderFilter visible={true} />

            <Column
              dataField={'productionDate'}
              caption={'Production Date'}
              dataType="date"
              format={'MM/dd/yyyy'}
              width={115}
              allowHeaderFiltering={true}
            >
              <CustomRule
                ignoreEmptyValue={true}
                validationCallback={productionDateValidate}
                message="Production Date should be earlier than Delivery Date."
              />
            </Column>
            <Column
              dataField={'deliveryDate'}
              caption={'Delivery Date'}
              dataType="date"
              format={'MM/dd/yyyy'}
              width={115}
              allowHeaderFiltering={true}
            >
              <CustomRule
                ignoreEmptyValue={true}
                validationCallback={deliveryDateValidate}
                message="Delivery Date cannot be earlier than Production Date"
              />
            </Column>
            <Column
              dataField="state"
              caption="State"
              allowSearch
              allowFiltering={false}
              allowEditing={false}
              allowHeaderFiltering={false}
            />
            <Column dataField="projectId" caption="Job #" allowEditing={false} allowHeaderFiltering={true} />
            <Column dataField="jobName" caption="Job Name" allowEditing={false} allowHeaderFiltering={false} />
            <Column
              dataField="description"
              caption="Load Description"
              allowEditing={false}
              allowHeaderFiltering={false}
            />
            <Column dataField="cutNumbers" caption="Cut #’s" allowEditing={false} allowHeaderFiltering={false} />
            <Column dataField={'ctrlCodes'} caption={'Ctrl Code'} allowEditing={false} allowHeaderFiltering={true} />
            <Column
              caption="LF of Cable/Rails"
              dataField="lf of Cable/Rails"
              allowEditing={false}
              dataType="number"
              allowHeaderFiltering={false}
              calculateCellValue={renderLFCell}
            />
            <Column
              caption="# of Cables/Rails"
              dataField="rails"
              allowEditing={false}
              allowHeaderFiltering={false}
              calculateCellValue={renderNumberOfCutsRailsCell}
            />
            <Column dataField="totalStuds" caption="# of Studs" allowEditing={false} allowHeaderFiltering={true}>
              <Format type="fixedPoint" />
            </Column>
            <Column dataField="actualDeadEnds" caption="Total DE" allowEditing={false} allowHeaderFiltering={false}>
              <Format type="fixedPoint" />
            </Column>
            <Column
              dataField="actualDeadEndCouplers"
              caption="Total DEC"
              allowEditing={false}
              allowHeaderFiltering={false}
            >
              <Format type="fixedPoint" />
            </Column>
            <Column
              dataField="actualIntermediate"
              caption="Total Intermediate"
              allowEditing={false}
              allowHeaderFiltering={false}
            >
              <Format type="fixedPoint" />
            </Column>
            <Column dataField="encapsulation" caption="Encap" allowEditing={false} allowHeaderFiltering={false} />
            <Column
              dataField="productionStatusId"
              caption="Production Status"
              allowEditing={pagePermissions.canEditShop}
              allowHeaderFiltering={true}
            >
              <Lookup dataSource={cutStatusLookup} valueExpr={'id'} displayExpr={'description'} />
            </Column>
            <Column
              dataField="additionalItemsId"
              caption="Add to load"
              allowEditing={false}
              allowHeaderFiltering={false}
            >
              <Lookup dataSource={additionalItemsLookup} valueExpr={'id'} displayExpr={'description'} />
            </Column>

            <Column dataField="shopNotes" caption="Shop Notes" allowHeaderFiltering={false} />
            <Column
              dataField="custReqDate"
              caption="CR Date"
              dataType="date"
              format={'MM/dd/yyyy'}
              allowHeaderFiltering={false}
            />
          </DataGrid>

          <Typography
            sx={{
              position: 'relative',
              marginTop: '10px',
              px: 2,
              fontSize: '16px',
              height: 50,
              textAlign: 'left',
              alignItems: 'center',
              display: 'flex',
              border: 'solid 1px #f0f0f0',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                backgroundColor: 'red',
                width: '5px',
                height: '100%',
                left: 0,
              }}
              s
            />
            This indicates the load/schedule has cuts in different status.
          </Typography>
          {/* </Box> */}
          <Dialog open={dialogOpen}>
            <DialogContent>
              <DialogContentText>
                <Typography sx={{ fontWeight: 500, fontSize: '1.2rem' }}>{alertDialogContent}</Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={() => setDialogOpen(false)} sx={{ px: 4, border: 'solid 1px' }}>
                Ok
              </Button>
            </DialogActions>
          </Dialog>

          <ActionBar
            sx={{
              justifyContent: 'end',
              display: 'flex',
              gap: '10px',
            }}
          >
            <ActionSubmitButton2 buttonText={'Save'} disabled={!hasEditData} onClick={handleSaveButton} />
            <ActionCancelButton
              buttonText={'cancel'}
              disabled={!hasEditData}
              clickHandler={handleCancelButton}
              width="3%"
            />
          </ActionBar>
        </Grid>
      </Grid>
    </Grid>
  );
}
