import { Box, Button, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import ActionBar from '../shared/actionBar';
import { ActionSubmitButton2 } from '../shared/actionSubmitButton';
import axios from 'axios';
import Input from '../shared/forms/input';
import DateInput from '../shared/forms/date';
import ActionCancelButton from '../shared/actionCancelButton';
import { JOB_STATUS } from '../../common/constants';
import { convertToGMT } from '../../utils/dateUtils';
import { env } from '../../env';

const ImportRebarJob = () => {
  const fabJobVerify = useForm({
    defaultValues: { jobNumber: '' },
  });
  const {
    formState,
    control,
    reset: resetJobImportForm,
    handleSubmit: jobImportFormSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      projectId: '',
      jobStatus: '',
      jobName: '',
      region: '',
      firstDeliveryDate: '',
      lastDeliveryDate: '',
      receivedDate: '',
    },
  });

  async function onSearchJob(data) {
    try {
      const response = await axios.get(`/fab/jobs/verify/${data.jobNumber}`, { loaderRequired: false });
      const jobImportData = response.data;

      setValue('projectId', jobImportData?.projectId ?? '');
      setValue('jobName', jobImportData?.jobName ?? '');
      setValue('jobStatus', JOB_STATUS[jobImportData?.jobStatus] ?? '');
      setValue('region', jobImportData?.region ?? '');
      setValue(
        'firstDeliveryDate',
        jobImportData?.firstDeliveryDate ? convertToGMT(jobImportData?.firstDeliveryDate) : ''
      );
      setValue(
        'lastDeliveryDate',
        jobImportData?.lastDeliveryDate ? convertToGMT(jobImportData?.lastDeliveryDate) : ''
      );
      setValue('receivedDate', jobImportData?.receivedDate ? convertToGMT(jobImportData?.receivedDate) : '');
    } catch (error) {}
  }

  async function saveFabJobImport(formData) {
    try {
      formData.jobStatus = Object.keys(JOB_STATUS).find((key) => JOB_STATUS[key] === formData.jobStatus) ?? 'U';

      await axios.post(`/fab/jobs/import`, { ...formData }, { loaderRequired: false });

      window.location.href = `${env.REACT_APP_CLASSIC_HOME}/project/${formData.projectId}/jobinfo`;
    } catch (error) {}
  }

  return (
    <Grid
      sx={{ width: '50%', alignItems: 'center', alignContent: 'center', mb: 8 }}
      container
      spacing={2}
      direction="column"
      alignItems="center"
    >
      <div>
        <Typography variant="h1">Add/Import Rebar Job</Typography>
      </div>
      <br />
      <form onSubmit={fabJobVerify.handleSubmit(onSearchJob)}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Input
            sx={{ width: '250px' }}
            placeholder={'Enter Rebar Job Number'}
            control={fabJobVerify.control}
            isRequired={true}
            label={'Job Number:'}
            name={'jobNumber'}
          />
          <Button
            type="submit"
            disabled={!fabJobVerify.formState.isDirty || fabJobVerify.formState.isSubmitting}
            className={`btn btn-blue`}
            sx={{ marginLeft: '1rem', width: '120px' }}
            startIcon={
              fabJobVerify.formState.isSubmitting ? <CircularProgress size={20} sx={{ color: '#3862ae' }} /> : null
            }
          >
            {'Search'}
          </Button>
        </Box>
      </form>

      <Grid item>
        <form onSubmit={jobImportFormSubmit(saveFabJobImport)}>
          <Grid item>
            <Card variant="outlined" sx={{ minWidth: 600, px: 6, py: 3 }}>
              <CardContent>
                <Box sx={{ display: 'flex', gap: 4, mb: 2 }}>
                  <Input
                    sx={{ width: '250px' }}
                    control={control}
                    label={'Job Number:'}
                    name={'projectId'}
                    isDisabled={true}
                    isRequired={true}
                  />
                  <Input
                    sx={{ width: '250px' }}
                    control={control}
                    label={'Status:'}
                    name={'jobStatus'}
                    isDisabled={true}
                  />
                </Box>
                <Box sx={{ display: 'flex', gap: 4, mb: 2 }}>
                  <Input
                    sx={{ width: '250px' }}
                    control={control}
                    label={'Job Name:'}
                    name={'jobName'}
                    isDisabled={true}
                  />
                  <Input
                    sx={{ width: '250px' }}
                    control={control}
                    label={'Region:'}
                    name={'region'}
                    isDisabled={true}
                  />
                </Box>
                <Box sx={{ display: 'flex', gap: 4, mb: 2 }}>
                  <Box sx={{ width: '250px' }}>
                    <DateInput
                      control={control}
                      label={'First Delivery Date:'}
                      name={'firstDeliveryDate'}
                      isRequired={true}
                    />
                  </Box>
                  <Box sx={{ width: '250px' }}>
                    <DateInput
                      control={control}
                      label={'Last Delivery Date:'}
                      name={'lastDeliveryDate'}
                      isRequired={true}
                    />
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', gap: 4, width: '250px' }}>
                  <DateInput control={control} label={'Received Date:'} name={'receivedDate'} isRequired={true} />
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <ActionBar display={'flex'} justifyContent={'end'} gap={'10px'}>
            <ActionSubmitButton2
              sx={{ width: '100px' }}
              className={`btn-blue btn`}
              loading={formState.isSubmitting}
              isDisabled={formState.isSubmitting || !(Object.keys(formState.dirtyFields).length ?? false)}
              buttonText={'Create'}
            />
            <ActionCancelButton
              buttonText={'Cancel'}
              disabled={formState.isSubmitting || !(Object.keys(formState.dirtyFields).length ?? false)}
              clickHandler={() => {
                resetJobImportForm();
                fabJobVerify.reset();
              }}
            />
          </ActionBar>
        </form>
      </Grid>
    </Grid>
  );
};

export default ImportRebarJob;
