import { Box, Button } from '@mui/material';
import { Viewer } from '@react-pdf-viewer/core';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import { printPlugin } from '@react-pdf-viewer/print';
import { useCallback, useMemo, useState } from 'react';

const PDFViewerModalHelper = ({ fileUrl, fileName, setPdfViewerOpen }) => {
  const printPluginInstance = printPlugin();
  const { Print } = printPluginInstance;
  const getFilePluginInstance = getFilePlugin({
    fileNameGenerator: (file) => fileName || file.data,
  });
  const { Download } = getFilePluginInstance;

  return (
    <Box
      sx={{
        flexDirection: 'column',
        overflow: 'hidden',
        left: 0,
        position: 'fixed',
        top: 0,
        height: '100vh',
        width: '100%',
        zIndex: 9999,
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'end',
          backgroundColor: '#fff',
          gap: 2,
          color: '#000',
          display: 'flex',
          px: '2.5rem',
          py: '.5rem',
          borderBottom: 'solid 1px hsl(0, 0%, 89.8%)',
          boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
        }}
      >
        <Print>
          {(props) => (
            <Button className="btn btn-blue" onClick={props.onClick}>
              Print
            </Button>
          )}
        </Print>
        <Download>
          {(props) => (
            <Button className="btn btn-blue" onClick={props.onClick}>
              Download
            </Button>
          )}
        </Download>
        <Button className="btn btn-cancel" onClick={() => setPdfViewerOpen(false)}>
          Close
        </Button>
      </Box>
      <Box sx={{ flexGrow: 1, overflow: 'auto', height: '100%' }}>
        <Viewer fileUrl={fileUrl} plugins={[printPluginInstance, getFilePluginInstance]} />
      </Box>
    </Box>
  );
};

export const usePDFViewerModal = ({ fileUrl, fileName }) => {
  const [pdfViewerOpen, setPdfViewerOpen] = useState(false);

  const PDFViewerModal = useCallback(() => {
    return <PDFViewerModalHelper fileName={fileName} fileUrl={fileUrl} setPdfViewerOpen={setPdfViewerOpen} />;
  }, [setPdfViewerOpen, fileName, fileUrl]);

  return useMemo(
    () => ({ setPdfViewerOpen, PDFViewerModal, pdfViewerOpen }),
    [setPdfViewerOpen, PDFViewerModal, pdfViewerOpen]
  );
};
