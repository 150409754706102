import { Helmet } from 'react-helmet-async';
import TabPanel from '../../../components/shared/tabPanel';
import { Tab, Tabs } from '@mui/material';
import ShopSchedule from '../../../components/PT/Schedules/shopSchedule';
import DispatchSchedule from '../../../components/PT/Schedules/dispatchSchedule';
import { useSchedulesContext } from '../../../context/schedulesContext';
import { useUserContext } from '../../../context/userContext';
import ShippedSchedule from '../../../components/PT/Schedules/shippedSchedule';
import PageLoader from '../../../components/shared/pageLoader';

export default function Schedules() {
  const { user } = useUserContext();
  const { setTabValue, tabValue, PTLocationsLoading, PTLocationsError } = useSchedulesContext();

  const pagePermissions = {
    canEditShop: user.isPTScheduler,
    canEditDispatch: user.isPTDispatcher,
    canEditShipped: user.isPTProjectManager,
  };

  function handleChange(event, newValue) {
    setTabValue(newValue);
  }

  if (PTLocationsLoading) return <PageLoader />;
  if (PTLocationsError) return <div>Error loading data</div>;

  return (
    <>
      <Helmet>
        <title>Schedule Form</title>
      </Helmet>
      <Tabs value={tabValue} onChange={handleChange} aria-label="">
        <Tab label="Shop Schedule" />
        <Tab label="Dispatch Schedule" />
        <Tab label="Shipped Schedule" />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <ShopSchedule pagePermissions={pagePermissions} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <DispatchSchedule pagePermissions={pagePermissions} />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <ShippedSchedule pagePermissions={pagePermissions} />
      </TabPanel>
    </>
  );
}
