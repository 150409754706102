import { Controller } from 'react-hook-form';
import { FormControl, TextField } from '@mui/material';
import React from 'react';

const Input = React.forwardRef(
  ({ control, isDisabled, isRequired, label, name, placeholder = '', maxLength, ...props }, ref) => {
    return (
      <FormControl ref={ref} size="medium" fullWidth {...props}>
        <Controller
          control={control}
          name={name}
          rules={{ ...(isRequired ? { required: isRequired } : {}) }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              id={name}
              placeholder={placeholder}
              disabled={isDisabled}
              label={label}
              error={!!error}
              helperText={error?.message}
              required={isRequired}
              variant="outlined"
              value={field.value ?? ''}
              inputProps={{
                ...(maxLength !== undefined && { maxLength }),
              }}
            />
          )}
        />
      </FormControl>
    );
  }
);

export default Input;
