import { Link } from 'react-router-dom';
import { Button, Grid } from '@mui/material';

export default function Module({ children, target }) {
    return (
        <Grid item xs={6} sm={4}>
            <Link to={target} className="module">
                <Button>{children}</Button>
            </Link>
        </Grid>
    )
}