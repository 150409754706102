import { Helmet } from 'react-helmet-async';
import { Box, Typography } from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';
import { useUserContext } from '../../../context/userContext';
import { AccessoriesForm } from '../../../components/PT/PTQuantities/accessoriesForm';
import { useJob } from '../../../utils/masterData';
import PageLoader from '../../../components/shared/pageLoader';

export default function AccessoriesComponents() {
  const { user } = useUserContext();
  const [searchParams] = useSearchParams();
  const { loadDescription } = useParams();
  const { jobId } = useParams();
  const { data: jobData, isLoading, error } = useJob({ jobId });

  const jobName = searchParams.get('jn'); //job name

  const pagePermissions = {
    canEdit: (user.isPTProjectManager || user.isPTDetailer) && !jobData?.value?.jobOnHold,
  };
  if (isLoading) return <PageLoader />;
  if (error) return <div>Error loading project info</div>;

  return (
    <>
      <Helmet>
        <title>Accessories</title>
      </Helmet>
      <Typography variant="h1">
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={2}>
          <Typography variant="h1">{decodeURIComponent(jobName)}</Typography>
          <Typography variant="h1">{loadDescription}</Typography>
        </Box>
      </Typography>
      <AccessoriesForm pagePermissions={pagePermissions} />
      
    </>
  );
}
