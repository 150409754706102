import { Grid } from '@mui/material';
import PartsForm from '../../../components/PT/Admin/partsForm';
import { Helmet } from 'react-helmet-async';

export default function Parts() {
  return (
    <>
      <Helmet>
        <title>Parts</title>
      </Helmet>
      <Grid container spacing={2}>
        <PartsForm />
      </Grid>
    </>
  );
}
