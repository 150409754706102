import { Helmet } from 'react-helmet-async';
import { Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import CutDropZone from '../../../components/PT/PTQuantities/cutDropZone';
import { matchPath, useLocation, useParams, useSearchParams } from 'react-router-dom';
import PTCutsData from '../../../components/PT/PTQuantities/cutsData';
import StudRailCutsData from '../../../components/PT/StudRail/cutsData';
import PageLoader from '../../../components/shared/pageLoader';
import axios from 'axios';
import { useSnackBar } from '../../../context/snackBarContext';
import { useUserContext } from '../../../context/userContext';
import { ROUTE_JOBS_STUD_RAIL_QUANTITIES, ROUTE_STUD_RAIL_CUT_IMPORT } from '../../../routes';
import { useJob } from '../../../utils/masterData';

export default function CutImport() {
  const { jobId, loadDescription } = useParams();
  const { user } = useUserContext();
  const [searchParams] = useSearchParams();
  const mode = searchParams.get('mode');
  const loadId = searchParams.get('loadId');

  //* get the loadId and jobName from history state
  let { state, pathname } = useLocation();
  const isStudRailImport = !!matchPath(
    `/pt/jobs/${ROUTE_JOBS_STUD_RAIL_QUANTITIES}/${ROUTE_STUD_RAIL_CUT_IMPORT}`,
    decodeURIComponent(pathname)
  );
  const jobName = state?.jobName;
  const { setSnackbar } = useSnackBar();
  const [cutsData, setCutsData] = useState([]);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { data: jobData, isLoading: jobLoading, error: jobError } = useJob({ jobId });
  //* to handle the navigation of cuts
  const [currentIndex, setCurrentIndex] = useState(0);
  const pagePermissions = {
    canEdit: (user.isPTProjectManager || user.isPTDetailer) && !jobData?.value?.jobOnHold,
  };
  //* handle the edit mode
  // * check query params, if edit mode - get the cut data by loadId.
  // * set the count/currentIndex state if history has selected cut state.
  useEffect(() => {
    (async () => {
      if (mode.toLowerCase() !== 'edit' || !pathname.length) return;
      try {
        const type = isStudRailImport ? '2' : '1';
        setIsLoading(true);
        const res = await axios.get(`jobs/${jobId}/loads/${loadId}/cuts?type=${type}`, { loaderRequired: false });
        // * setCurrentIndex state, if history state has selected cut id.
        if (!res.data?.value.length) {
          return; // no cuts.
        }
        if (state?.selectedCut) {
          res.data.value.forEach((cut, index) => {
            if (state.selectedCut === cut.cutId) setCurrentIndex(index);
          });
        }
        setCutsData(res.data.value);
      } catch (error) {
        setSnackbar({
          open: true,
          message: `Error retrieving cuts data`,
          severity: 'error',
        });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [mode, loadId, jobId, pathname, isStudRailImport]);

  const editorProps = {
    cutsData, // (can be imported cuts data) or (in edit mode cuts data) //
    setCutsData, // need to update the original cuts data list length, to render back cutDropZone.
    isFormDirty,
    setIsFormDirty,
    setCurrentIndex,
    pagePermissions,
    currentIndex,
  };

  if (isLoading || jobLoading) return <PageLoader />;

  if (jobError) return <div>Error loading project info</div>;

  const studRailImportCheck = () => {
    if (isStudRailImport) {
      return <StudRailCutsData {...editorProps} />;
    }

    return <PTCutsData {...editorProps} />;
  };

  return (
    <div>
      <Helmet>
        <title>Cut Import</title>
      </Helmet>
      <Grid container spacing={1} sx={{ margin: '1rem auto 0', width: '75%', mb: 8 }}>
        <Grid
          item
          xs={12}
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h1">{`${jobName} (${jobId})`}</Typography>
          <Typography variant="h1">
            Load #: <span>{loadDescription}</span>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {!cutsData.length ? (
            <CutDropZone loadId={state?.loadId} setCutsData={setCutsData} setIsFormDirty={setIsFormDirty} />
          ) : (
            studRailImportCheck()
          )}
        </Grid>
      </Grid>
    </div>
  );
}
