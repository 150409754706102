import Logo from './logo';
import { Grid } from '@mui/material';
import SearchBar from './searchBar';
import ProfileNav from './profileNav';

export default function Header({ nosearch }) {
    return (
        <div className="hdr">
            <Grid className="hdr-main" container rowSpacing={{ xs: 1, md: 0 }} columnSpacing={{ xs: 0 }}>
                <Grid
                    item
                    xs={12}
                    md={3}
                    lg={2}
                    sx={{
                        display: 'flex',
                        justifyContent: { xs: 'center', md: 'flex-start' },
                    }}
                    className="hdr-col"
                >
                    <Logo />
                </Grid>
                {!nosearch && (
                    <Grid item xs={12} md={5} lg={6} className="hdr-col">
                        <SearchBar />
                    </Grid>
                )}
                <Grid item sm={4} lg={3} className="hdr-col col-links">
                    <ProfileNav />
                </Grid>
            </Grid>
        </div>
    );
}
