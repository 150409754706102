import { Controller } from 'react-hook-form';
import { Box, Checkbox as MuiCheckbox, FormControlLabel } from '@mui/material';

const styles = {
  color: 'rgba(0,0,0,.87)',
  fontSize: { xs: '.9125rem', xl: '1rem' },
};

export default function Checkbox({ control, isDisabled, isRequired, label, name }) {
  return (
    <Box sx={{ alignItems: 'center', display: 'flex' }}>
      <FormControlLabel
        disabled={isDisabled}
        control={
          <Controller
            control={control}
            defaultValue={false}
            name={name}
            render={({ field }) => (
              <MuiCheckbox
                disabled={isDisabled}
                onChange={(e) => field.onChange(e.target.checked)}
                checked={field.value}
              />
            )}
            sx={styles}
          />
        }
        label={label}
      />
    </Box>
  );
}
