import { useCallback, useEffect, useRef, useState } from 'react';
import { useSnackBar } from '../../../context/snackBarContext';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import {
  Skeleton,
  Grid,
  Typography,
  Box,
  FormGroup,
  FormControlLabel,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import {
  Column,
  Editing,
  RequiredRule,
  Format,
  Selection,
  Lookup,
  DataGrid,
  TotalItem,
  Summary,
  Button as DevextremeButton,
} from 'devextreme-react/data-grid';
import ActionBar from '../../shared/actionBar';
import ActionCancelButton from '../../shared/actionCancelButton';
import { ActionSubmitButton2 } from '../../shared/actionSubmitButton';
import { useQuery } from '@tanstack/react-query';
import { useJob } from '../../../utils/masterData';

export default function EstimatesForm({ pagePermissions }) {
  const { jobId } = useParams();
  const { data } = useJob({ jobId });
  const { data: billingTypeLookup } = useQuery({
    queryKey: ['common/billingtype'],
    queryFn: async () => {
      const response = await axios.get(`common/billingtype`);
      return response.data;
    },
  });

  const dataGridRef = useRef();
  const { setSnackbar } = useSnackBar();

  const [estimatesData, setEstimatesData] = useState([]);
  const [bidItemsLookupData, setBidItemsLookupData] = useState([]);
  const [partClassLookupData, setpartClassLookupData] = useState([]);

  const [loader, setLoader] = useState(true);
  const [hasEditData, setHasEditData] = useState(false);
  const [jobName, setJobName] = useState();
  // default prop for reset
  const [progressBilling, setProgressBilling] = useState();
  const [prevProgressBilling, setPrevProgressBilling] = useState();
  const [isProgressBillingEditData, setIsProgressBillingEditData] = useState(false);

  const [estimateDeleteModal, setEstimateDeleteModal] = useState({
    open: false,
    message: '',
    estimateSavePayload: null,
  });

  const [defaultEstimates, setDefaultEstimates] = useState();
  let defaultRowData;

  //* adds default values for new row insert
  function onInitNewRow(e) {
    let data = defaultRowData;
    if (data) {
      e.data.bidItemId = data.bidItemId;
      e.data.partClassId = data.partClassId === 0 ? undefined : data.partClassId;
      e.data.partId = data.partId === 0 ? undefined : data.partId;
      e.data.perUnit = data.perUnit;
      e.data.uom = data.uom;
      e.data.costPerUnit = data.costPerUnit;
      e.data.quantity = data.quantity;
      defaultRowData = undefined;
    }
  }

  //* get all data api call
  useEffect(() => {
    Promise.all([
      axios.get(`Jobs/${jobId}/estimates`, { loaderRequired: false }),
      axios.get(`Jobs/biditems`, { loaderRequired: false }),
      axios.get(`PT/PartClass`, { loaderRequired: false }),
    ])
      .then((results) => {
        let estimatesResponse = results[0].data;
        setJobName(estimatesResponse?.jobName);

        if (estimatesResponse.isDefaultEstimates) {
          setDefaultEstimates(estimatesResponse.estimates);
        } else {
          setEstimatesData(estimatesResponse.estimates);
        }
        setBidItemsLookupData(results[1].data);
        setpartClassLookupData(results[2].data);
        setProgressBilling(estimatesResponse?.progressBilling);
        setPrevProgressBilling(estimatesResponse?.progressBilling);
        setLoader(false);
      })
      .catch((error) => {
        setSnackbar({
          open: true,
          message: `Error occured while getting estimates.`,
          severity: 'error',
        });
        console.log(error);
        setLoader(false);
      });
  }, [jobId, setSnackbar]);

  const onInitialized = function (e) {
    if (defaultEstimates?.length && pagePermissions.canEdit) {
      defaultEstimates.forEach((estimate) => {
        defaultRowData = estimate;
        e.component.addRow();
      });
    }
  };

  //* removes the data grid toolbar header buttons
  const handleOnToolbarPreparing = useCallback((e) => {
    e.toolbarOptions.items.splice(0);
  }, []);

  //* refresh datagrid data on insert new row
  async function refresh() {
    try {
      const estimatesResponse = await axios.get(`Jobs/${jobId}/estimates`, { loaderRequired: false });
      if (estimatesResponse.data.isDefaultEstimates) {
        setDefaultEstimates(estimatesResponse.data.estimates);
        setEstimatesData([]); // making this blank
      } else {
        setDefaultEstimates([]); // making this blank.
        setEstimatesData(estimatesResponse.data.estimates);
      }
    } catch (error) {}
  }

  //* on save data grid handler
  async function handleSaving(e) {
    // default save behavior canceled
    e.cancel = true;

    let payload = {
      progressBilling: progressBilling,
      estimates: [],
      estimatesToRemove: [],
    };

    let allRecords = dataGridRef.current.instance.getVisibleRows();

    e.changes.forEach((change) => {
      if (change.type === 'remove') {
        // Check if job is exported and prevent deletion

        let rowData = allRecords.find((r) => r.data?.estimateId === change.key);
        if (rowData?.data) {
          payload.estimatesToRemove.push(rowData.data);
        }
      } else if (change.type === 'insert') {
        payload.estimates.push(change.data);
      } else {
        // If this is update, get other fields as well
        let row = allRecords.find((r) => r.data?.estimateId === change.key);
        if (row?.data) {
          payload.estimates.push({
            ...row.data,
            createdBy: undefined,
            createdDate: undefined,
          });
        }
      }
    });

    // check valid unique bidItemId and partclassId
    if (payload.estimates.length && !isValidUniqueBidItemIdPartClassId(payload.estimates)) {
      setSnackbar({
        open: true,
        message: 'Duplicate BidItemId PartclassId found!',
        severity: 'error',
      });
      return;
    }
    if (payload.estimatesToRemove.length) {
      validateEstimateDelete(payload, e?.component);
    } else {
      e.promise = saveEstimates(payload, e?.component);
    }
  }

  async function validateEstimateDelete(payload, component) {
    try {
      const response = await axios.put(`Jobs/${jobId}/validatedeleteestimates`, payload);

      if (response.data?.errors?.length) {
        setEstimateDeleteModal({
          open: true,
          message: response.data.errors.join(', '),
          estimateSavePayload: payload, // need this state.
        });
        return;
      }
      saveEstimates(payload, component);
    } catch (error) {}
  }

  async function saveEstimates(estimates, component) {
    try {
      await axios.put(`Jobs/${jobId}/estimates`, estimates);
      setSnackbar({
        open: true,
        message: `Saved successfully`,
        severity: 'success',
      });
      await refresh();
      component?.cancelEditData();
      if (estimateDeleteModal.open) closeEstimateDeleteModal();
    } catch (error) {
      console.log(error);
      setSnackbar({
        open: true,
        message: `Error occured while updating estimates.`,
        severity: 'error',
      });
    }
  }

  // validate unique bidItemId and PartClassId
  function isValidUniqueBidItemIdPartClassId(estimates) {
    //* logic to remove old estimate data and take the updated estimates data
    //* create hashset id:true to exclude from old estimatesData
    const ids = {};
    //* check for all ids in estimates if present
    estimates.forEach((item) => {
      if (item?.estimateId) ids[item?.estimateId] = true;
    });
    //* use ids hashset to exclude from the estimatesData list
    const updatedEstimates = [estimatesData.filter((item) => !ids[item.estimateId]), ...estimates].flat();

    //* create set object to check for duplicate bidItemId partClassId
    const uniqueBidItemIdPartClassId = new Set();
    for (const row of updatedEstimates) {
      const pair = `${row.bidItemId}-${row.partClassId}`;
      //* if in set return false
      if (uniqueBidItemIdPartClassId.has(pair)) {
        return false;
      }
      uniqueBidItemIdPartClassId.add(pair);
    }
    return true;
  }

  // cancel button handler
  const handleCancelButton = useCallback(() => {
    dataGridRef.current.instance.cancelEditData();
    // revert the progress billing also
    setProgressBilling(prevProgressBilling);
    setIsProgressBillingEditData(false);
  }, [prevProgressBilling]);

  // save button handler
  const handleSaveButton = useCallback(() => {
    dataGridRef.current.instance.saveEditData();
    setHasEditData(true);
    setIsProgressBillingEditData(false);
    //updates the prev value now
    setPrevProgressBilling(progressBilling);
  }, [progressBilling]);

  // add row button
  const handleAddRowButton = useCallback(
    (e) => {
      axios
        .get(`PT/PartClass`, { loaderRequired: false })
        .then((results) => {
          setpartClassLookupData(results.data);
          setLoader(false);
        })
        .catch((error) => {
          setSnackbar({
            open: true,
            message: `Error occured while getting part class.`,
            severity: 'error',
          });
          console.log(error);
          setLoader(false);
        });
      dataGridRef.current.instance.addRow();
    },
    [jobId, setSnackbar]
  );

  // progress billing check box handle
  function handleProgressBilling(e) {
    setProgressBilling(e.target.checked);
    setIsProgressBillingEditData(true);
  }

  const handleOnContentReady = useCallback((e) => {
    setHasEditData(e.component.hasEditData());
  }, []);

  const customizeText = (itemInfo) => `${itemInfo.value?.toFixed(2)}`;

  const onUpdateQuantity = function (newData, value, currentRowData) {
    newData.quantity = value;
    let perUnit = currentRowData.perUnit;
    if (!perUnit || perUnit === 0) perUnit = 1;
    newData.salesPrice = (currentRowData.pricePerUnit ? currentRowData.pricePerUnit : 0) * (value / perUnit);
    newData.budgetCost = (currentRowData.costPerUnit ? currentRowData.costPerUnit : 0) * (value / perUnit);
  };

  const onUpdateUnitPrice = function (newData, value, currentRowData) {
    newData.pricePerUnit = value;
    let perUnit = currentRowData.perUnit;
    if (!perUnit || perUnit === 0) perUnit = 1;
    newData.salesPrice = ((currentRowData.quantity ? currentRowData.quantity : 0) / perUnit) * value;
    newData.salesPrice = isFinite(newData.salesPrice) ? newData.salesPrice : 0;
  };

  const onUpdateCostPrice = function (newData, value, currentRowData) {
    newData.costPerUnit = value;
    let perUnit = currentRowData.perUnit;
    let costPerUnit = newData.costPerUnit;
    if (!costPerUnit || costPerUnit === 0) costPerUnit = 1;
    if (!perUnit || perUnit === 0) perUnit = 1;
    newData.budgetCost = (currentRowData.quantity ? currentRowData.quantity : 0) * (costPerUnit / perUnit);
    newData.budgetCost = isFinite(newData.budgetCost) ? newData.budgetCost : 0;
  };

  const onUpdateBudgetCost = function (newData, value, currentRowData) {
    newData.budgetCost = value;
    let perUnit = currentRowData.perUnit;
    if (!perUnit || perUnit === 0) perUnit = 1;
    newData.costPerUnit = (value / (currentRowData.quantity ? currentRowData.quantity : 0)) * perUnit;
    newData.costPerUnit = isFinite(newData.costPerUnit) ? newData.costPerUnit : 0;
  };

  const onUpdateSalesPrice = function (newData, value, currentRowData) {
    newData.salesPrice = value;
    let perUnit = currentRowData.perUnit;
    if (!perUnit || perUnit === 0) perUnit = 1;
    if (currentRowData.quantity) newData.pricePerUnit = value / (currentRowData.quantity / perUnit);
    newData.pricePerUnit = isFinite(newData.pricePerUnit) ? newData.pricePerUnit : 0;
  };

  const getFilteredParts = (options) => ({
    store: allParts,
    filter: options.data ? [['partClassId', '=', options.data.partClassId], 'and', ['isActive', '=', true]] : null,
  });

  const { data: allParts } = useQuery({
    queryKey: 'pt/parts',
    queryFn: async () => (await axios.get('pt/parts')).data,
  });

  const onUpdatePartClassGroup = async function (newData, value, currentRowData) {
    newData.partClassId = value;
    let partClassInfo = partClassLookupData.find((c) => c.partClassId === value);

    if (partClassInfo) {
      newData.uom = partClassInfo.uom;
      newData.partId = partClassInfo.defaultPart;
      newData.perUnit = partClassInfo.perUnit;
    } else {
      newData.uom = '';
      newData.partId = '';
      newData.perUnit = 0;
      newData.costPerUnit = 0.0;
      newData.budgetCost = 0.0;
    }
  };
  const deleteButtonVisible = useCallback(
    (rowProps) => {
      return pagePermissions.canEdit;
    },
    [pagePermissions]
  );

  function closeEstimateDeleteModal() {
    setEstimateDeleteModal({ message: '', open: false, estimateSavePayload: null });
    dataGridRef.current.instance.cancelEditData();
  }

  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        {loader ? (
          <Skeleton width={300} height={50} />
        ) : (
          <Typography variant="h1">{`${jobName} (${jobId})`}</Typography>
        )}
        {loader ? (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <Skeleton width={100} height={50} sx={{ marginRight: '1rem' }} />
            <Skeleton width={100} height={50} />
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <FormGroup sx={{ marginRight: '1rem' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!pagePermissions.canEdit || data?.value?.regionID !== 'WST'}
                    onChange={handleProgressBilling}
                    checked={progressBilling}
                  />
                }
                label="Progress Billing"
              />
            </FormGroup>
            {pagePermissions.canEdit ? (
              <Button onClick={handleAddRowButton} className="btn btn-blue btn-icon" startIcon={<AddIcon />}>
                Add Row
              </Button>
            ) : null}
          </Box>
        )}
      </Grid>
      <Grid item xs={12} marginBottom={9}>
        {loader ? (
          <Skeleton sx={{ width: '100%', height: 300 }} />
        ) : (
          <>
            <DataGrid
              ref={dataGridRef}
              id="gridContainer"
              dataSource={estimatesData}
              keyExpr={'estimateId'}
              showBorders={true}
              onSaving={handleSaving}
              rowAlternationEnabled={true}
              onToolbarPreparing={handleOnToolbarPreparing}
              onContentReady={handleOnContentReady}
              onInitNewRow={onInitNewRow}
              onInitialized={onInitialized}
              allowColumnResizing={true}
              onEditorPreparing={(e) => {
                if (e.dataField === 'bidItemId' && e.row.data.isExported) {
                  e.editorOptions.readOnly = true;
                }
              }}
            >
              <Selection mode="single" />
              <Editing
                mode="batch"
                allowUpdating={pagePermissions.canEdit}
                allowAdding={pagePermissions.canEdit}
                allowDeleting={pagePermissions.canEdit}
                selectTextOnEditStart={true}
              />
              <Column dataField="bidItemId" caption={'Bid Item'} width="10%">
                <Lookup dataSource={bidItemsLookupData} valueExpr="id" displayExpr="description" />
                <RequiredRule />
              </Column>
              <Column
                dataField="partClassId"
                caption={'Part Class'}
                setCellValue={onUpdatePartClassGroup}
                width="12%"
                allowEditing={true}
              >
                <Lookup dataSource={partClassLookupData} valueExpr={'partClassId'} displayExpr={'name'} />
                <RequiredRule />
              </Column>
              <Column dataField="partId" allowSearch allowFiltering={false} caption="Part" allowEditing={true}>
                <Lookup
                  dataSource={getFilteredParts}
                  valueExpr="partId"
                  displayExpr="partNumber"
                  allowClearing="true"
                />
                <RequiredRule />
              </Column>
              <Column dataField="quantity" caption="Quantity" setCellValue={onUpdateQuantity}>
                <RequiredRule />
                <Format type="fixedPoint" />
              </Column>
              <Column dataField="perUnit" caption="Per Unit" allowEditing={true} width="6%">
                <Format type="fixedPoint" />
              </Column>
              <Column dataField="uom" caption="UOM" allowEditing={true} width="5%" />
              <Column dataField="salesPrice" caption="Sales Price $" setCellValue={onUpdateSalesPrice} width="10%">
                <RequiredRule />
                <Format type="fixedPoint" precision={2} />
              </Column>
              <Column dataField="pricePerUnit" caption="Unit Price $" setCellValue={onUpdateUnitPrice}>
                <RequiredRule />
                <Format type="fixedPoint" precision={2} />
              </Column>
              <Column
                dataField="budgetCost"
                caption="Budget Cost $"
                allowEditing={true}
                width="10%"
                setCellValue={onUpdateBudgetCost}
              >
                <Format type="fixedPoint" precision={2} />
              </Column>
              <Column
                dataField="costPerUnit"
                allowEditing={true}
                caption="Cost/Unit $"
                setCellValue={onUpdateCostPrice}
              >
                <Format type="fixedPoint" precision={2} />
              </Column>
              <Column dataField="billingType" caption="Billing Type" width="6%" allowEditing={pagePermissions.canEdit}>
                <Lookup dataSource={billingTypeLookup} valueExpr={'code'} displayExpr={'code'} />
                <RequiredRule />
              </Column>
              <Summary>
                <TotalItem column="budgetCost" summaryType="sum" customizeText={customizeText}></TotalItem>
                <TotalItem column="salesPrice" summaryType="sum" customizeText={customizeText}></TotalItem>
              </Summary>
              <Column type="buttons">
                <DevextremeButton name="delete" visible={deleteButtonVisible} />
              </Column>
            </DataGrid>
            <ActionBar
              sx={{
                justifyContent: 'end',
                display: 'flex',
                gap: '10px',
              }}
            >
              <ActionSubmitButton2
                buttonText={'Save'}
                disabled={!hasEditData && !isProgressBillingEditData}
                onClick={handleSaveButton}
              />
              <ActionCancelButton
                buttonText={'cancel'}
                disabled={!hasEditData && !isProgressBillingEditData}
                clickHandler={handleCancelButton}
                width="3%"
              />
            </ActionBar>
            <EstimatesDeleteModal
              {...estimateDeleteModal}
              handleClose={closeEstimateDeleteModal}
              handleSave={() => saveEstimates(estimateDeleteModal.estimateSavePayload, dataGridRef.current.instance)}
            />
          </>
        )}
      </Grid>
    </>
  );
}

function EstimatesDeleteModal(props) {
  const { open, handleClose, message, handleSave } = props;
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        You are about to delete bid item(s) that are already exported to SAP. Please make sure to remove them in SAP as
        well.
      </DialogTitle>
      <DialogContent sx={{ minWidth: 400 }}>
        <DialogContentText id="alert-dialog-slide-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ px: 4, pb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography sx={{ fontWeight: 700 }}>{'Do you want to continue?'}</Typography>
        <Box sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Button onClick={handleClose}>No</Button>
          <Button className="btn-blue btn" onClick={handleSave}>
            yes
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
