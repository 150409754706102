import { Grid } from '@mui/material';
import StudRailsForm from '../../../components/PT/StudRail/studRailsForm';
import { Helmet } from 'react-helmet-async';
import { useUserContext } from '../../../context/userContext';
import { useJob } from '../../../utils/masterData';
import PageLoader from '../../../components/shared/pageLoader';
import { useParams } from 'react-router-dom';

export default function StudRailQuantities() {
  const { user } = useUserContext();
  const { jobId } = useParams();
  const { data: jobData, isLoading, error } = useJob({ jobId });
  if (isLoading) return <PageLoader />;

  if (error) return <div>Error loading project info</div>;

  const pagePermissions = {
    canEdit: (user.isPTProjectManager || user.isPTDetailer) && !jobData?.value?.jobOnHold,
    canEditLoad: user.isPTProjectManager && !jobData.value.jobOnHold,
  };

  return (
    <>
      <Helmet>
        <title>Stud Rails Quantities</title>
      </Helmet>
      <Grid container minHeight={'calc(100vh - 200px'}>
        <StudRailsForm pagePermissions={pagePermissions} />
      </Grid>
    </>
  );
}
