import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { usePTLocation } from '../utils/masterData';
import { useSnackBar } from './snackBarContext';
import { useUserContext } from './userContext';

const SchedulesConext = createContext({
  tabValue: 0,
  setTabValue: () => null,
  shopGridRadioValue: '2',
  setShopGridRadioValue: () => null,
  selectedLocations: [],
  setSelectedLocations: () => null,
  PTLocationsLoading: true,
  PTLocations: undefined,
  PTLocationsError: undefined,
});

export default function SchedulesProvider({ children }) {
  const { user } = useUserContext();
  const { data: PTLocations, isLoading: PTLocationsLoading, error: PTLocationsError } = usePTLocation(); // locations data fetch
  const [tabValue, setTabValue] = useState(0);
  const [shopGridRadioValue, setShopGridRadioValue] = useState('2'); // handle grid state for awaiting approval and approved
  const [selectedLocations, setSelectedLocations] = useState([]); // filter by locations
  const { setSnackbar } = useSnackBar();

  useEffect(() => {
    if (PTLocations) {
      // Check cache
      const cache = localStorage.getItem('pt-location-filter');
      const cacheLocations = JSON.parse(cache);
      if (cacheLocations?.length) {
        const locationSet = new Set(cacheLocations.map((l) => l.locationID));
        setSelectedLocations(PTLocations.filter((l) => locationSet.has(l.locationID)));
      } else {
        // Get user office
        const userLocationId = user.office;
        const initialLocations = PTLocations.filter((location) => location.locationID === userLocationId) || [];
        setSelectedLocations(initialLocations);
      }
    }
  }, [PTLocations]);

  if (PTLocationsError) setSnackbar({ open: true, message: 'Error getting filter locations', severity: 'warning' });

  const value = useMemo(
    () => ({
      tabValue,
      setTabValue,
      shopGridRadioValue,
      setShopGridRadioValue,
      selectedLocations,
      setSelectedLocations,
      PTLocationsLoading,
      PTLocations,
      PTLocationsError,
    }),
    [
      tabValue,
      setTabValue,
      shopGridRadioValue,
      setShopGridRadioValue,
      selectedLocations,
      setSelectedLocations,
      PTLocationsLoading,
      PTLocations,
      PTLocationsError,
    ]
  );
  return <SchedulesConext.Provider value={value}>{children}</SchedulesConext.Provider>;
}

export const useSchedulesContext = () => {
  const context = useContext(SchedulesConext);

  if (!context) throw new Error("Schedules context should be called in it's provider.");

  return context;
};
