import Module from '../Home/module';
import { Box, Grid, Skeleton, Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import * as Path from '../../routes';
import { useUserContext } from '../../context/userContext';

export default function ModuleList() {
    const { user: currentUser, loading } = useUserContext();

    return (
        <Box sx={{ height: 'auto', overflow: 'hidden' }}>
            <Grid container columnSpacing={2} rowSpacing={2} className="module-pt-grid">
                {loading ? (
                    Array.from({ length: 20 }, (index) => (
                        <Module key={index}>
                            <Skeleton width={'100%'} height={400} />
                        </Module>
                    ))
                ) : (
                    <>
                        {currentUser.isAdmin && (
                            <Module target={Path.ROUTE_PT_ADMIN}>
                                <SettingsIcon className="module-icon" />
                                <Typography variant="button" className="module-title">
                                    Admin
                                </Typography>
                            </Module>
                        )}
                        <Module target={'schedules'}>
                            <PendingActionsOutlinedIcon className="module-icon" />
                            <Typography variant="button" className="module-title">
                                Schedules
                            </Typography>
                        </Module>
                    </>
                )}
            </Grid>
        </Box>
    );
}
