import { useEffect, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSnackBar } from '../../../context/snackBarContext';
import { useUserContext } from '../../../context/userContext';
import axios from 'axios';
import { PatternFormat } from 'react-number-format';
import Checkbox from '../../shared/forms/checkbox';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import ActionBar from '../../shared/actionBar';
import { ActionSubmitButton2 } from '../../shared/actionSubmitButton';
import ActionCancelButton from '../../shared/actionCancelButton';

export default function UsersForm(props) {
  const { currentUser, PTLocations, refetchUsers } = props;
  const { setSnackbar } = useSnackBar();
  const { refresh } = useUserContext();

  const { register, reset, handleSubmit, formState, control } = useForm({
    defaultValues: currentUser,
  });
  const { dirtyFields, errors, isSubmitting } = formState;

  // set the form state when user changed
  useEffect(() => {
    if (currentUser === undefined) {
      setSnackbar({
        open: true,
        message: 'Error populating user data',
        severity: 'warning',
      });
    } else {
      reset(currentUser);
    }
  }, [currentUser, reset, setSnackbar]);

  // update user
  async function onSubmit(data) {
    const user = {
      ...data,
    };

    try {
      await axios.patch(`users/${currentUser.userId}`, { ...user }, { loaderRequired: false });

      setSnackbar({
        open: true,
        message: 'User updated',
        severity: 'success',
      });

      // update the users
      // const updatedUsers = users.map((u) => {
      //   if (u.userId === user.userId) {
      //     u = user;
      //   }
      //   return u;
      // });
      // setUsers(updatedUsers);
      refetchUsers();
      reset(data);
      //* refresh current logined in user data (user Context)
      refresh();
    } catch (error) {}
  }

  const handleCanel = useCallback(() => {
    reset(currentUser);
  }, [currentUser, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3} rowSpacing={4} id="muiForm" sx={{ padding: '.5rem 3rem' }}>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled
            label="Name"
            variant="outlined"
            fullWidth
            {...register('displayName', { required: true })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="location">Location</InputLabel>
            <Controller
              name="office"
              control={control}
              render={({ field }) => (
                <Select {...field} labelId="location" label="Location">
                  {PTLocations?.map((data) => (
                    <MenuItem key={data?.locationID} value={data?.locationID}>
                      {data?.locationName}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="email"
            control={control}
            rules={{
              required: 'This filed is required',
              pattern: {
                value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                message: 'Invalid email address',
              },
            }}
            render={({ field, error }) => (
              <TextField
                {...field}
                label="Email"
                variant="outlined"
                fullWidth
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="mobile"
            control={control}
            rules={{
              pattern: {
                value: /^\+\d (\(\d{3}\) |\d{3}-)\d{3} \d{4}$/,
                message: 'Invalid cell phone',
              },
            }}
            render={({ field }) => (
              <PatternFormat
                {...field}
                fullWidth
                type="tel"
                label="Cell Phone"
                format="+1 (###) ### ####"
                mask={'_'}
                error={Boolean(errors.mobile)}
                helperText={errors.mobile?.message}
                customInput={TextField}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
            <Checkbox control={control} name={'isPTEngineer'} label="Engineer" />
            <Checkbox control={control} name={'isCAM'} label="CAM" />
            <Checkbox control={control} name={'isAdmin'} label="Admin" />
            <Checkbox control={control} name={'isPTDetailer'} label="Detailer" />
            <Checkbox control={control} name={'isPTScheduler'} label="Scheduler" />
            <Checkbox control={control} name={'isPTProjectManager'} label="Project manager" />
            <Checkbox control={control} name={'isPTDispatcher'} label="Dispatcher" />
          </Box>
        </Grid>
        <ActionBar
          sx={{
            display: 'flex',
            width: '100%',
            gap: 2,
            justifyContent: 'end',
          }}
        >
          <ActionSubmitButton2
            loading={isSubmitting}
            buttonText={'Save'}
            isDisabled={isSubmitting || !(Object.keys(dirtyFields).length ?? false)}
          />
          <ActionCancelButton
            clickHandler={handleCanel}
            buttonText={'Cancel'}
            disabled={isSubmitting || !(Object.keys(dirtyFields).length ?? false)}
          />
        </ActionBar>
      </Grid>
    </form>
  );
}
