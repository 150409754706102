import { Helmet } from 'react-helmet-async';
import { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { JobCreationContext } from '../../../components/PT/jobCreationContext';
import JobCreation from '../../../components/PT/jobCreation';
import { useSnackBar } from '../../../context/snackBarContext';
import { useUserContext } from '../../../context/userContext';

const CreateJob = () => {
  const [locations, setLocations] = useState([]);
  const { setSnackbar } = useSnackBar();
  const { user } = useUserContext();

  const pagePermissions = {
    //canEdit: user.isPTProjectManager,
    canCreateJob: user.isCAM
  };

  useEffect(() => {
    const fetchData = async () => {
      axios
        .get('Locations?ptOnly=true&ActiveOnly=true')
        .then((response) => {
          setLocations(response?.data ?? []);
        })
        .catch((error) => {
          console.log(error);
          setSnackbar({
            open: true,
            message: 'Error in getting PT locations.',
            severity: 'error',
          });
        });
    };

    if (pagePermissions.canCreateJob) {
      fetchData();
    }
  }, [setSnackbar]);

  return (
    <JobCreationContext.Provider value={useMemo(() => ({ locations: locations }), [locations])}>
      <>
        <Helmet>
          <title>PT Job Creation</title>
        </Helmet>

        <JobCreation pagePermissions={pagePermissions} />
      </>
    </JobCreationContext.Provider>
  );
};

export default CreateJob;
