import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { DataGrid } from 'devextreme-react';
import { Column, Format } from 'devextreme-react/data-grid';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { FiChevronsRight, FiChevronsLeft } from 'react-icons/fi';
import { RiDeleteBin6Line } from 'react-icons/ri';
import {
  useAccessories,
  useBidItems,
  useCableDiameter,
  useExcuseCodes,
  useMillthickness,
} from '../../../utils/masterData';
import { useQuery } from '@tanstack/react-query';
import ActionBar from '../../shared/actionBar';
import { ActionSubmitButton2 } from '../../shared/actionSubmitButton';
import ActionCancelButton from '../../shared/actionCancelButton';
import { useSnackBar } from '../../../context/snackBarContext';
import { CUT_STAUTS } from '../../../common/constants';

export default function CutsData(props) {
  const {
    cutsData: importedCutsData,
    setCutsData: setImportedCutsData,
    isFormDirty,
    setIsFormDirty,
    currentIndex,
    setCurrentIndex,
    pagePermissions,
  } = props;
  const { jobId } = useParams();
  const cutsViewGrid = useRef();
  const [searchParams] = useSearchParams();
  const mode = searchParams.get('mode');
  const loadId = searchParams.get('loadId');
  const [openPopup, setOpenPopup] = useState(false);
  const navigate = useNavigate();
  const { setSnackbar } = useSnackBar();

  //* edit or import
  // const mode = searchParams.get('mode');
  //* a cut to show when page loads
  // const loadId = searchParams.get('loadId');
  const [cutLineItems, setCutLineItems] = useState([]);
  //for readonly field in form.
  const [bidItemDesc, setBidItemDesc] = useState('');
  const [cuts, setCuts] = useState(() => importedCutsData ?? []);
  //* handle the form cut fields state

  //* get job info,  lookupdata,
  const { data: jobData, isLoading: jobLoading } = useQuery({
    queryKey: ['job', jobId],
    queryFn: async () => {
      const response = await axios.get(`Jobs/${jobId}`, { loaderRequired: false });
      return response.data;
    },
  });
  const { data: excuseCodesOptions } = useExcuseCodes();
  const { data: biditemsOptions } = useBidItems();
  const { data: accessoriesKitOptions } = useAccessories();
  const { data: millThicknessOptions } = useMillthickness();
  const { data: cableDiameterOptions } = useCableDiameter();

  // set the cut's - Mill thickness, Cable diameter and accessories kit value when job data fetched
  useEffect(() => {
    if (jobData?.value) {
      // check for each cut-data, if respective field already have value, if yes skip it.
      const updateCutsData = importedCutsData?.map((cut) => {
        if (cut?.accessoryKit === 0 && jobData.value.accessoryKit) {
          cut.accessoryKit = jobData.value.accessoryKit;
        }
        if (cut?.cableDiameter === 0 && jobData.value.cableDiameter) {
          cut.cableDiameter = jobData.value.cableDiameter;
        }
        if (cut?.millThickness === 0 && jobData.value.millThickness) {
          cut.millThickness = jobData.value.millThickness;
        }
        return cut;
      });
      setCuts(updateCutsData);
    }
  }, [importedCutsData, jobData, setCuts]); //* (cutsData) object use for avoiding rerendering.

  //* set cut line items grid data when next - prev button click.
  useEffect(() => {
    if (mode.toLowerCase() === 'import') setCutLineItems(() => cuts[currentIndex]?.cutLineItems);
  }, [currentIndex, cuts, mode]);

  //* handle states when currentIndex value changes (left-right)
  //* when left-right navigate call api to get cutline items data for cut in view (ONYLY in EDIT MODE)
  useEffect(() => {
    //* set the bid item description field
    const bidId = cuts[currentIndex]?.bidItemId;
    const description = biditemsOptions?.find((item) => item.id === bidId)?.description;
    setBidItemDesc(description);
  }, [currentIndex, biditemsOptions, importedCutsData, jobId, loadId, mode, cuts]); //* cutsData for getting index

  //* handling the grid state when currentIndex(left, right -> clicked), updating the grid values (cut-rails).
  //* only if in mode is edit
  useEffect(() => {
    (async () => {
      if (mode.toLowerCase() === 'edit') {
        const cutId = importedCutsData.find((cut, index) => index === currentIndex)?.cutId;
        try {
          const res = await axios.get(`jobs/${jobId}/loads/${loadId}/cuts/${cutId}`); //* keeping loader enabled here.

          setCutLineItems(res.data?.value?.cutLineItems);
        } catch (error) {}
      }
    })();
  }, [mode, importedCutsData, currentIndex, jobId, loadId]);

  //* row count generate
  function rowCountGenerate(data) {
    return <span>{data.rowIndex + 1}</span>;
  }

  function handleLeftArrow() {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      if (mode.toLowerCase() !== 'edit') setCutLineItems(() => cuts[currentIndex - 1]?.cutLineItems);
    }
  }

  function handleRightArrow() {
    if (currentIndex < cuts.length - 1) {
      setCurrentIndex(currentIndex + 1);
      if (mode.toLowerCase() !== 'edit') setCutLineItems(() => cuts[currentIndex + 1]?.cutLineItems);
    }
  }

  function handleDelete() {
    const updateCuts = cuts.filter((cut, index) => index !== currentIndex);
    if (!updateCuts?.length) setImportedCutsData([]); // if no more cuts update imported cuts data length.
    setCuts(updateCuts);
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      if (mode.toLowerCase() !== 'edit') setCutLineItems(() => cuts[currentIndex - 1]?.cutLineItems);
    }
  }

  function handleExCodeChange(e) {
    const updateCutsData = [...cuts];
    updateCutsData[currentIndex].exCode = e.target.value;
    setCuts([...updateCutsData]);

    if (!isFormDirty) setIsFormDirty(true);
  }

  function handleAccessoriesChange(e) {
    const updateCutsData = [...cuts];
    updateCutsData[currentIndex].accessoryKit = e.target.value;
    setCuts([...updateCutsData]);

    if (!isFormDirty) setIsFormDirty(true);
  }

  // need to handle the bid description state change also.
  function handleBidChange(e) {
    const updateCutsData = [...cuts];
    updateCutsData[currentIndex].bidItemId = e.target.value;
    setCuts([...updateCutsData]);
    // description
    setBidItemDesc(biditemsOptions.find((item) => item.id === e.target.value)?.description);

    if (!isFormDirty) setIsFormDirty(true);
  }

  function handleCableDiameterChange(e) {
    const updateCutsData = [...cuts];
    updateCutsData[currentIndex].cableDiameter = e.target.value;
    setCuts([...updateCutsData]);

    if (!isFormDirty) setIsFormDirty(true);
  }

  function handleMillThicknessChange(e) {
    const updateCutsData = [...cuts];
    updateCutsData[currentIndex].millThickness = e.target.value;
    setCuts([...updateCutsData]);

    if (!isFormDirty) setIsFormDirty(true);
  }

  //* save cuts on save button click.
  async function handleSaveButton() {
    //* check if all for all cuts BidItem, millThickness, accdessories and cable-diameter are selected
    let i = 0;
    for (let cut of cuts) {
      //* if null||undefined||0
      if (!cut?.bidItemId || !cut?.accessoryKit || !cut?.cableDiameter || !cut.millThickness) {
        //* set count goto that particular cut.
        // console.log(cut);
        setCurrentIndex(i);
        setSnackbar({
          open: true,
          message: 'Bid, Mill Thickness, Accessories kit and Cable diameter fields are required for all cuts.',
          severity: 'error',
        });
        return;
      }
      i += 1;
    }
    const payload = { cuts, type: 1 };

    // refactor to await syntax, instead of callback.
    axios
      .put(`jobs/${jobId}/loads/${loadId}/cuts`, payload)
      .then((res) => {
        setIsFormDirty(false);
        setSnackbar({
          open: true,
          message: `Saved successfully`,
          severity: 'success',
        });
        //* sNavigate at previous page
        navigate(-1);
      })
      .catch((e) => {
        console.log(e);
        setSnackbar({
          open: true,
          message: `Error saving.`,
          severity: 'error',
        });
      });
  }

  // form fields disable check
  const isFormDisabled = useMemo(() => {
    return (
      !pagePermissions.canEdit ||
      (mode.toLowerCase() === 'edit' && cuts[currentIndex]?.statusId >= CUT_STAUTS.releaseToShop)
    );
  }, [pagePermissions, mode, cuts, currentIndex, CUT_STAUTS]);

  return (
    <Box sx={{ position: 'relative' }}>
      <Grid container columnSpacing={5} rowSpacing={3} sx={{ margin: '0 auto', width: '75%' }}>
        {/* <Grid item xs={12} sx={{ textAlign: 'end' }}></Grid> */}
        <Grid item xs={12} sm={6}>
          <strong>Cut #:</strong> {cuts[currentIndex]?.cutNo}
        </Grid>
        <Grid item xs={12} sm={6}>
          <strong>Description:</strong> {cuts[currentIndex]?.description}
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl size="medium" fullWidth>
            <InputLabel id={'excuse-code'}>Excuse Code</InputLabel>
            <MuiSelect
              labelId="excuse-code"
              id="excuse-code-select"
              value={cuts[currentIndex]?.exCode}
              label="Excuse Code"
              onChange={handleExCodeChange}
              // cut status >= date approved then edit disabled (IN EDIT MODE).
              disabled={isFormDisabled}
            >
              {excuseCodesOptions?.value?.map((code) => (
                <MenuItem key={code.exCode} value={code.exCode}>
                  {code.description}
                </MenuItem>
              ))}
            </MuiSelect>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          {jobLoading ? (
            <Skeleton width={'100%'} height={'100%'} />
          ) : (
            <FormControl size="medium" fullWidth>
              <InputLabel id={'accessories'}>Accessories Kit</InputLabel>
              <MuiSelect
                labelId="accessories"
                id="accessories-select"
                disabled={isFormDisabled}
                // if no value, value set to job default.
                value={
                  cuts[currentIndex]?.accessoryKit === 0
                    ? jobData.value?.accessoryKit
                    : cuts[currentIndex]?.accessoryKit
                }
                label="Accessories Kit"
                onChange={handleAccessoriesChange}
              >
                {accessoriesKitOptions?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.description}
                  </MenuItem>
                ))}
              </MuiSelect>
            </FormControl>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl size="medium" fullWidth>
            <InputLabel id={'bid'}> Bid</InputLabel>
            <MuiSelect
              labelId="bid"
              id="bid-select"
              value={cuts[currentIndex]?.bidItemId}
              label="Bid"
              onChange={handleBidChange}
              // disable edit
              disabled={mode.toLowerCase() === 'edit'}
            >
              {biditemsOptions
                ?.filter((status) => [1, 2].includes(status.id))
                .map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.description}
                  </MenuItem>
                ))}
            </MuiSelect>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          {jobLoading ? (
            <Skeleton width={'100%'} height={'100%'} />
          ) : (
            <FormControl size="medium" fullWidth>
              <InputLabel id={'cableDiameter'}>Cable Diameter</InputLabel>
              <MuiSelect
                labelId="cableDiameter"
                id="cableDiameter-select"
                // if no value, value set to job default.
                value={
                  cuts[currentIndex]?.cableDiameter === 0
                    ? jobData.value?.cableDiameter
                    : cuts[currentIndex]?.cableDiameter
                }
                label="Accessories Kit"
                onChange={handleCableDiameterChange}
                disabled={isFormDisabled}
              >
                {cableDiameterOptions?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.description}
                  </MenuItem>
                ))}
              </MuiSelect>
            </FormControl>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl size="medium" fullWidth>
            <TextField
              id="bid-desc-text"
              label={'Bid Item Desc'}
              variant="outlined"
              value={bidItemDesc ?? ''}
              disabled
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          {jobLoading ? (
            <Skeleton width={'100%'} height={'100%'} />
          ) : (
            <FormControl size="medium" fullWidth>
              <InputLabel id={'millThickness'}>Mill Thickness</InputLabel>
              <MuiSelect
                labelId="millThickness"
                id="millThickness-select"
                // if no value, value set to job default.
                value={
                  cuts[currentIndex]?.millThickness === 0
                    ? jobData.value?.millThickness
                    : cuts[currentIndex]?.millThickness
                }
                label="Accessories Kit"
                onChange={handleMillThicknessChange}
                disabled={isFormDisabled}
              >
                {millThicknessOptions?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.description}
                  </MenuItem>
                ))}
              </MuiSelect>
            </FormControl>
          )}
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2, alignItems: 'end' }}>
            <Box paddingBottom={4}>
              <FiChevronsLeft
                cursor={currentIndex > 0 ? 'pointer' : 'default'}
                size={25}
                color={currentIndex > 0 ? '#3862ae' : '#B9CAE9'}
                onClick={handleLeftArrow}
              />
            </Box>
            <DataGrid ref={cutsViewGrid} width={800} height={230} dataSource={cutLineItems} showBorders={true}>
              <Column caption="Line" alignment="center" cellRender={rowCountGenerate} />
              <Column dataField={'cableColor'} caption={'CT'} />
              <Column dataField={'quantity'} caption={'Qty'}>
                <Format type="fixedPoint" />
              </Column>
              <Column dataField={'length'} caption={'Length'}>
                <Format type="fixedPoint" />
              </Column>
              <Column dataField={'lf'} caption={'LF'}>
                <Format type="fixedPoint" />
              </Column>
              <Column dataField={'fabricationType'} caption={'Type'} />
            </DataGrid>
            <Box sx={{ display: 'flex', gap: 2, pb: 4 }}>
              <FiChevronsRight
                onClick={handleRightArrow}
                cursor={currentIndex < cuts.length - 1 ? 'pointer' : 'default'}
                size={25}
                color={currentIndex < cuts.length - 1 ? '#3862ae' : '#B9CAE9'}
              />
              {mode.toLowerCase() === 'import' ? (
                <RiDeleteBin6Line onClick={handleDelete} cursor={'pointer'} size={25} />
              ) : null}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <ActionBar
        sx={{
          justifyContent: 'end',
          display: 'flex',
          gap: '10px',
        }}
      >
        <ActionSubmitButton2
          isDisabled={!pagePermissions.canEdit}
          buttonText={'Save'}
          disabled={!isFormDirty}
          onClick={handleSaveButton}
        />
        <ActionCancelButton
          buttonText={'cancel'}
          clickHandler={() => {
            if (mode !== 'edit') setOpenPopup(true);
            else navigate(-1);
          }}
        />
      </ActionBar>
      <Dialog
        open={openPopup}
        onClose={() => setOpenPopup(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Typography variant="h6">
            This will cancel the import discarding all the changes and take you back to the load screen. Do you want to
            continue?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => navigate(-1)} autoFocus>
            Yes
          </Button>
          <Button variant="contained" color="error" onClick={() => setOpenPopup(false)}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
