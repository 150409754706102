import { Button, Box } from '@mui/material';
import { useCallback, useRef, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import {
  Column,
  CustomRule,
  DataGrid,
  Editing,
  HeaderFilter,
  Lookup,
  MasterDetail,
  RequiredRule,
  Scrolling,
} from 'devextreme-react/data-grid';
import ActionBar from '../../shared/actionBar';
import { ActionSubmitButton2 } from '../../shared/actionSubmitButton';
import ActionCancelButton from '../../shared/actionCancelButton';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import KitPartsForm from './kitPartsForm';
import { useSnackBar } from '../../../context/snackBarContext';
import { queryClient } from '../../../App';
import GridSkeleton from '../../shared/gridSkeleton';

export default function KitConfigForm() {
  const {
    data: kitConfig,
    refetch,
    error,
    isLoading,
  } = useQuery({
    queryKey: ['pt/kitconfig'],
    queryFn: async () => (await axios.get('pt/kits', { loaderRequired: false })).data,
    refetchOnMount: 'always',
  });

  const { data: anchorTypesLookup } = useQuery({
    queryKey: ['pt/anchortypes'],
    queryFn: async () => (await axios.get('pt/anchortypes', { loaderRequired: false })).data,
  });

  const { data: cableDiameterLookup } = useQuery({
    queryKey: ['pt/cableDiameter'],
    queryFn: async () => (await axios.get('pt/cableDiameter', { loaderRequired: false })).data,
    refetchIntervalInBackground: false,
  });

  const { data: accessoriesLookup } = useQuery({
    queryKey: ['common/accessories'],
    queryFn: async () => (await axios.get('common/accessories', { loaderRequired: false })).data,
    refetchIntervalInBackground: false,
  });

  const updateKitConfig = useMutation({
    mutationFn: (kits) => axios.put('pt/kits', { kits }, { loaderRequired: false }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['pt/kits'] }),
  });

  const { setSnackbar } = useSnackBar();

  const kitConfigDataGridRef = useRef();

  const [hasEditData, setHasEditData] = useState(false);

  const handleAddRowButton = useCallback(() => {
    kitConfigDataGridRef.current?.instance.addRow();
  }, [kitConfigDataGridRef]);

  const handleOnToolbarPreparing = useCallback((e) => {
    e.toolbarOptions.items.splice(0);
  }, []);

  const onInitNewRow = useCallback((e) => {
    e.data.isActive = true;
  }, []);

  const renderKitPartsForm = useCallback((childProps) => {
    return <KitPartsForm {...childProps.data} />;
  }, []);

  //* Handle batch edit process request
  const processBatchRequest = useCallback(
    async (kits, component) => {
      try {
        updateKitConfig.mutate(kits);
        setSnackbar({ open: true, message: 'Saved Kit Config successfully', severity: 'success' });
        component.cancelEditData();
        await refetch();
      } catch (error) {
        setSnackbar({ open: true, message: 'Error saving Kit Config data', severity: 'error' });
      }
    },
    [refetch, setSnackbar, updateKitConfig]
  );

  const onSaving = useCallback(
    (e) => {
      e.cancel = true;

      if (e.changes.length) {
        let allRecords = kitConfigDataGridRef.current.instance.getVisibleRows();
        let payload = [];
        //* build the payload object to save parts data
        e.changes.forEach((item) => {
          if (item.type === 'insert') {
            payload.push({ ...item.data, kitId: -1 }); // can't send kitId-0, as  0 is already saved in DB.
          } else {
            //* If this is update, get other fields as well
            let row = allRecords.find((r) => r.data?.kitId === item.key);
            if (row?.data) {
              payload.push({
                ...row.data,
              });
            }
          }
        });

        //* event async saving callback method
        e.promise = processBatchRequest(payload, e.component);
      }
    },
    [processBatchRequest, kitConfigDataGridRef]
  );

  const kitNameValidation = useCallback(
    (event) => {
      //* check for unique value
      const allRecords = kitConfigDataGridRef.current.instance.getVisibleRows();
      if (allRecords?.length) {
        const uniqueValue = new Set();
        for (const row of allRecords) {
          //*check if kit Name in Set and current row value is being edited and it parent row (not having rowType = 'details')
          if (
            uniqueValue.has(row.data?.kitName?.toLowerCase().trim()) &&
            event.value.toLowerCase().trim() === row.data?.kitName?.toLowerCase().trim() &&
            row.rowType === 'data'
          ) {
            event.rule.message = 'Duplicate Kit Name found.';
            return false;
          }
          uniqueValue.add(row.data?.kitName?.toLowerCase().trim());
        }
      }
      //* 100 characters check
      if (event.value?.length > 50) {
        event.rule.message = 'Kit Name has more than 50 characters.';
        return false;
      }
      return true;
    },
    [kitConfigDataGridRef]
  );

  //* handle Accessory kit disable state.
  //* if any row is expanded disable edit.
  const onEditorPreparing = useCallback((event) => {
    //* If any row expanded disable edit.
    let isRowExpanded = false;
    const rowsData = event.component.getDataSource().items();
    rowsData.forEach((row) => {
      if (event.component.isRowExpanded(row.kitId)) {
        isRowExpanded = true;
      }
    });
    //* check if any row expanded.
    if (isRowExpanded) event.editorOptions.disabled = true;
  }, []);

  //* cancle row expand when grid in edit state.
  const onRowExpanding = useCallback(
    (e) => {
      //* check if any edit data.
      if (e.component.hasEditData()) {
        e.cancel = true;
        setSnackbar({
          open: true,
          message: 'Save pending changes to expand a row.',
          severity: 'warning',
        });
      } else e.component.collapseAll(-1);
    },
    [setSnackbar]
  );

  //* if error in getting kit config data.
  if (error) setSnackbar({ open: true, message: 'Error getting kit config data.', severity: 'error' });

  if (isLoading) return <GridSkeleton />;

  return (
    <Box marginBottom={12} width={'100%'} display={'flex'} flexDirection={'column'}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          paddingBottom: 2,
        }}
      >
        <Button onClick={handleAddRowButton} className="btn btn-blue btn-icon" startIcon={<AddIcon />}>
          Add Kit
        </Button>
      </Box>
      <Box height={'100%'} display={'flex'}>
        <DataGrid
          dataSource={kitConfig}
          ref={kitConfigDataGridRef}
          id="id"
          showBorders
          keyExpr={'kitId'}
          rowAlternationEnabled={true}
          onToolbarPreparing={handleOnToolbarPreparing}
          onInitNewRow={onInitNewRow}
          onContentReady={(e) => setHasEditData(e.component.hasEditData())}
          onSaving={onSaving}
          onEditorPreparing={onEditorPreparing}
          //* expand only one row at a time
          onRowExpanding={onRowExpanding}
        >
          <HeaderFilter visible />
          <MasterDetail enabled component={renderKitPartsForm} />

          <Editing allowAdding allowUpdating mode="batch" />
          <Scrolling mode="Standard" />

          <Column dataField="kitName" caption="Kit Name" allowFiltering={false}>
            <RequiredRule />
            <CustomRule validationCallback={kitNameValidation} />
          </Column>
          <Column dataField="cableDiameterId" caption="Diameter" allowSearch allowFiltering={true}>
            <Lookup dataSource={cableDiameterLookup} valueExpr={'id'} displayExpr={'description'} />s
            <RequiredRule />
          </Column>
          <Column dataField="anchorType" caption="Anchor Type">
            <Lookup dataSource={anchorTypesLookup} displayExpr={'name'} valueExpr={'type'} />
            <RequiredRule />
          </Column>
          <Column dataField="accessoryKit" caption="Accessory Type">
            <Lookup dataSource={accessoriesLookup} displayExpr={'description'} valueExpr={'id'} />
            <RequiredRule />
          </Column>
          <Column dataField="isActive" caption="Active" dataType="boolean" />
        </DataGrid>
      </Box>
      <ActionBar
        sx={{
          justifyContent: 'end',
          display: 'flex',
          gap: '10px',
        }}
      >
        <ActionSubmitButton2
          buttonText={'Save'}
          onClick={() => kitConfigDataGridRef.current.instance.saveEditData()}
          disabled={!hasEditData || updateKitConfig.isPending}
          loading={updateKitConfig.isPending}
        />
        <ActionCancelButton
          buttonText={'cancel'}
          disabled={!hasEditData}
          clickHandler={() => kitConfigDataGridRef.current.instance.cancelEditData()}
        />
      </ActionBar>
    </Box>
  );
}
