import { Autocomplete, FormControl, TextField } from "@mui/material";
import { JobCreationContext } from "../PT/jobCreationContext";
import React, { useContext } from "react";
import { Controller } from "react-hook-form";
import * as ErrorMessages from "./errorMessages";

const LocationDropDown = (props) => {
  const { locations } = useContext(JobCreationContext);
  const { control, errors, width, required } = props;

  return (
    <FormControl sx={{ mx: 2, minWidth: width }} size="medium">
      <Controller
        name="location"
        rules={
          required
            ? { required: ErrorMessages.Error_RequiredField }
            : { required: false }
        }
        control={control}
        render={({ field }) => {
          const { onChange } = field;
          return (
            <Autocomplete
              disabled={props.disabled ?? false}
              onChange={(event, data) => {
                onChange(data?.locationID);
                props.changeHandler(data);
              }}
              id="location"
              options={locations}
              getOptionLabel={(option) => option.locationName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Location"
                  error={Boolean(errors.location)}
                  helperText={errors.location?.message}
                />
              )}
            />
          );
        }}
      />
    </FormControl>
  );
};

export default LocationDropDown;

export const LocationDropDown2 = React.forwardRef(
  ({ control, errors, width, required, changeHandler, ...props }, ref) => {
    const { locations } = useContext(JobCreationContext);

    return (
      <FormControl ref={ref} {...props} size="medium">
        <Controller
          name="location"
          rules={
            required
              ? { required: ErrorMessages.Error_RequiredField }
              : { required: false }
          }
          control={control}
          render={({ field }) => {
            const { onChange } = field;
            return (
              <Autocomplete
                disabled={props.disabled ?? false}
                onChange={(event, data) => {
                  onChange(data?.locationID);
                  changeHandler(data);
                }}
                id="location"
                options={locations}
                getOptionLabel={(option) => option.locationName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Location"
                    error={Boolean(errors.location)}
                    helperText={errors.location?.message}
                  />
                )}
              />
            );
          }}
        />
      </FormControl>
    );
  }
);
