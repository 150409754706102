import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const ConfirmDialog = ({ open, onClose, onConfirm, title }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogContent>
      <Typography variant="h6">{title}</Typography>
      <Typography variant="subtitle2">&nbsp;</Typography>
    </DialogContent>
    <DialogActions>
      <Button
        color="primary"
        onClick={onConfirm}
        sx={{ px: 4, border: 'solid 1px', width: '100px' }}
      >
        Yes
      </Button>
      <Button
        color="error"
        onClick={onClose}
        sx={{ px: 4, border: 'solid 1px' }}
      >
        No
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmDialog;
