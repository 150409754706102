import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useAppState } from "../../context/appContext";

export default function Spinner({ show }) {
  const { loading } = useAppState();

  if (!loading) {
    return;
  }

  return (
    <div>
      <Backdrop
        sx={{ color: "#052668", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
