export const JOB_STATUS = {
  U: 'UnKnown',
  C: 'Complete',
  D: 'Deleted',
  E: 'Estimated',
  F: 'Final',
  I: 'Inactive',
  L: 'Lost',
  O: 'Open',
  N: 'Negotiating',
};

export const PT_CATEGORY = {
  barrierCable: 'Barrier Cable',
  cable: 'Cable',
  ptHardware: 'PT Hardware',
  accessories: 'Accessories',
  studRails: 'Stud Rails',
};

export const CUT_STAUTS = {
  draft: 1,
  releaseToShop: 2,
  dateApproved: 3,
  taggedInProcess: 4,
  sentToTMW: 5,
  WIP: 6,
  shipped: 7,
};

export const PRODUCTION_STATUS = CUT_STAUTS;

export const REPORT_NAMES = {
  loadSummary: 'Load Summary',
  looseComponents: 'Loose Components',
  fabOrder: 'Fab Order',
  tag: 'Tag',
  studrailPaperwork: 'Studrail Paperwork',
  looseComponentsDetailed: 'Loose Components Detailed',
  ptiPaperwork: 'PTI Paperwork',
  barrierCableFabOrder: 'Barrier Cable Fab Order Report',
};

export const LOAD_TYPE = {
  cable: 1,
  studRails: 2,
};

export const AREA_NAMES = {
  pt: 'PT',
  rebar: 'Rebar',
};

export const BID_ITEMS = {
  ptCable: 1,
  barrierCable: 2,
  studRails: 3,
  ptAccessories: 4,
  equipment: 5,
  engineering: 6,
  restoration: 7,
  stressing: 8,
  labor: 9,
};
