import { Outlet, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import Home from './pages/Home/index';
import Login from './pages/Login/index';
import PTHome from './pages/PT';
import PTLayout from './pages/PT/ptLayout';
import * as Paths from './routes';
import AdminIndex from './pages/PT/Admin';
import CreateJob from './pages/PT/Job/createJob';
import Layout from './pages/Layout/layout';
import JobsLayout from './pages/PT/Job/jobsLayout';
import MainInfo from './pages/PT/Job/mainInfo';
import Estimates from './pages/PT/Job/estimates';
import RedirectMainInfo from './components/PT/MainInfo/redirectMainInfo';
import PTQuantites from './pages/PT/Cable/ptQuantities';
import CutImport from './pages/PT/Cable/cutImport';
import PartClass from './pages/PT/Admin/partClass';
import Parts from './pages/PT/Admin/parts';
import FabConfig from './pages/PT/Admin/fabConfig';
import Unauthorized from './pages/unauthorized';
import KitConfig from './pages/PT/Admin/kitConfig';
import Hardwares from './pages/PT/Cable/hardwares';
import Schedules from './pages/PT/Schedule/schedules';
import ShopPaperWork from './pages/PT/Schedule/shopPaperWork';
import RedirectSchedules from './components/PT/Schedules/redirectSchedules';
import Contacts from './pages/PT/Job/contacts';
import AccessoriesComponents from './pages/PT/Cable/accessories';
import RedirectQuantites from './components/PT/PTQuantities/redirectPTQuantites';
import ScheduleLayout from './components/PT/Schedules/schedulesLayout';
import ImportFabJob from './pages/PT/Job/importFabJob';
import Authorize, { loader as userLoader } from './auth/authorize';
import FabJobImportRedirect from './components/Redirects/fabJobImportRedirect';
import NotFound from './pages/notfound';
import Authenticate from './auth/authentication';
import StudRailQuantities from './pages/PT/StudRail/studRailQuantities';
import ErrorBoundary from './components/shared/errorBoundary';
import CISUploadDocuments from './pages/PT/Job/cisUploadDocuments';
import LinkDrawings from './components/PT/PTQuantities/linkDrawings';
import PTIFiling from './pages/PT/Cable/ptiFiling';
import BCAdditionalItems from './pages/PT/Cable/bcAdditionalItems'; 
import StudRailQuantitiesAccessories from './pages/PT/StudRail/studRailQuantitiesChairs';


export default function Router({ queryClient }) {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path={Paths.ROUTE_DEFAULT} element={<Authenticate />}>
          <Route loader={() => userLoader(queryClient)} element={<Authorize />} errorElement={<ErrorBoundary />}>
            <Route element={<Layout />}>
              <Route path={Paths.ROUTE_EMPTY} element={<Home />} />
              <Route path={Paths.ROUTE_HOME} element={<Home />} />
              <Route path={Paths.ROUTE_NEW_PT_JOB} element={<CreateJob />} />

              <Route path={Paths.ROUTE_NEW_FAB_JOB} element={<ImportFabJob />} />
              <Route path={Paths.ROUTE_PT} element={<PTHome />} />
              <Route path={Paths.ROUTE_PT_ADMIN} element={<PTHome />} />
              <Route path={Paths.ROUTE_PT_SCHEDULES} element={<ScheduleLayout />}>
                <Route index element={<Schedules />} />
                <Route path={Paths.ROUTE_PT_SHOP_PAPER_WORK} element={<ShopPaperWork />} />
                <Route path={Paths.ROUTE_PT_SCHEDULES_REDIRECT_JOB} element={<RedirectSchedules />} />
                <Route path={Paths.ROUTE_PT_SCHEDULES_REDIRECT_LOAD} element={<RedirectSchedules />} />
              </Route>
            </Route>
            <Route path={Paths.ROUTE_FAB_JOBS} element={<JobsLayout />}>
              <Route index element={<FabJobImportRedirect />} />
              <Route path={Paths.ROUTE_FAB_JOBINFO} element={<MainInfo />} />
              <Route path={Paths.ROUTE_FAB_JOBS_RDIRECT} element={<FabJobImportRedirect />} />
            </Route>
            <Route path={Paths.ROUTE_JOBS} element={<JobsLayout />}>
              <Route path={Paths.ROUTE_EMPTY} element={<PTHome />} />
              <Route path={Paths.ROUTE_JOBINFO}>
                <Route index path="" element={<MainInfo />} />
                <Route path={Paths.ROUTE_CIS_DOCUMENTS_UPLOAD} element={<CISUploadDocuments />} />
              </Route>
              <Route path={Paths.ROUTE_JOBINF_REDIRECT} element={<RedirectMainInfo />} />
              <Route path={Paths.ROUTE_JOBS_ESTIMATES} element={<Estimates />} />
              <Route path={Paths.ROUTE_JOBS_CONTACTS} element={<Contacts />} />
              <Route path={Paths.ROUTE_JOBS_PTQUANTITIES} element={<Outlet />}>
                <Route index path="" element={<PTQuantites />} />
                <Route path={Paths.ROUTE_LOADS_CUT_IMPORT} element={<CutImport />} />
                <Route path={Paths.ROUTE_LOAD_HARDWARES} element={<Hardwares />} />
                <Route path={Paths.ROUTE_LOAD_IMPORT_ACCESSORIES} element={<AccessoriesComponents />} />
                <Route path={Paths.ROUTE_LOAD_IMPORT_BARRIER_CABLE_STEEL_POST} element={<AccessoriesComponents />} />
                <Route path={Paths.ROUTE_LOAD_Link_Drawings} element={<LinkDrawings />} />
                <Route path={Paths.ROUTE_LOAD_PTI_Filing} element={<PTIFiling />} />
                <Route path={Paths.ROUTE_LOAD_BC_Additional_Components} element={<BCAdditionalItems />} />

                {/* // redirect routes for load and cuts  */}
                <Route path={Paths.ROUTE_LOAD_QUANTITES_REDIRECT} element={<RedirectQuantites />} />
                <Route path={Paths.ROUTE_CUTS_QUANTITES_REDIRECT} element={<RedirectQuantites />} />
              </Route>
              <Route path={Paths.ROUTE_JOBS_STUD_RAIL_QUANTITIES}>
                <Route index element={<StudRailQuantities />} />
                <Route path={Paths.ROUTE_STUD_RAIL_CUT_IMPORT} element={<CutImport />} />
                <Route path={Paths.ROUTE_LOAD_IMPORT_STUDRAIL_CHAIRS} element={<StudRailQuantitiesAccessories/>} />


                {/* // redirect routes for load and cuts  */}
                <Route path={Paths.ROUTE_LOAD_QUANTITES_REDIRECT} element={<RedirectQuantites />} />
                <Route path={Paths.ROUTE_CUTS_QUANTITES_REDIRECT} element={<RedirectQuantites />} />
              </Route>
            </Route>
            <Route path={Paths.ROUTE_PT_ADMIN} element={<PTLayout />}>
              <Route index element={<AdminIndex />} />
              <Route path={Paths.ROUTE_PT_ADMIN_PARTCLASS} element={<PartClass />} />
              <Route path={Paths.ROUTE_PT_ADMIN_PART} element={<Parts />} />
              <Route path={Paths.ROUTE_PT_ADMIN_FABCONFIG} element={<FabConfig />} />
              <Route path={Paths.ROUTE_PT_ADMIN_KITCONFIG} element={<KitConfig />} />
            </Route>
          </Route>
          <Route path={Paths.ROUTE_UNAUTHORIZED_USER} element={<Unauthorized />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path={Paths.ROUTE_LOGIN} element={<Login />} />
      </>
    )
  );

  return <RouterProvider router={router} />;
}
