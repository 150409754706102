import { Controller } from 'react-hook-form';
import { FormControl, TextField } from '@mui/material';
import { NumericFormat } from 'react-number-format';

export default function InputNum({ control, isDisabled, label, name, onBlurHandler }) {
  return (
    <FormControl size="medium" fullWidth>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <NumericFormat
            {...field}
            customInput={TextField}
            disabled={isDisabled}
            thousandSeparator=","
            value={field.value ? field.value : ''}
            label={label}
            onBlur={onBlurHandler}
          />
        )}
      />
    </FormControl>
  );
}
