import moment from 'moment/moment';

const convertToGMT = (originalDate) => {
  
  const clonedDate = moment(originalDate);
   const currentDate = clonedDate.set({hour:0,minute:0})

  // Add 12 hours
  const modifiedDateInLocalTime = currentDate.add(12, 'hours');

  // Convert to GMT
  const modifiedDateInGMT = modifiedDateInLocalTime.utc().format();

  return modifiedDateInGMT;
};

export { convertToGMT };
