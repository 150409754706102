import { Outlet } from 'react-router-dom';
import { useUserContext } from '../context/userContext';
import PageLoader from '../components/shared/pageLoader';
import { userDetailQuery } from '../utils/masterData';
import { msalInstance } from './userAccount';
import { loginRequest } from './config/authConfig';
import axios from 'axios';
import TagManager from 'react-gtm-module';

export async function loader(queryClient) {
  const query = userDetailQuery;
  const account = msalInstance.getAllAccounts()[0];
  const msalResponse = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account,
  });

  const token = msalResponse.accessToken;
  return (
    queryClient.getQueryData(query.queryKey) ??
    queryClient.fetchQuery({
      queryKey: ['user'],
      queryFn: async () => {
        const response = await axios.get('users/me', {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });

        // Adding userID to the google tag data layer.
        const userId = response?.data?.userId;
        if (userId) TagManager.dataLayer({dataLayer: { userId: userId}}); 

        return response.data;
      },
      refetchInterval: 1000 * 60 * 10, // 10 mins.
      retry: false,
    })
  );
}

export default function Authorize() {
  const { loading: userLoading } = useUserContext();

  if (userLoading) return <PageLoader />;
  // returns user
  return <Outlet />;
}
