import { CSVLink } from 'react-csv';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

export default function ExportCSV({ data }) {
  if (!data) return;

  return (
    <CSVLink data={data} filename={'myJobs.csv'} style={{ display: 'flex' }} title="Export to CSV">
      <FileDownloadIcon sx={{ color: '#fff' }} />
    </CSVLink>
  );
}
