import { useCallback, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useSnackBar } from '../../../context/snackBarContext';
import {
  Column,
  CustomRule,
  DataGrid,
  Editing,
  HeaderFilter,
  Lookup,
  PatternRule,
  RequiredRule,
  Scrolling,
  SearchPanel,
} from 'devextreme-react/data-grid';
import { Button, Grid, InputAdornment, TextField } from '@mui/material';
import { Box } from '@mui/system';
import ActionBar from '../../shared/actionBar';
import { ActionSubmitButton2 } from '../../shared/actionSubmitButton';
import ActionCancelButton from '../../shared/actionCancelButton';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import AddIcon from '@mui/icons-material/Add';
import GridSkeleton from '../../shared/gridSkeleton';
import { useStudDiameter } from '../../../utils/masterData';
import { PT_CATEGORY } from '../../../common/constants';

export default function PartsForm() {
  const [isSaving, setIsSaving] = useState(false);

  const {
    data: parts,
    error: errorParts,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ['pt/parts'],
    queryFn: async () => {
      const response = await axios.get('pt/parts', { loaderRequired: false });
      return response.data;
    },
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    staleTime: 0,
  });
  const { data: activePartClassLookup } = useQuery({
    queryKey: ['pt/activePartClass'],
    queryFn: async () => {
      const response = await axios.get(`pt/partClass?type=${true}`);
      return response.data;
    },
  });
  const { data: categoriesLookup } = useQuery({
    queryKey: ['pt/categories'],
    queryFn: async () => {
      const response = await axios.get('pt/categories');
      return response.data;
    },
  });
  const { data: millThicknessLookup } = useQuery({
    queryKey: ['pt/millThickness'],
    queryFn: async () => {
      const response = await axios.get('pt/millthickness');
      return response.data;
    },
  });

  const { data: cableDiameterLookup } = useQuery({
    queryKey: ['pt/cableDiameter'],
    queryFn: async () => {
      const response = await axios.get('pt/cableDiameter');
      return response.data;
    },
  });

  const { setSnackbar } = useSnackBar();

  const partsDataGridRef = useRef(null);

  const [hasEditData, setHasEditData] = useState(false);
  const { data: studDiameterLookupData } = useStudDiameter();

  //* get the category name from active part class lookup data and CategoriesLookup data.
  const getPTCategoryName = useCallback(
    (Id) => {
      const categoryId = activePartClassLookup?.find((item) => item.partClassId === Id)?.categoryId;

      //* get the category name
      const categoryName = categoriesLookup?.find((item) => item.id === categoryId)?.name;
      return categoryName;
    },
    [activePartClassLookup, categoriesLookup]
  );

  const handleAddRowButton = useCallback(() => {
    partsDataGridRef.current.instance.addRow();
  }, []);

  //* remove all toolbar options except for Search Bar.
  const handleToolbarPreparing = useCallback((e) => {
    e.toolbarOptions.items.splice(0);
  }, []);

  const onInitRow = useCallback((e) => {
    e.data.isActive = true;
  }, []);

  //* partnumber needs to be unique validation
  const uniquePartNumberValidate = (options) => {
    //* get all data to validate
    let allRecords = partsDataGridRef.current.instance.getVisibleRows();
    if (allRecords?.length) {
      let uniquePartId = new Set();
      for (const row of allRecords) {
        //* check if partNumeber in Set and current row value is being edited.
        if (uniquePartId.has(row.data?.partNumber) && options.value === row.data?.partNumber) return false;
        uniquePartId.add(row.data?.partNumber);
      }
    }
    return true;
  };

  //* render the PT Category Column data. Ready only.
  const ptCategoryRender = useCallback(
    (eventProps) => {
      //* get the category id from active part class lookup data
      const categoryId = activePartClassLookup?.find(
        (item) => item.partClassId === eventProps.data.partClassId
      )?.categoryId;

      //* get the category name
      const categoryName = categoriesLookup?.find((item) => item.id === categoryId)?.name;

      return <span>{categoryName}</span>;
    },
    [activePartClassLookup, categoriesLookup]
  );

  //* Handle batch edit process request
  const processBatchRequest = useCallback(
    async (parts, component) => {
      try {
        setIsSaving(true);
        await axios.put('pt/parts', { parts }, { loaderRequired: false });
        setSnackbar({ open: true, message: 'Saved parts successfully', severity: 'success' });
        await refetch();
        component.cancelEditData();
      } catch (error) {
        setSnackbar({ open: true, message: 'Error saving parts data', severity: 'error' });
      } finally {
        setIsSaving(false);
      }
    },
    [refetch, setSnackbar]
  );

  //* used to build the payload object
  const handleOnSaving = useCallback(
    (e) => {
      //* disable default save behavour.
      e.cancel = true;
      if (e.changes.length) {
        let allRecords = partsDataGridRef.current.instance.getVisibleRows();
        let payload = [];

        //* build the payload object to save parts data
        e.changes.forEach((item) => {
          if (item.type === 'insert') {
            payload.push(item.data);
          } else {
            //* If this is update, get other fields as well
            let row = allRecords.find((r) => r.data?.partId === item.key);
            if (row?.data) {
              payload.push({
                ...row.data,
              });
            }
          }
        });
        e.promise = processBatchRequest(payload, e.component);
      }
    },
    [processBatchRequest]
  );

  //* diable the cableDiameterId & millThicknessId.
  const onEditorPreparing = useCallback(
    (e) => {
      // make sure partClass is selected. (has value)

      // for categoryName barrierCable, cable --> enables.
      if (e.row.data?.partClassId && (e.dataField === 'cableDiameterId' || e.dataField === 'millThicknessId')) {
        const categoryName = getPTCategoryName(e.row.data.partClassId);
        // should have cable and barrier cable.
        const isValidPTCategory = categoryName === PT_CATEGORY.cable || categoryName === PT_CATEGORY.barrierCable;
        e.editorOptions.disabled = !isValidPTCategory;
      }

      // for categoryName studRails  --> enables.
      if (e.row.data?.partClassId && (e.dataField === 'studDiameterId' || e.dataField === 'studHeight')) {
        const categoryName = getPTCategoryName(e.row.data.partClassId);
        //should have stud rails and partclass not 'FLATBAR'
        const isValidPTCategory = categoryName === PT_CATEGORY.studRails;
        e.editorOptions.disabled = !isValidPTCategory;
      }

      // for categoryName studRails && partClassName===FLATBAR --> enables.
      if (e.row.data?.partClassId && e.dataField === 'flatBarSize') {
        const categoryName = getPTCategoryName(e.row.data.partClassId);
        const isValidPTCategory = categoryName === PT_CATEGORY.studRails;
        e.editorOptions.disabled = !isValidPTCategory;
      }
    },
    [getPTCategoryName, PT_CATEGORY]
  );

  //* function to validate (Required) Cable Diameter and Millthickness
  //* based on part class selected.
  const validateCableDiameterCableMil = useCallback(
    (options) => {
      //* check if PT category is 'Cable' or 'Barrier Cable'
      const categoryName = getPTCategoryName(options.data.partClassId);
      // if not cable category.
      if (categoryName !== PT_CATEGORY.barrierCable && categoryName !== PT_CATEGORY.cable) {
        return true;
      }

      return !!(
        options.data?.partClassId &&
        (categoryName === PT_CATEGORY.barrierCable || categoryName === PT_CATEGORY.cable) &&
        options.value
      );
    },
    [getPTCategoryName, PT_CATEGORY]
  );

  const updatePartClass = useCallback(
    (newData, value, currentRowData) => {
      newData.partClassId = value;
      const partClassId = value;
      // clears cell values when part class cell value changes.
      const category = getPTCategoryName(partClassId);

      // cableDiameterId, millThicknessId --> when not any cable.
      if (category !== PT_CATEGORY.cable && category !== PT_CATEGORY.barrierCable) {
        if (currentRowData.cableDiameterId) newData.cableDiameterId = null;
        if (currentRowData.millThicknessId) newData.millThicknessId = null;
      }

      // studDiameterId, studHeight --> when not stud rail or partClassName === FlatBar
      if (category !== PT_CATEGORY.studRails) {
        if (currentRowData.studDiameterId) newData.studDiameterId = null;
        if (currentRowData.studHeight) newData.studHeight = null;
        if (currentRowData.flatBarSize) newData.flatBarSize = null;
      }
    },
    [getPTCategoryName, PT_CATEGORY]
  );

  //* save button handler
  function handleSaveButton() {
    partsDataGridRef.current.instance.saveEditData();
    setHasEditData(true);
  }

  //* Reset button handler.
  function handleCancelButton() {
    partsDataGridRef.current.instance.cancelEditData();
  }

  //* set the form dirty state
  function handleOnContentReady(e) {
    setHasEditData(e.component.hasEditData());
  }

  if (errorParts) {
    setSnackbar({
      open: true,
      message: 'Error getting Parts',
      severity: 'error',
    });
  }

  async function setMaterialID(newData, value, currentRowData) {
    let materialId = value;
    if (value === '') return;

    newData.matDescription = '';
    newData.matUOM = '';

    try {
      const response = await axios.get(`common/materials/${materialId}`);
      if (response?.data?.errors?.length === 0 && response?.data?.value) {
        newData.materialId = materialId;
        newData.matDescription = response.data.value.materialDescription;
        newData.matUOM = response.data.value.uom;
      } else if (response?.data?.errors?.length === 1) {
        newData.materialId = '';
        setSnackbar({ open: true, message: `${response?.data?.errors[0]}`, severity: 'error', isAction: true });
      } else {
        setSnackbar({ open: true, message: `Invalid SAP Material ID`, severity: 'error', isAction: true });
      }
      return;
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Error occured while retrieving material information.`,
        severity: 'error',
        isAction: true,
      });
    }
  }

  return (
    <Grid item xs={12} sx={{ mb: 8 }}>
      {isLoading ? (
        <GridSkeleton />
      ) : (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Button onClick={handleAddRowButton} className="btn btn-blue btn-icon" startIcon={<AddIcon />}>
              Add Row
            </Button>
            <TextField
              id="search-grid"
              variant="filled"
              placeholder="Search..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchSharpIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                partsDataGridRef.current.instance.searchByText(e.target.value);
              }}
            />
          </Box>
          <DataGrid
            ref={partsDataGridRef}
            keyExpr={'partId'}
            dataSource={parts}
            onToolbarPreparing={handleToolbarPreparing}
            id="partsGrid"
            height={440}
            showBorders={true}
            rowAlternationEnabled={true}
            onContentReady={handleOnContentReady}
            onSaving={handleOnSaving}
            onInitNewRow={onInitRow}
            onEditorPreparing={onEditorPreparing}
            allowColumnResizing={true}
            allowColumnReordering={true}
            columnResizingMode="widget"
          >
            <HeaderFilter visible />
            <SearchPanel visible={true} placeholder="Search..." />
            <Editing allowAdding allowUpdating mode="batch" />
            <Scrolling mode="infinite" />

            <Column
              dataField="partClassId"
              allowSearch
              allowFiltering={true}
              caption="Part Class"
              setCellValue={updatePartClass}
            >
              <Lookup dataSource={activePartClassLookup} valueExpr={'partClassId'} displayExpr={'name'} />
              <RequiredRule />
            </Column>
            <Column dataField="partNumber" allowSearch allowFiltering={false} caption="Part ID">
              <RequiredRule />
              <PatternRule
                pattern={/^[a-zA-Z0-9_-]+$/}
                message="Only alpha-numeric values without spaces and no special values except underscores and hyphens are allowed."
              />
              <CustomRule validationCallback={uniquePartNumberValidate} message="This value must be unique." />
            </Column>
            <Column
              allowSearch
              allowFiltering={false}
              caption="PT Category"
              cellRender={ptCategoryRender}
              allowResizing={true}
            />
            <Column
              dataField="manufactureId"
              allowSearch
              allowFiltering={false}
              caption="Manufacture ID"
              editorOptions={{ maxLength: 10 }}
            >
              <PatternRule
                pattern={/^[a-zA-Z0-9_-]+$/}
                message="Only alpha-numeric values without spaces and no special values except underscores and hyphens are allowed."
              />
            </Column>
            <Column
              dataField="cableDiameterId"
              dataType="string"
              allowSearch
              allowFiltering={true}
              caption="Cable Diameter"
            >
              <Lookup dataSource={cableDiameterLookup} valueExpr={'id'} displayExpr={'description'} />
              <CustomRule message="Cable Diameter is required." validationCallback={validateCableDiameterCableMil} />
            </Column>
            <Column
              dataType="string"
              dataField="millThicknessId"
              allowSearch
              allowFiltering={true}
              caption="Cable Mil"
              alignment="left"
            >
              <Lookup dataSource={millThicknessLookup} valueExpr={'id'} displayExpr={'description'} />
              <CustomRule message="Cable Mil is required." validationCallback={validateCableDiameterCableMil} />
            </Column>
            <Column
              dataType="string"
              dataField="studDiameterId"
              allowSearch
              allowFiltering={true}
              caption="Stud Dia"
              alignment="left"
            >
              <Lookup dataSource={studDiameterLookupData} displayExpr={'value'} valueExpr={'id'} />
            </Column>

            <Column
              dataType="number"
              dataField="studHeight"
              allowSearch
              allowFiltering={true}
              caption="Stud Height "
              alignment="left"
            />

            <Column
              dataType="string"
              dataField="flatBarSize"
              allowSearch
              allowFiltering={true}
              caption="Flat Bar Size "
              alignment="left"
            />

            <Column
              dataField="materialId"
              allowSearch
              allowFiltering={false}
              caption="SAP Material"
              setCellValue={setMaterialID}
            >
              <PatternRule pattern={/^\d+$/} message="Only numeric values are allowed." />
              <RequiredRule />
            </Column>
            <Column
              dataField="matDescription"
              allowSearch
              allowFiltering={false}
              caption="Description"
              allowEditing={false}
              minWidth={150}
              allowResizing
            />
            <Column dataField="matUOM" allowSearch allowFiltering={false} caption="UOM" allowEditing={false} />
            <Column
              allowSearch
              allowFiltering
              caption={'Active'}
              dataField="isActive"
              dataType="boolean"
              trueText="Active"
              falseText="Inactive"
            />
          </DataGrid>
        </>
      )}
      <ActionBar sx={{ justifyContent: 'end', display: 'flex', gap: '10px' }}>
        <ActionSubmitButton2
          buttonText={'Save'}
          onClick={handleSaveButton}
          disabled={!hasEditData || isSaving}
          loading={isSaving}
        />
        <ActionCancelButton
          buttonText={'cancel'}
          disabled={!hasEditData || isSaving}
          clickHandler={handleCancelButton}
        />
      </ActionBar>
    </Grid>
  );
}
