import { Helmet } from 'react-helmet-async';
import { useParams, useSearchParams } from 'react-router-dom';
import HardwaresForm from '../../../components/PT/PTQuantities/hardwaresForm';
import { useUserContext } from '../../../context/userContext';
import { useJob } from '../../../utils/masterData';
import PageLoader from '../../../components/shared/pageLoader';
import JobHeader from '../../../components/shared/jobHeader';

export default function Hardwares() {
  const { loadDescription } = useParams();
  const [searchParams] = useSearchParams();
  const { user } = useUserContext();
  const { jobId } = useParams();
  const { data: jobData, isLoading, error } = useJob({ jobId });

  const pagePermissions = {
    canEdit: (user.isPTProjectManager || user.isPTDetailer) && !jobData?.value?.jobOnHold,
  };

  const jobName = searchParams.get('jn');
  const cutNo = searchParams.get('cutNo');

  if (isLoading) return <PageLoader />;
  if (error) return <div>Error loading project info</div>;

  return (
    <>
      <Helmet>
        <title>PT Hardware</title>
      </Helmet>
      <JobHeader jobName={jobName} loadDescription={loadDescription} cutNo={cutNo} />
        <HardwaresForm pagePermissions={pagePermissions} />
    </>
  );
}
