import { Button } from '@mui/material';
import { ExitToApp } from '@mui/icons-material';
import React from 'react';

const ExportButton = React.forwardRef(({ ...props }, ref) => {
    return (
        <Button className="btn btn-icon" ref={ref} startIcon={<ExitToApp />} {...props}>
            Export
        </Button>
    );
});

export default ExportButton;
