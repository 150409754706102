import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { Column, DataGrid, Lookup } from 'devextreme-react/data-grid';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  TextField,
  Typography,
} from '@mui/material';
import { FiChevronsRight, FiChevronsLeft } from 'react-icons/fi';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { useSnackBar } from '../../../context/snackBarContext';
import { useBidItems, useStudDiameter, useExcuseCodes } from '../../../utils/masterData';
import { ActionSubmitButton2 } from '../../shared/actionSubmitButton';
import ActionBar from '../../shared/actionBar';
import ActionCancelButton from '../../shared/actionCancelButton';
import { CUT_STAUTS } from '../../../common/constants';

export default function CutsData(props) {
  const {
    cutsData: importedCutsData,
    setCutsData: setImportedCutsData,
    isFormDirty,
    setIsFormDirty,
    currentIndex,
    setCurrentIndex,
    pagePermissions,
  } = props;
  const { jobId } = useParams();
  const cutsViewGrid = useRef();
  const [searchParams] = useSearchParams();
  //* edit or import
  const mode = searchParams.get('mode');
  const loadId = searchParams.get('loadId');

  const [openPopup, setOpenPopup] = useState(false);
  const navigate = useNavigate();
  const { setSnackbar } = useSnackBar();
  //* a cut to show when page loads
  // const loadId = searchParams.get('loadId');
  const [cutRails, setCutRails] = useState([]);
  //for readonly field in form.
  const [bidItemDesc, setBidItemDesc] = useState('');
  const [cuts, setCuts] = useState(() => importedCutsData ?? []);
  //* handle the form cut fields state

  //* get job info,  lookupdata,
  const { data: excuseCodesOptions } = useExcuseCodes();
  const { data: biditemsOptions } = useBidItems();

  const { data: studDiameterLookupData } = useStudDiameter();

  //* set cut line items grid data when next - prev button click.
  useEffect(() => {
    if (mode.toLowerCase() === 'import') {
      setCutRails(() => cuts[currentIndex]?.cutRails);
    }
  }, [currentIndex, cuts, mode]);

  //* handle states when count value changes (left-right)
  //* when left-right navigate call api to get cutline items data for cut in view (ONYLY in EDIT MODE)
  useEffect(() => {
    //* set the bid item description field
    const bidId = cuts[currentIndex]?.bidItemId;
    const description = biditemsOptions?.find((item) => item.id === bidId)?.description;
    setBidItemDesc(description);
  }, [currentIndex, biditemsOptions, importedCutsData, jobId, loadId, cuts]); //* importedCutsData for getting index

  //* handling the grid state when currentIndex(left, right -> clicked), updating the grid values (cut-rails).
  useEffect(() => {
    (async () => {
      //* only if mode is edit
      if (mode.toLowerCase() === 'edit') {
        const cutId = importedCutsData.find((cut, index) => index === currentIndex)?.cutId;
        try {
          //* get cut by id.
          const res = await axios.get(`jobs/${jobId}/loads/${loadId}/cuts/${cutId}`); //* keeping loader enabled here.

          setCutRails(res.data?.value?.cutRails);
        } catch (error) {}
      }
    })();
  }, [mode, importedCutsData, currentIndex, jobId, loadId]);

  //* validate the lookup data (studDiameter, studOffset, studSpacing)
  useEffect(() => {
    if (importedCutsData?.length) {
      const errors = [];
      const cutRails = importedCutsData.map((cut) => cut.cutRails).flat();

      cutRails.forEach((cutRail) => {
        if (cutRail.studDiameterId === 0) {
          errors.push(`Stud Diameter for line# ${cutRail.lineNumber}`);
        }
      });

      if (errors.length) {
        setSnackbar({
          message: `${errors.join(', ') + ' is invalid.'}`,
          open: true,
          severity: 'warning',
          isAction: true,
        });
      }
    }
  }, [importedCutsData, studDiameterLookupData]);

  function handleLeftArrow() {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      if (mode.toLowerCase() !== 'edit') setCutRails(() => cuts[currentIndex - 1]?.cutRails);
    }
  }

  function handleRightArrow() {
    if (currentIndex < cuts.length - 1) {
      setCurrentIndex(currentIndex + 1);
      if (mode.toLowerCase() !== 'edit') setCutRails(() => cuts[currentIndex + 1]?.cutRails);
    }
  }

  function handleDelete() {
    const updateCuts = cuts.filter((cut, index) => index !== currentIndex);
    if (!updateCuts?.length) setImportedCutsData([]);
    setCuts(updateCuts);
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      if (mode.toLowerCase() !== 'edit') setCutRails(() => cuts[-1]?.cutRails);
    }
  }

  function handleExCodeChange(e) {
    const updateCutsData = [...cuts];
    updateCutsData[currentIndex].exCode = e.target.value;
    setCuts([...updateCutsData]);

    if (!isFormDirty) setIsFormDirty(true);
  }

  // need to handle the bid description state change also.
  function handleBidChange(e) {
    const updateCutsData = [...cuts];
    updateCutsData[currentIndex].bidItemId = e.target.value;
    setCuts([...updateCutsData]);
    // description
    setBidItemDesc(biditemsOptions.find((item) => item.id === e.target.value)?.description);

    if (!isFormDirty) setIsFormDirty(true);
  }

  function handleShippingMethod(e) {
    const updatedCuts = [...cuts];
    updatedCuts[currentIndex].shippingMethod = e.target.value; // Update the state
    setCuts(updatedCuts); // Set the updated state

    if (!isFormDirty) setIsFormDirty(true);
  }

  //* save cuts on save button click.
  async function handleSaveButton() {
    //* check if all for all cuts BidItem,  are selected
    let i = 0;
    for (let cut of cuts) {
      if (!cut?.bidItemId || (!cut?.exCode && !cut?.shippingMethod)) {
        //* set count goto that particular cut.
        setCurrentIndex(i);
        setSnackbar({
          open: true,
          message: 'Bid field is required for all cuts.',
          severity: 'error',
        });
        return;
      }
      i += 1;
    }
    const payload = { cuts, type: 2 };
    try {
      await axios.put(`jobs/${jobId}/loads/${loadId}/cuts`, payload);
      setIsFormDirty(false);
      setSnackbar({
        open: true,
        message: `Saved successfully`,
        severity: 'success',
      });
      //* Navigate at previous page
      navigate(-1);
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Error saving.`,
        severity: 'error',
      });
    }
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <Grid container columnSpacing={5} rowSpacing={3} sx={{ margin: '0 auto', width: { lg: '95%' } }}>
        {/* <Grid item xs={12} sx={{ textAlign: 'end' }}></Grid> */}
        <Grid item xs={12} sm={6}>
          <strong>Cut #:</strong> {cuts[currentIndex]?.cutNo}
        </Grid>
        <Grid item xs={12} sm={6}>
          <strong>Description:</strong> {cuts[currentIndex]?.description}
        </Grid>
        <Grid item xs={12}>
          <Box display={'flex'} width={'100%'} justifyContent={'center'} alignItems={'center'} gap={2}>
            <FormControl size="medium" fullWidth>
              <InputLabel id={'excuse-code'}>Excuse Code</InputLabel>
              <MuiSelect
                labelId="excuse-code"
                id="excuse-code-select"
                value={cuts[currentIndex]?.exCode}
                label="Excuse Code"
                onChange={handleExCodeChange}
                disabled={
                  !pagePermissions.canEdit ||
                  (mode.toLowerCase() === 'edit' && cuts[currentIndex]?.statusId >= CUT_STAUTS.releaseToShop)
                }
              >
                {excuseCodesOptions?.value?.map((code) => (
                  <MenuItem key={code.exCode} value={code.exCode}>
                    {code.description}
                  </MenuItem>
                ))}
              </MuiSelect>
            </FormControl>
            <FormControl size="medium" fullWidth>
              <InputLabel id={'bid'}> Bid</InputLabel>
              <MuiSelect
                labelId="bid"
                id="bid-select"
                value={cuts[currentIndex]?.bidItemId}
                label="Bid"
                disabled={
                  !pagePermissions.canEdit ||
                  (mode.toLowerCase() === 'edit' && cuts[currentIndex]?.statusId >= CUT_STAUTS.releaseToShop)
                }
                onChange={handleBidChange}
              >
                {biditemsOptions
                  ?.filter((status) => [3].includes(status.id))
                  .map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.description}
                    </MenuItem>
                  ))}
              </MuiSelect>
            </FormControl>
            <FormControl size="medium" fullWidth>
              <TextField
                id="bid-desc-text"
                label={'Bid Item Desc'}
                variant="outlined"
                value={bidItemDesc ?? ''}
                disabled
              />
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={8} sm={6} md={6}>
          <Box>
            <FormControl size="medium" fullWidth>
              <TextField
                id="shipping-method-text"
                label={'Shipping Method'}
                variant="outlined"
                onChange={handleShippingMethod}
                value={cuts[currentIndex]?.shippingMethod || ''}
                inputProps={{ maxLength: 30 }}
                disabled={cuts[currentIndex]?.statusId >= CUT_STAUTS.releaseToShop}
              />
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', gap: 2, alignItems: 'end' }}>
            <Box paddingBottom={4}>
              <FiChevronsLeft
                cursor={currentIndex > 0 ? 'pointer' : 'default'}
                size={25}
                color={currentIndex > 0 ? '#3862ae' : '#B9CAE9'}
                onClick={handleLeftArrow}
              />
            </Box>
            <DataGrid ref={cutsViewGrid} height={330} dataSource={cutRails} showBorders={true}>
              <Column dataField={'lineNumber'} caption="Line" alignment="center" />
              <Column dataField={'railQuantity'} caption="Quantity" alignment="center" />
              <Column dataField={'color'} width={150} caption="Color Designation" alignment="center" />
              <Column dataField={'studDiameterId'} caption="Stud Dia" alignment="center">
                <Lookup dataSource={studDiameterLookupData} displayExpr={'value'} valueExpr={'id'} />
              </Column>
              <Column dataField={'studsPerRail'} caption="No Studs" width={100} alignment="center" />
              <Column dataField={'offset'} width={100} caption="SO" alignment="center" />
              <Column dataField={'spacing'} width={100} caption="Stud Spa" alignment="center" />
              <Column dataField={'railHeight'} caption="Rail Height" width={100} alignment="center" />
              <Column dataField={'galvanized'} caption="Galv" alignment="center" />
              <Column dataField={'varStudGroup'} caption="VarStudGroup" alignment="center" visible={false} />
            </DataGrid>
            <Box sx={{ display: 'flex', gap: 2, pb: 4 }}>
              <FiChevronsRight
                onClick={handleRightArrow}
                cursor={currentIndex < cuts.length - 1 ? 'pointer' : 'default'}
                size={25}
                color={currentIndex < cuts.length - 1 ? '#3862ae' : '#B9CAE9'}
              />
              {mode.toLowerCase() === 'import' ? (
                <RiDeleteBin6Line onClick={handleDelete} cursor={'pointer'} size={25} />
              ) : null}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <ActionBar
        sx={{
          justifyContent: 'end',
          display: 'flex',
          gap: '10px',
        }}
      >
        <ActionSubmitButton2 buttonText={'Save'} disabled={!isFormDirty} onClick={handleSaveButton} />
        <ActionCancelButton
          buttonText={'cancel'}
          clickHandler={() => {
            if (mode !== 'edit') setOpenPopup(true);
            else navigate(-1);
          }}
        />
      </ActionBar>
      <Dialog
        open={openPopup}
        onClose={() => setOpenPopup(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Typography variant="h6">
            This will cancel the import discarding all the changes and take you back to the load screen. Do you want to
            continue?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => navigate(-1)} autoFocus>
            Yes
          </Button>
          <Button variant="contained" color="error" onClick={() => setOpenPopup(false)}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
