import { useEffect, useState } from 'react';
import { useErrorContext } from '../../context/errorContext';
import { Alert } from '@mui/material';

export const DisplayError = () => {
    const { error } = useErrorContext();
    const [visible, setVisibility] = useState(false);
    let message = '';

    if (error != null){
        message = 'Error occured while processing the request.';
    }

    if (typeof error?.response?.data === 'string'){
        message = error?.response?.data;  // Status 500 error
    }
    else if (typeof error?.response?.data?.message === 'string'){
        message = error?.response?.data?.message; // Failure Api result 
    }
    else if (typeof error?.response?.data?.title === 'string') {
        message = error?.response?.data?.title; // For model state validation errors
    }
    else if (typeof error?.message === 'string') {
        message = error?.message;
    }

    let validationErrors = [];
    let errors = error?.response?.data?.errors;

    for (let key in errors) {
        validationErrors.push(errors[key]);
    }

    if (!validationErrors.length) {
        if (typeof error?.response?.data === 'object') {
            errors = Object.values(error?.response?.data);
            for (let key in errors) {
                validationErrors.push(errors[key]);
            }
        }
    }
    const listItems = validationErrors.map((error, index) => <li key={error}>{error}</li>);

    useEffect(() => {
        setVisibility(true);
    }, [error])
 

    return (
        <>
        {
            message.length > 0 && visible &&
            <Alert severity='error'
                sx={{ width: '90%', marginBottom: '1rem' }}
                onClose={() => { setVisibility(false) }}>
                {message}
                { <ul>{listItems}</ul> }
            </Alert>
        }
    </>
    );
};
