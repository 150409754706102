import { Grid } from '@mui/material';
import InputNum from '../../../shared/forms/inputNum';

export default function LaborHours({ control, pagePermissions }) {
  return (
    <Grid container columnSpacing={{ xs: 2, md: 5 }} rowSpacing={{ xs: 3, md: 4 }}>
      <Grid item xs={6}>
        <InputNum
          isDisabled={!pagePermissions.canEdit}
          control={control}
          label="Project Management"
          name="projectManagementHours"
        />
      </Grid>
      <Grid item xs={6}>
        <InputNum isDisabled={!pagePermissions.canEdit} control={control} label="Detailing" name="detailingHours" />
      </Grid>
      <Grid item xs={6}>
        <InputNum
          isDisabled={!pagePermissions.canEdit}
          control={control}
          label="Project Engineering"
          name="projectEngineeringHours"
        />
      </Grid>
      <Grid item xs={6}>
        <InputNum
          isDisabled={!pagePermissions.canEdit}
          control={control}
          label="Design Engineering"
          name="designEngineeringHours"
        />
      </Grid>
    </Grid>
  );
}
