import DataGrid, { Column, Lookup, Paging, SearchPanel, Selection, HeaderFilter } from 'devextreme-react/data-grid';
import UsersForm from './usersForm.js';
import { Grid } from '@mui/material';
import { useState, useCallback, useRef, useEffect } from 'react';
import axios from 'axios';
import { useSnackBar } from '../../../context/snackBarContext.js';
import UserFormSkeleton from './userFormSkeleton.js';
import GridSkeleton from '../../shared/gridSkeleton.js';
import { useQuery } from '@tanstack/react-query';

export default function ManageUsers() {
  const dataGridRef = useRef();
  const { setSnackbar } = useSnackBar();
  const [selectedUser, setSelectedUser] = useState();
  const populationFormat = { type: 'fixedPoint', precision: 0 };
  const areaFormat = { type: 'fixedPoint', precision: 0 };
  const {
    data: locations,
    error: locationsError,
    isLoading: locationsLoading,
  } = useQuery({
    queryKey: ['locations'],
    queryFn: async () => {
      const res = await axios.get('Locations', { loaderRequired: false });
      return res.data;
    },
  });

  const {
    data: users,
    isLoading,
    error,
    refetch: refetchUsers,
  } = useQuery({
    queryKey: ['ptusers'],
    queryFn: async () => {
      const res = await axios.get('users/ptusers', { loaderRequired: false });

      return res.data;
    },
    refetchInterval: 15 * 60 * 1000,
  });

  // handle side effect for row selection and form view on user data loaded.
  useEffect(() => {
    if (users?.length && !selectedUser) {
      setSelectedUser(users[0]);
      dataGridRef?.current?.instance?.selectRowsByIndexes([0]);
    }
  }, [users, dataGridRef, selectedUser]);

  if (error) {
    setSnackbar({
      open: true,
      message: 'Error getting users',
      severity: 'error',
    });
  }
  if (locationsError) {
    setSnackbar({
      open: true,
      message: 'Error in getting PT locations.',
      severity: 'error',
    });
  }

  // handle user select
  const handleOnSelectionChanged = useCallback((e) => {
    setSelectedUser(e?.selectedRowsData[0]);
  }, []);

  return (
    <Grid container spacing={4} className="users" sx={{ paddingLeft: '10px', mb: 8 }}>
      <Grid item xs={12} md={7} sx={{ position: 'relative' }}>
        {isLoading ? (
          <GridSkeleton />
        ) : (
          <DataGrid
            ref={dataGridRef}
            id="gridContainer"
            dataSource={users}
            keyExpr="userId"
            allowColumnResizing
            showBorders={true}
            onSelectionChanged={handleOnSelectionChanged}
            rowAlternationEnabled={true}
          >
            <Paging defaultPageSize={15} />
            <SearchPanel visible={true} placeholder="Search..." />
            <HeaderFilter visible />
            <Selection mode="single" />

            <Column allowSearch allowFiltering={false} dataField="displayName" caption={'Name'} />
            <Column allowSearch dataField="office" caption={'Location'}>
              <Lookup dataSource={locations} displayExpr="locationName" valueExpr="locationID" />
            </Column>
            <Column
              allowFiltering={false}
              allowSearch
              dataField="email"
              width={320}
              caption={'Email'}
              format={populationFormat}
            />
            <Column
              dataField="mobile"
              allowSearch
              caption={'Cell Phone'}
              allowFiltering={false}
              format={areaFormat}
              width={150}
            />
          </DataGrid>
        )}
      </Grid>
      <Grid item xs={12} md={5}>
        {isLoading || locationsLoading ? (
          <UserFormSkeleton />
        ) : (
          selectedUser !== undefined && (
            <UsersForm refetchUsers={refetchUsers} PTLocations={locations} currentUser={selectedUser} />
          )
        )}
      </Grid>
    </Grid>
  );
}
