import { Helmet } from 'react-helmet-async';
import { Box, Typography, Grid } from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';
import PTIFilingForm from '../../../components/PT/PTQuantities/ptiFilingForm';

export default function PTIFiling() {
  const { loadDescription } = useParams();
  const [searchParams] = useSearchParams();

  const jobName = searchParams.get('jn');

  return (
    <>
      <Helmet>
        <title>PTI Filing</title>
      </Helmet>
      <Typography variant="h1">
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={2}>
          <Typography variant="h1">{decodeURIComponent(jobName)}</Typography>
          <Typography variant="h1">{loadDescription}</Typography>
        </Box>
      </Typography>
      <Grid item xs={12} sx={{ marginTop: '1rem' }}>
        <PTIFilingForm />
      </Grid>
    </>
  );
}
