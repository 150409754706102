import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const QUERY_PT_PARTCLASS = ['pt/partClass'];
export const useGetActiveParts = () => {
  return useQuery({
    queryKey: QUERY_PT_PARTCLASS,
    queryFn: async () => (await axios.get(`pt/partClass?type=${true}`, { loaderRequired: false })).data,
  });
};

const QUERY_PT_KIT_PART = 'PT/Kits/Parts';
export const useGetKitPart = (params) =>
  useQuery({
    queryKey: [QUERY_PT_KIT_PART, params.kitId],
    queryFn: async ({ queryKey }) => (await axios.get(`PT/Kits/${queryKey[1]}/Parts`, { loaderRequired: false })).data,
  });

const QUERY_PT_HardwareParts = 'PT/Parts/PT/HardwareParts';
export const useGetActiveHardwareParts = () => {
  return useQuery({
    queryKey: [QUERY_PT_HardwareParts],
    queryFn: async () => (await axios.get(`PT/Parts/PT/HardwareParts`, { loaderRequired: false })).data,
  });
};

const QUERY_PT_CUTSTATUS = 'pt/cutStatus';
export const useCutStatus = () =>
  useQuery({
    queryKey: [QUERY_PT_CUTSTATUS],
    queryFn: async () => (await axios.get('pt/cutstatus', { loaderRequired: false })).data,
  });

const QUERY_PT_BIDITEMS = 'Jobs/biditems';
export const useBidItems = () =>
  useQuery({
    queryKey: [QUERY_PT_BIDITEMS],
    queryFn: async () => (await axios.get('Jobs/biditems', { loaderRequired: false })).data,
  });

const QUERY_PT_EXCUSECODES = 'pt/excusecodes';
export const useExcuseCodes = () =>
  useQuery({
    queryKey: [QUERY_PT_EXCUSECODES],
    queryFn: async () => (await axios.get('pt/excusecodes', { loaderRequired: false })).data,
  });

const QUERY_PT_ACCESSORIES = 'common/accessories';
export const useAccessories = () =>
  useQuery({
    queryKey: [QUERY_PT_ACCESSORIES],
    queryFn: async () => (await axios.get('common/accessories', { loaderRequired: false })).data,
  });

const QUERY_PT_MILLTHICKNESS = 'pt/millthickness';
export const useMillthickness = () =>
  useQuery({
    queryKey: QUERY_PT_MILLTHICKNESS,
    queryFn: async () => {
      const response = await axios.get('pt/millthickness');
      return response.data;
    },
  });

const QUERY_PT_CABLEDIAMETER = 'pt/cableDiameter';
export const useCableDiameter = () =>
  useQuery({
    queryKey: QUERY_PT_CABLEDIAMETER,
    queryFn: async () => (await axios.get('pt/cableDiameter', { loaderRequired: false })).data,
    refetchIntervalInBackground: false,
  });

const QUERY_PT_ADDITIONAL_ITEMS = ['pt/additionalItems'];
export const useAdditionalItems = () =>
  useQuery({
    queryKey: QUERY_PT_ADDITIONAL_ITEMS,
    queryFn: async () => (await axios.get(`pt/additionalItems`, { loaderRequired: false })).data,
  });

const QUERY_PT_DELIVERYTYPES = ['pt/deliveryTypes'];
export const useDeliveryTypes = () =>
  useQuery({
    queryKey: QUERY_PT_DELIVERYTYPES,
    queryFn: async () => (await axios.get(`pt/deliveryTypes`, { loaderRequired: false })).data,
  });

// job details query
const QUERY_JOBS_DETAILS_BYJOBID = 'jobs/jobId';
export const useJob = (params) =>
  useQuery({
    queryKey: [QUERY_JOBS_DETAILS_BYJOBID, params.jobId],
    queryFn: async ({ queryKey }) => (await axios.get(`jobs/${queryKey[1]}`, { loaderRequired: false })).data,
  });

// Approval Count query
const QUERY_CURRENTUSER_APPROVALCOUNT = ['approvals/approvalCount'];
export const useApprovalCount = () =>
  useQuery({
    queryKey: QUERY_CURRENTUSER_APPROVALCOUNT,
    queryFn: async () => (await axios.get(`approvals/approvalCount`, { loaderRequired: false })).data,
  });

// My Jobs
const QUERY_MY_JOBS = 'jobs/myjobs';
export const useMyJobs = ({ hasPT = null, hasRebar = null }) =>
  useQuery({
    queryKey: [QUERY_MY_JOBS, { ...(hasPT && { hasPT }), ...(hasRebar && { hasRebar }) }],
    queryFn: async () =>
      (
        await axios.get('fab/jobs/myjobs', {
          loaderRequired: false,
          params: { ...(hasPT && { hasPT }), ...(hasRebar && { hasRebar }) },
        })
      ).data,
    refetchOnMount: 'always',
    refetchOnWindowFocus: 'always',
  });

export const userDetailQuery = {
  queryKey: ['user'],
  queryFn: async () => {
    const response = await axios.get('users/me', { loaderRequired: false });
    return response.data;
  },
};

const QUERY_STUDDIAMETER = 'pt/StudDiameter';
export const useStudDiameter = () =>
  useQuery({
    queryKey: [QUERY_STUDDIAMETER],
    queryFn: async () => (await axios.get('pt/StudDiameter', { loaderRequired: false })).data,
  });

const QUERY_JOBTYPES = 'Common/JobTypes';
export const useJobTypes = () =>
  useQuery({
    queryKey: [QUERY_JOBTYPES],
    queryFn: async () => (await axios.get('Common/JobTypes')).data,
  });

const QUERY_CABLE_TYPE = 'pt/CableType';
export const useCableType = () =>
  useQuery({
    queryKey: [QUERY_CABLE_TYPE],
    queryFn: async () => (await axios.get('pt/CableType')).data,
  });

const QUERY_PT_LOCATION = 'PTLocations';
export const usePTLocation = () =>
  useQuery({
    queryKey: [QUERY_PT_LOCATION],
    queryFn: async () => (await axios.get('Locations?ptOnly=true&ActiveOnly=true', { loaderRequired: false })).data,
  });

const QUERY_PT_AREACODES = ['pt/areaCodes'];
export const usePTAreaCodes = () =>
  useQuery({
    queryKey: QUERY_PT_AREACODES,
    queryFn: async () => (await axios.get('pt/areaCodes')).data,
    refetchIntervalInBackground: false,
  });

  const QUERY_PT_VENDORS = ['pt/Vendors'];
export const usePTVendors = () =>
  useQuery({
    queryKey: QUERY_PT_VENDORS,
    queryFn: async () => (await axios.get('pt/Vendors')).data,
    refetchIntervalInBackground: false,
  });
