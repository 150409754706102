import { Card, CardHeader, Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import ModuleList from '../../components/PT/moduleList';
import MyJobs from '../../components/shared/myJobs';
import RecentJobs from '../../components/Home/recentJobs';
 
const PTHome = () => {
  return (
    <div>
      <Helmet>
        <title>PT Dashboard</title>
      </Helmet>
      <Grid
        container
        spacing={4}
        className="cards"
        sx={{
          color: 'text.primary',
        }}
      >
        <Grid item xs={12} md={6} lg={4} sx={{ display: 'flex' }}>
          <MyJobs />
        </Grid>
        <Grid item xs={12} md={6} lg={4} sx={{ display: 'flex' }}>
          {/* can be removed */}
          {/* <Notifications /> */}
          <RecentJobs />
        </Grid>
        <Grid item xs={12} md={6} lg={4} sx={{ display: 'flex' }}>
          <Card className="card">
            <CardHeader className="card-hdr" title="Modules" />
            <ModuleList />
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
 
export default PTHome;
