import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Box, Button, Container, Menu, MenuItem } from '@mui/material';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import LanIcon from '@mui/icons-material/Lan';

export default function ProjectsNav() {
  const { jobId } = useParams();
  const [anchorEl, setAnchorEl] = useState(null);

  function handleClick(event) {
    if (event.currentTarget.classList.contains('dropmenu')) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <Container maxWidth="false" sx={{ padding: '6px 8px' }} disableGutters className="admin-user">
      <Box>
        <Button
          variant="outlined"
          sx={{
            color: 'rgba(0, 0, 0, 0.87)',
            border: 'none',
            '&:hover': {
              backgroundColor: 'transparent',
              border: 'none',
            },
          }}
          disableRipple
          className="dropmenu"
          data="jobInfo"
          onClick={handleClick}
          startIcon={<ContentPasteSearchIcon />}
        >
          Job Info
        </Button>
        <Menu
          className="sub-menu"
          anchorEl={anchorEl}
          open={anchorEl?.getAttribute('data') === 'jobInfo'}
          onClose={handleClose}
          MenuListProps={{
            sx: {
              backgroundColor: '#f1f2f3',
              display: 'flex',
              minHeight: '42px',
              padding: '0',
            },
          }}
        >
          <MenuItem onClick={handleClose}>
            <Link to={`/PT/jobs/${jobId}/MainInfo`}>Main Info</Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link to={`/PT/jobs/${jobId}/contacts`}>Contact Info</Link>
          </MenuItem>
          {/* <MenuItem onClick={handleClose}>
                        <Link to="/">Notifications</Link>
                    </MenuItem> */}
          <MenuItem onClick={handleClose}>
            <Link to={`${jobId}/estimates`}>Estimates</Link>
          </MenuItem>
        </Menu>
        <Button
          variant="outlined"
          sx={{
            color: 'rgba(0, 0, 0, 0.87)',
            border: 'none',
            '&:hover': {
              backgroundColor: 'transparent',
              border: 'none',
            },
          }}
          disableRipple
          className="dropmenu"
          data="loads"
          onClick={handleClick}
          startIcon={<LanIcon />}
        >
          Loads
        </Button>
        <Menu
          className="sub-menu"
          anchorEl={anchorEl}
          open={anchorEl?.getAttribute('data') === 'loads'}
          onClose={handleClose}
          MenuListProps={{
            sx: {
              backgroundColor: '#f1f2f3',
              display: 'flex',
              minHeight: '42px',
              padding: '0',
            },
          }}
        >
          <MenuItem onClick={handleClose}>
            <Link to={`${jobId}/pt-quantities`}>PT Quantities</Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link to={`${jobId}/studrails_quantities`}>Stud Rails Quantities</Link>
          </MenuItem>
        </Menu>
        {/* // schedules not at job level. */}
        {/* <Button
                    variant="outlined"
                    sx={{
                        color: 'rgba(0, 0, 0, 0.87)',
                        border: 'none',
                        '&:hover': {
                            backgroundColor: 'transparent',
                            border: 'none',
                        },
                    }}
                    disableRipple
                    onClick={() => navigate(`pt/schedules`)}
                    startIcon={<CalendarMonthIcon />}
                >
                    Schedules
                </Button> */}
      </Box>
    </Container>
  );
}
