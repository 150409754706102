import { Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import Logo from '../components/Layout/logo';

export default function GenericErrorPage({ message }) {
  return (
    <>
      <Helmet>
        <title>Unauthorized</title>
      </Helmet>
      <div className="hdr">
        <Grid className="hdr-main" container rowSpacing={{ xs: 1, md: 0 }} columnSpacing={{ xs: 0 }}>
          <Grid
            item
            xs={12}
            md={3}
            lg={2}
            sx={{
              display: 'flex',
              justifyContent: { xs: 'center', md: 'flex-start' },
            }}
            className="hdr-col"
          >
            <Logo />
          </Grid>
        </Grid>
      </div>
      <main style={{ 
        alignItems: 'center', 
        display: 'flex',
        height: 'calc(100vh - 150px)',  
        justifyContent: 'center' }}>
        <div style={{ textAlign: 'center' }}>
            <img src="/oops.svg" width={450} height={359} alt="networkError" />
            <div style={{ margin: '2rem 0 .5rem' }}>Error Message: {message}</div>
            <div>Please try again. If the problem persisists, reach out to <a href='mailto:helpdesk@cmc.com'> CMC Helpdesk.</a></div>
        </div>
      </main>
    </>
  );
}
