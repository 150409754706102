import { useSnackBar } from '../../context/snackBarContext';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { forwardRef } from 'react';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert evelation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomSnackBar() {
  const { snackbar, closeSnackBar } = useSnackBar();

  return (
    <div>
      <Snackbar
        open={snackbar.open}
        onClose={closeSnackBar}
        autoHideDuration={snackbar.isAction ? null : 6000}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <Alert
          onClose={closeSnackBar}
          severity={snackbar.severity}
          sx={{
            width: '100%',
            justifyItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

