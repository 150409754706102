import { createContext, useContext, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { userDetailQuery } from '../utils/masterData';

const initialUserContextState = {
  user: undefined,
  loading: true,
  error: false,
  refresh: () => null,
};

export const userContext = createContext(initialUserContextState);

export const UserContextProvider = ({ children }) => {
  const { data: user, isLoading: loading, error, refetch: refresh } = useQuery(userDetailQuery);

  const value = useMemo(() => ({ error, loading, refresh, user }), [error, loading, refresh, user]);
  return <userContext.Provider value={value}>{children}</userContext.Provider>;
};

export const useUserContext = () => {
  const context = useContext(userContext);

  return context;
};
