import { useState, useRef, useEffect, useCallback } from 'react';
import { useSnackBar } from '../../../context/snackBarContext';
import axios from 'axios';
import {
    Column,
    Editing,
    DataGrid,
    RequiredRule,
    Scrolling,
    Selection,
    HeaderFilter,
    CustomRule,
} from 'devextreme-react/data-grid';
import { Skeleton, Grid, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ActionBar from '../../shared/actionBar';
import { ActionSubmitButton2 } from '../../shared/actionSubmitButton';
import ActionCancelButton from '../../shared/actionCancelButton';

function FabConfigForm() {
    const fabConfigDataGrid = useRef(null);
    const { setSnackbar } = useSnackBar();
    const [loader, setLoader] = useState(true);
    const [fabConfigData, setFabConfigData] = useState([]);
    const [hasEditData, setHasEditData] = useState(false);

    useEffect(() => {
        Promise.all([axios.get(`PT/FabConfig`, { loaderRequired: false })])
            .then((Result) => {
                console.log(Result[0].data);
                setFabConfigData(Result[0].data);
                setLoader(false);
            })
            .catch((error) => {
                setSnackbar({
                    open: true,
                    message: `Error occured while getting fabconfig data.`,
                    severity: 'error',
                });
                console.log(error);
                setLoader(false);
            });
    }, [setSnackbar]);

    //* removes the data grid toolbar header buttons
    function handleOnToolbarPreparing(e) {
        //* remove add, save and reset
        e.toolbarOptions.items.splice(0);
    }

    //* handle edit btn state
    function handleOnContentReady(e) {
        setHasEditData(e.component.hasEditData());
    }

    //* add row button handler
    function handleAddRowButton() {
        fabConfigDataGrid.current.instance.addRow();
    }

    //* save button handler
    function handleSaveButton() {
        fabConfigDataGrid.current.instance.saveEditData();
        setHasEditData(true);
    }

    //* cancel/reset button handler
    function handleCancelButton() {
        fabConfigDataGrid.current.instance.cancelEditData();
    }

    //* refresh datagrid data on insert new row
    async function refresh() {
        try {
            const fabconfigesponse = await axios.get(`PT/FabConfig`, { loaderRequired: false });
            setFabConfigData(fabconfigesponse.data);
        } catch (error) {
            console.log(error);
        }
    }

    const handleInitNewRow = (e) => {
        // Set the default values for the new row
        e.data.isActive = true;
    };

    //* on save data grid handler
    async function handleSaving(e) {
        // default save behavior canceled
        e.cancel = true;

        let payload = {
            fabconfig: [],
        };

        let haveInsert = false;
        let allRecords = fabConfigDataGrid.current.instance.getVisibleRows();

        e.changes.forEach((change) => {
            if (change.type === 'insert') {
                payload.fabconfig.push(change.data);
                haveInsert = true;
            } else {
                // If this is update, get other fields as well
                let row = allRecords.find((r) => r.data?.fabTypeId === change.key);
                if (row?.data) {
                    payload.fabconfig.push({
                        ...row.data,
                    });
                }
            }
        });

        //grid default save behavior enabled back
        e.cancel = false;

        axios
            .put(`PT/FabConfig`, payload, { loaderRequired: false })
            .then(async () => {
                setSnackbar({
                    open: true,
                    message: `Saved successfully`,
                    severity: 'success',
                });

                if (haveInsert) await refresh();
            })
            .catch((error) => {
                setSnackbar({
                    open: true,
                    message: 'Error occured while updating fabconfig.',
                    severity: 'error',
                });

                console.log('Fab Config Update Error', error);
            });
    }

    function validateNumberRange(options) {
        //* check if value is between 0 to 10 return true
        return !(options?.value < 0 || options?.value > 10);
    }

    const FabTypeNameValidation = useCallback(
        (event) => {
            //* check for unique value
            const allRecords = fabConfigDataGrid.current.instance.getVisibleRows();
            if (allRecords?.length) {
                const uniqueValue = new Set();
                for (const row of allRecords) {
                    //*check if Fabrication Type Name in Set and current row value is being edited
                    if (
                        uniqueValue.has(row.data?.fabType.toLowerCase().trim()) &&
                        event.value.toLowerCase().trim() === row.data?.fabType.toLowerCase().trim() &&
                        row.rowType === 'data'
                    ) {
                        event.rule.message = 'Duplicate Fabrication Type name found.';
                        return false;
                    }
                    uniqueValue.add(row.data?.fabType.toLowerCase().trim());
                }
            }
            return true;
        },
        [fabConfigDataGrid]
    );

    return (
        <Grid container spacing={2}>
            <Grid
                item
                xs={12}
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'start',
                }}
            >
                <Button onClick={handleAddRowButton} className="btn btn-blue btn-icon" startIcon={<AddIcon />}>
                    Add Row
                </Button>
            </Grid>
            <Grid item xs={12} marginBottom={9}>
                {loader ? (
                    <Skeleton sx={{ width: '100%', height: 300 }} />
                ) : (
                    <DataGrid
                        ref={fabConfigDataGrid}
                        id="gridContainer"
                        dataSource={fabConfigData}
                        height={440}
                        keyExpr={'fabTypeId'}
                        showBorders={true}
                        rowAlternationEnabled={true}
                        onToolbarPreparing={handleOnToolbarPreparing}
                        onContentReady={handleOnContentReady}
                        onInitNewRow={handleInitNewRow}
                        onSaving={handleSaving}
                    >
                        <HeaderFilter visible />
                        <Selection mode="single" />
                        <Editing allowAdding allowUpdating mode="batch" />
                        <Scrolling mode="infinite" />

                        <Column
                            dataField="fabType"
                            caption="Fabrication Type"
                            allowSearch
                            allowFiltering={false}
                            allowEditing={true}
                        >
                            <RequiredRule />
                            <CustomRule validationCallback={FabTypeNameValidation} />
                        </Column>
                        <Column
                            dataField="deadEnd"
                            caption="Dead End"
                            allowSearch
                            allowEditing={true}
                            allowFiltering={false}
                            dataType="number"
                        >
                            <RequiredRule />
                            <CustomRule
                                message="Please enter a number between 0 and 10."
                                validationCallback={validateNumberRange}
                            />
                        </Column>
                        <Column
                            dataField="contInter"
                            caption="Intermediate Rollout"
                            allowSearch
                            allowFiltering={false}
                            allowEditing={true}
                        >
                            <RequiredRule />
                            <CustomRule
                                message="Please enter a number between 0 and 10."
                                validationCallback={validateNumberRange}
                            />
                        </Column>
                        <Column
                            dataField="liveEnd"
                            caption="Live End"
                            allowSearch
                            allowFiltering={false}
                            allowEditing={true}
                        >
                            <RequiredRule />
                            <CustomRule
                                message="Please enter a number between 0 and 10."
                                validationCallback={validateNumberRange}
                            />
                        </Column>
                        <Column
                            dataField="deadEndCouplers"
                            caption="DE Coupler"
                            allowSearch
                            allowFiltering={false}
                            allowEditing={true}
                        >
                            <RequiredRule />
                            <CustomRule
                                message="Please enter a number between 0 and 10."
                                validationCallback={validateNumberRange}
                            />
                        </Column>
                        <Column
                            caption={'Active'}
                            dataType="boolean"
                            dataField="isActive"
                            allowFiltering
                            allowSearch
                            trueText="Active"
                            falseText="Inactive"
                        />
                    </DataGrid>
                )}
            </Grid>
            <ActionBar
                sx={{
                    justifyContent: 'end',
                    display: 'flex',
                    gap: '10px',
                }}
            >
                <ActionSubmitButton2 buttonText={'Save'} onClick={handleSaveButton} disabled={!hasEditData} />
                <ActionCancelButton buttonText={'cancel'} disabled={!hasEditData} clickHandler={handleCancelButton} />
            </ActionBar>
        </Grid>
    );
}

export default FabConfigForm;
