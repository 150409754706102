import { FormLabel, Grid } from '@mui/material';
import InputNum from '../../../shared/forms/inputNum';

export default function GearSets({ control, pagePermissions }) {
  return (
    <Grid container columnSpacing={{ xs: 3, xl: 5 }} rowSpacing={{ xs: 3, md: 4 }} sx={{ paddingTop: '2.5rem' }}>
      <Grid item xs={12} md={6}>
        <Grid container columnSpacing={{ xs: 2 }} rowSpacing={{ xs: 3 }}>
          <Grid item xs={12}>
            <FormLabel style={{ color: 'rgba(0, 0, 0, 0.87)', fontWeight: '600' }}>
              Stressing Gear - # of Sets/Month:
            </FormLabel>
          </Grid>
          <Grid item sm={6}>
            <InputNum isDisabled={!pagePermissions.canEdit} control={control} label="Set(s)" name="stressGearSets" />
          </Grid>
          <Grid item sm={6}>
            <InputNum
              isDisabled={!pagePermissions.canEdit}
              control={control}
              label="Month(s)"
              name="stressGearMonths"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container columnSpacing={{ xs: 2 }} rowSpacing={{ xs: 3 }}>
          <Grid item xs={12}>
            <FormLabel style={{ color: 'rgba(0, 0, 0, 0.87)', fontWeight: '600' }}>
              Pocket Shear Gear - # of Sets/Month:
            </FormLabel>
          </Grid>
          <Grid item sm={6}>
            <InputNum isDisabled={!pagePermissions.canEdit} control={control} label="Set(s)" name="pocketShearSets" />
          </Grid>
          <Grid item sm={6}>
            <InputNum
              isDisabled={!pagePermissions.canEdit}
              control={control}
              label="Month(s)"
              name="pocketShearMonths"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
